import { Text } from '@chakra-ui/react';
import { Part, PartType, Pigtail } from '@web/apps/types';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';

const PigtailsDashboard = () => {
  const methods = useForm<Pigtail>({ defaultValues: { type: PartType.PIGTAIL } });

  const columns: CustomColumnDef[] = [
    {
      header: 'Wires',
      renderFn: (part: Part) => {
        const pigtail = part as Pigtail;
        return <Text>{pigtail.partWires.length}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.PIGTAIL} columnDefs={columns} />
    </FormProvider>
  );
};

export default PigtailsDashboard;
