import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';

import { GroupedConductor } from '../../../../../types/Design.ts';
import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { RelationalBreakoutPointNodeType } from '../../components/nodes/BreakoutPointNode/RelationalBreakoutPointNode.tsx';
import { NodeType } from '../../relational_types.ts';
import { useNoteGroupBuildNotes } from '../useNoteGroupBuildNotes.tsx';
import { useBuildNoteActions } from './useBuildNoteActions.tsx';
import { useDeleteControlPointAction } from './useDeleteControlPointAction.tsx';
import { useRelationalUnmergeActions } from './useRelationalUnmergeActions.tsx';

const getGroupedConductorKey = (groupedConductor: GroupedConductor) =>
  `${groupedConductor.sourceDesignPartId}:${groupedConductor.targetDesignPartId}`;

/**
 * Hook for breakout point actions.
 */
export const useRelationalBreakoutPointActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as RelationalBreakoutPointNodeType;

  const deleteLayoutNode = useDeleteControlPointAction(closeMenu, node?.id);

  const noteGroupBuildNotes = useNoteGroupBuildNotes(node?.id);
  const { buildNoteActions } = useBuildNoteActions({
    closeMenu,
    noteGroupBuildNotes,
    targetId: node?.id,
    targetType: 'LayoutNode',
  });

  const { handleUnmerge, getGroupedConductorName } = useRelationalUnmergeActions(node?.id, closeMenu);
  const groupedConductors = node?.data?.groupedConductors || [];

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={deleteLayoutNode}>Remove</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {groupedConductors.length > 1 && (
        <MenuGroup title="Unmerge Bundle">
          {groupedConductors.map((groupedConductor) => (
            <MenuItem key={getGroupedConductorKey(groupedConductor)} onClick={() => handleUnmerge(groupedConductor)}>
              {getGroupedConductorName(groupedConductor)}
            </MenuItem>
          ))}
        </MenuGroup>
      )}
      {buildNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.BreakoutPoint,
  } as MenuActions;
};
