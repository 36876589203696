import { ArrowRightIcon } from '@chakra-ui/icons';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Part, PartType } from '@web/apps/types';
import { useController, useFormContext } from 'react-hook-form';

import StatusBadge from './StatusBadge.tsx';

interface Props {
  partType: PartType;
}

const PartsLibraryModalHeader = ({ partType }: Props) => {
  const { control } = useFormContext<Part>();
  const { field: partNumberField } = useController({ name: 'partNumber', control });
  const { field: statusField } = useController({ name: 'status', control });

  const partNumberColor = useColorModeValue('black', 'white');
  const partTypeColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center">
        <Text as="span" color={partTypeColor}>
          {partType}
        </Text>
        <ArrowRightIcon mx={3} boxSize={4} color={partTypeColor} />
        {partNumberField.value && (
          <>
            <Text as="span" color={partNumberColor}>
              {partNumberField.value}
            </Text>
          </>
        )}
        {statusField.value && (
          <>
            <StatusBadge ml={4} status={statusField.value} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default PartsLibraryModalHeader;
