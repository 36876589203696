import { Box, Icon, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';
import { partTypeToRouteName } from '@web/apps/utils/parts.ts';
import generateURLSearch from '@web/common/lib/api-utils/generateURLSearch';
import { MdOutlineSdCard } from 'react-icons/md';
import { generatePath } from 'react-router-dom';

import { useUpdateDesignPartMutation } from '../../../api/design-parts-api.ts';
import AcceptChangesModal from './AcceptChangesModal';

interface Props {
  part: DesignPart;
  designId: string;
}

const DiffIndicator = ({ part, designId }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateDesignPart } = useUpdateDesignPartMutation();
  const {
    partData: { id: partId, type: partType, partNumber },
    partDataDiff,
    id: designPartId,
  } = part;

  const hasDiff = partDataDiff && partDataDiff.length > 0;

  const onAccept = () => {
    updateDesignPart({ designId, partId: designPartId });
    onClose();
  };

  const partUrl =
    generatePath(`/parts/:partType/:partId`, {
      partType: partTypeToRouteName(partType),
      partId,
    }) + generateURLSearch({ mode: 'READ' });

  return (
    <>
      <Tooltip
        isDisabled={!hasDiff}
        label={
          <Box>
            <Text>New Part Data Available</Text>
            <Text>Click to show changes</Text>
          </Box>
        }
      >
        <span>
          {hasDiff ? (
            <Icon
              color="orange.400"
              aria-label="Show Diff"
              as={MdOutlineSdCard}
              onClick={onOpen}
              cursor="pointer"
              display="block"
            />
          ) : (
            <Icon as={MdOutlineSdCard} opacity={0} />
          )}
        </span>
      </Tooltip>
      <AcceptChangesModal
        isOpen={isOpen}
        onClose={onClose}
        partNumber={partNumber}
        partUrl={partUrl}
        onAccept={onAccept}
      ></AcceptChangesModal>
    </>
  );
};

export default DiffIndicator;
