import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { HStack, Input, Text } from '@chakra-ui/react';
import { Backshell, Connector, PartType } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import { useFormContext } from 'react-hook-form';

import CompatibleAccessoryTable from './CompatibleAccessoryTable.tsx';

interface Props {
  editing: boolean;
}

const CompatibleBackshellTable = ({ editing }: Props) => {
  const { getValues, register } = useFormContext<Connector>();

  return (
    <FormControl>
      <HStack>
        <FormLabel id="BackshellLabel">
          <Text>Compatible Backshells</Text>
        </FormLabel>
      </HStack>
      <CompatibleAccessoryTable
        labelId="BackshellLabel"
        partType={PartType.BACKSHELL}
        editing={editing}
        maxIncluded={1}
        customColumns={[
          {
            header: 'Shielded',
            renderFn: (part) => {
              return (
                <EditableFormText label={null} editing={editing}>
                  <TextInput
                    maxWidth="200px"
                    formControlProps={{ isDisabled: true }}
                    value={(part as Backshell).shielded ? 'Yes' : 'No'}
                  />
                </EditableFormText>
              );
            },
          },
          {
            header: 'Torque Value',
            renderFn: (_part, index) => {
              return (
                <EditableFormText
                  editing={editing}
                  label={null}
                  value={getValues(`accessories.${index}.torqueValue`)?.toString()}
                >
                  <FormControl flex={1}>
                    <FormLabel>Torque Value</FormLabel>
                    <Input
                      isDisabled={!editing}
                      {...register(`accessories.${index}.torqueValue`)}
                      placeholder="ft/lbs"
                    />
                  </FormControl>
                </EditableFormText>
              );
            },
          },
        ]}
        aria-labelledby="includedBackshellLabel"
      />
    </FormControl>
  );
};

export default CompatibleBackshellTable;
