import { Button, FormControl, FormLabel, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Part, Tool, ToolType } from '@web/apps/types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useTools } from '../../Tools-Library/api/queries.ts';
import InsertionRemovalFields from '../../Tools-Library/features/InsertionsRemovals/InsertionRemovalFields.tsx';
import PositionerFields from '../../Tools-Library/features/Positioners/PositionerFields.tsx';
import TurretHeadFields from '../../Tools-Library/features/TurretHeads/TurretHeadFields.tsx';
import ToolRelationRow from './ToolRelationRow.tsx';

interface Props {
  editing: boolean;
}

export const toOption = (tool: Tool) => ({
  label: tool.partNumber,
  value: tool.id,
  type: tool.type,
});

export const toolTypeToLabel = {
  [ToolType.CRIMP]: 'Crimper',
  [ToolType.DIESET]: 'Die Set',
  [ToolType.INSERTIONREMOVAL]: 'Insertion Removal Tool',
  [ToolType.POSITIONER]: 'Positioner',
  [ToolType.TORQUEADAPTER]: 'Torque Adapter',
  [ToolType.TURRETHEAD]: 'Turret Head',
};

export const toolTypeToTitle = {
  [ToolType.CRIMP]: 'Crimpers',
  [ToolType.DIESET]: 'Die Sets',
  [ToolType.INSERTIONREMOVAL]: 'Insertions + Removals',
  [ToolType.POSITIONER]: 'Positioners',
  [ToolType.TORQUEADAPTER]: 'Torque Adapters',
  [ToolType.TURRETHEAD]: 'Turret Heads',
};

export const toolTypeToAdditionalFields = {
  [ToolType.INSERTIONREMOVAL]: InsertionRemovalFields,
  [ToolType.POSITIONER]: PositionerFields,
  [ToolType.TURRETHEAD]: TurretHeadFields,
};

const ToolRelationRows = ({ editing }: Props) => {
  const { control } = useFormContext<Part>();
  const {
    fields: partTools,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'partTools',
  });

  const handleNewPartTool = () => {
    append({ toolId: '', notes: '', partNumber: '', type: '' });
  };

  const { data: res } = useTools();
  const defaultOptions = res ? res.data.filter((option) => !partTools.some((t) => t.toolId === option.id)) : [];

  return (
    <FormControl>
      <FormLabel id="ToolRelationRows">
        <Text>Tools</Text>
      </FormLabel>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>PART NUMBER</Th>
            <Th>TYPE</Th>
            <Th>USAGE</Th>
            {editing && (
              <Th textAlign="right">
                <Button onClick={handleNewPartTool}>+</Button>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {partTools.map((partTool, index) => {
            return (
              <ToolRelationRow
                key={partTool.id}
                index={index}
                editing={editing}
                remove={remove}
                update={update}
                defaultOptions={defaultOptions.map(toOption)}
              />
            );
          })}
        </Tbody>
      </Table>
    </FormControl>
  );
};

export default ToolRelationRows;
