import { Connector, ConnectorData, DesignPart, Part, PartGender, PartType, Passive } from '@web/apps/types';

const getPartPrefix = (part: Part) => {
  const partType = part.type;

  switch (partType) {
    case PartType.CONNECTOR:
      return (part as Connector).gender === PartGender.FEMALE ? 'J' : 'P';
    case PartType.PASSIVE:
      return (part as Passive).subtype?.charAt(0) ?? 'PSV';
    default:
      return getPrefixForPartType(partType);
  }
};

const getDesignPartPrefix = (designPart: DesignPart) => {
  const partType = designPart.partData.type;

  switch (partType) {
    case PartType.CONNECTOR: {
      const connectorData = designPart.partData as ConnectorData;
      return connectorData.gender === PartGender.FEMALE ? 'J' : 'P';
    }
    case PartType.PASSIVE: {
      const passive = designPart.partData as Passive;
      return passive.subtype?.charAt(0) ?? 'PSV';
    }
    default:
      return getPrefixForPartType(partType);
  }
};

const getPrefixForPartType = (partType: PartType) => {
  switch (partType) {
    case PartType.CABLE:
      return 'C';
    case PartType.GENERIC:
      return 'GP';
    case PartType.PIGTAIL:
      return 'PT';
    case PartType.SPLICE:
      return 'SPL';
    case PartType.WIRE:
      return 'W';
    default:
      return partType.toUpperCase();
  }
};

const generateUniqueDesignPartName = (designParts: DesignPart[], prefix: string) => {
  let i = 1;
  while (designParts.some((p) => p.name === `${prefix}${i}`)) {
    i++;
  }
  return `${prefix}${i}`;
};

export const generateDesignPartNameFromPart = (designParts: DesignPart[], part: Part) => {
  const prefix = getPartPrefix(part);
  return generateUniqueDesignPartName(designParts, prefix);
};

export const generateDesignPartNameFromDesignPart = (designParts: DesignPart[], designPart: DesignPart) => {
  const prefix = getDesignPartPrefix(designPart);
  return generateUniqueDesignPartName(designParts, prefix);
};
