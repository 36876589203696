import { Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { Design } from '@web/apps/types';
import { RouteNames } from '@web/consts/routeNames';
import { ReactFlowProvider } from '@xyflow/react';
import { useRef } from 'react';
import { generatePath } from 'react-router-dom';

import { useDeleteDesignMutation } from '../api/designs-api';
import { Extension, useExportDesignMutation } from '../api/export-api';
import { DesignProvider } from '../hooks/useDesign';
import { DesignIdProvider } from '../hooks/useDesignId';
import { DesignActions } from './DesignActions';
import { MiniReactFlow, ReactFlowPortalRef } from './MiniReactFlow';
import { PartRevisionLabel } from './PartRevisionLabel';

export const DesignRow = ({
  design,
  openCloneModal,
  openEditModal,
  setSelectedDesign,
}: {
  design: Design;
  openCloneModal: () => void;
  openEditModal: () => void;
  setSelectedDesign: (design: Design) => void;
}) => {
  const reactFlowRef = useRef<ReactFlowPortalRef>(null);
  const designPath = generatePath(RouteNames.DESIGNS.DESIGN, { designId: design.id });

  const { mutate: exportDesign } = useExportDesignMutation();
  const { mutate: deleteDesign } = useDeleteDesignMutation();

  const handleRowClick = () => window.open(designPath, '_blank', 'noopener,noreferrer');

  const handleRowMouseOver = () => setSelectedDesign(design);

  const handleExportDesign = async (design: Design, extension: Extension) => {
    if (reactFlowRef.current) {
      const base64Image = await reactFlowRef.current.exportImage();
      exportDesign({
        designId: design.id,
        partNumber: design.partNumber,
        partRevision: design.partRevision,
        extension: extension,
        images: extension === 'pdf' ? [{ layout: base64Image }] : undefined,
      });
    }
  };

  const handleDeleteDesign = (design: Design) => deleteDesign({ designId: design.id });

  return (
    <Tr role="group" onClick={handleRowClick} onMouseOver={handleRowMouseOver}>
      <Td>
        <DesignActions
          onEdit={openEditModal}
          onClone={openCloneModal}
          onExportPdf={() => handleExportDesign(design, 'pdf')}
          onExportExcel={() => handleExportDesign(design, 'xlsx')}
          onDelete={() => handleDeleteDesign(design)}
          onClick={(e) => e.stopPropagation()}
        />
        {design.id && (
          <ReactFlowProvider>
            <DesignIdProvider designId={design.id}>
              <DesignProvider>
                <MiniReactFlow ref={reactFlowRef} />
              </DesignProvider>
            </DesignIdProvider>
          </ReactFlowProvider>
        )}
      </Td>
      <Td>
        <PartRevisionLabel partNumber={design.partNumber} partRevision={design.partRevision} />
      </Td>
      <Td>
        {design.description && (
          <Tooltip label={design.description}>
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {design.description}
            </Text>
          </Tooltip>
        )}
      </Td>
      <Td>{design.lengthUnit}</Td>
      <Td>
        {design.creator?.name && (
          <Tooltip label={design.creator.name}>
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {design.creator.name}
            </Text>
          </Tooltip>
        )}
      </Td>
      <Td>
        <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" paddingRight={4}>
          {design.tenant.name}
        </Text>
      </Td>
    </Tr>
  );
};
