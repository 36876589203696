import { Box, Text } from '@chakra-ui/react';
import { UUID } from '@web/apps/types';
import { Node, NodeProps } from '@xyflow/react';

import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { HandleTypes } from '../../../types/handles.ts';
import { getNodeColor } from '../../../utils/common.ts';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { PassiveShape } from './PassiveShape.tsx';

export type PassiveNodeData = {
  designPartId: UUID;
};

export const defaultPassiveNodeData: PassiveNodeData = {
  designPartId: '',
};

export type PassiveNodeType = Node<PassiveNodeData>;

/**
 * Passive node component.
 * @param props
 * @constructor
 */
export const PassiveNode = (props: NodeProps<PassiveNodeType>) => {
  const { data = defaultPassiveNodeData, selected } = props;
  const designPart = useDesignParts().getDesignPartById(data.designPartId);

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative">
      <Text color="gray.900" fontFamily="mono" position="absolute" top="-20px">
        {designPart.name}
      </Text>
      <PassiveShape designPart={designPart} color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
