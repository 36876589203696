import { PatternProps } from '@web/apps/types';

export const Kevlar = ({ id, color = 'black', lightColor = 'white' }: PatternProps) => (
  <pattern id={`${id}-pattern`} patternUnits="userSpaceOnUse" width="8" height="8">
    <rect width="8" height="8" fill={lightColor} />
    <line x1="0" y1="1.25" x2="8" y2="1.25" stroke={color} strokeWidth={1} />
    <line x1="0" y1="4" x2="8" y2="4" stroke={color} strokeWidth={1} />
    <line x1="0" y1="7" x2="8" y2="7" stroke={color} strokeWidth={1} />
    <line x1="0" y1="4" x2="8" y2="1.25" stroke={color} strokeWidth={1} />
    <line x1="0" y1="7" x2="8" y2="4" stroke={color} strokeWidth={1} />
  </pattern>
);
