import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { Connection, Design, DesignPart } from '@web/apps/types';

import { useDesignId } from './useDesignId';

export const emptyConnections: Connection[] = [];
export const emptyDesignParts: DesignPart[] = [];

// Define the select function
const selectData = (design: Design | undefined) => ({
  designParts: design?.designParts || emptyDesignParts,
  connections: design?.connections || emptyConnections,
});

export const useConnectionsData = () => {
  // Get the design ID
  const designId = useDesignId();

  // Fetch connections and design parts (for connection points)
  const { data, isLoading, isSuccess, error } = useDesignQuery(designId, selectData);

  return { data, isLoading, isSuccess, error };
};
