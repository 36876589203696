import {
  ConnectorData,
  DesignPart,
  isCableDesignPart,
  isOverwrapDesignPart,
  isWireDesignPart,
  PartType,
  ResolvedConnectionPoint,
  WireData,
} from '@web/apps/types';

/**
 * Check if a part accepts contacts.
 * @param part
 */
export const acceptsContacts = (part: DesignPart | null | undefined): boolean =>
  !!part && part.partData.type === PartType.CONNECTOR && (part.partData as ConnectorData).acceptsContacts;

/**
 * Check if a part is a connection target.
 * @param part
 */
export const isConnectionTarget = (part: DesignPart | null | undefined): boolean =>
  !!(
    part &&
    part.connectionPoints &&
    new Set([PartType.CONNECTOR, PartType.PASSIVE, PartType.PIGTAIL, PartType.SPLICE]).has(part.partData.type)
  );

/**
 * Check if a part is consumable.
 * @param part
 */
export const isConsumable = (part: DesignPart | null | undefined): boolean =>
  !!part && isOverwrapDesignPart(part) && part.partData.consumable;

/**
 * Normalizes the source and target IDs to ensure consistent source/target pairs for undirected edges.
 * @param firstId
 * @param secondId
 */
export const normalizeSourceTargetIds = (firstId: string, secondId: string) => {
  const [source, target] = [firstId, secondId].sort((a, b) => a.localeCompare(b));

  return { source, target, key: `${source}:${target}` };
};

/**
 * Normalizes the source and target objects to ensure consistent source/target pairs for undirected edges.
 * @param firstObj
 * @param secondObj
 * @param propertyKey
 */
export const normalizeSourceTargetObjects = <T extends object>(firstObj: T, secondObj: T, propertyKey: keyof T) => {
  const getValue = (obj: T) => String(obj[propertyKey]);

  const [source, target] = [firstObj, secondObj].sort((a, b) => {
    const valueA = getValue(a);
    const valueB = getValue(b);
    return valueA.localeCompare(valueB);
  });

  return { source, target, key: `${getValue(source)}:${getValue(target)}` };
};

/**
 * uniqueDesignPartsByPartNumber returns a list of unique DesignParts by part number.
 * @param designParts
 */
export const uniqueDesignPartsByPartNumber = (designParts: DesignPart[]): DesignPart[] => {
  return designParts.reduce<DesignPart[]>((acc, item) => {
    if (!acc.some((existingItem) => existingItem.partData.partNumber === item.partData.partNumber)) {
      acc.push(item);
    }
    return acc;
  }, []);
};

/**
 * getWireData returns the wire data given a connection point for a conductor.
 * @param cp
 */
export const getWireData = (cp: ResolvedConnectionPoint): WireData | null => {
  if (!cp?.designPart) return null;

  if (isWireDesignPart(cp.designPart)) {
    return cp.designPart.partData;
  }

  if (isCableDesignPart(cp.designPart)) {
    return cp.designPart.partData.partWires?.[cp.original.position]?.wire || null;
  }

  return null;
};
