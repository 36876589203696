import { Box, HStack, MenuItemOption, MenuOptionGroup, Spinner, Text } from '@chakra-ui/react';
import {
  useCreateDesignPartLayoutEdgeMutation,
  useDeleteDesignPartLayoutEdgeMutation,
} from '@web/apps/Design/api/design-part-layout-edges-api.ts';
import { useCreateDesignPartMutation } from '@web/apps/Design/api/design-parts-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { useDesignParts } from '@web/apps/Design/hooks/useDesignParts.tsx';
import { generateDesignPartNameFromPart } from '@web/apps/Design/utils/generateDesignPartName.ts';
import { DesignPart, DesignPartLayoutEdge, Part, PartType, UUID } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useMemo } from 'react';

import { usePartSearchModal } from '../../../../hooks/usePartSearchModal.tsx';
import AddOverwrapButton from '../../../Layout/components/AddOverwrapButton.tsx';
import { isSegmentEdge } from '../../relational_types.ts';

/**
 * Hook to add a new overwrap (by opening Part Search Modal), or toggle an existing overwrap.
 * @param elementId
 */
export const useRelationalOverwrapActions = (elementId?: UUID) => {
  const { getEdge } = useReactFlow();
  const { openModal, closeModal } = usePartSearchModal();
  const designId = useDesignId();
  const { designParts = [] } = useDesignParts();

  const overwraps = designParts.filter((designPart) => designPart.partData['type'] === PartType.OVERWRAP);
  const { mutate: createDesignPart } = useCreateDesignPartMutation();
  const { mutateAsync: createDesignPartLayoutEdge, isPending: isCreatingDesignPartLayoutEdge } =
    useCreateDesignPartLayoutEdgeMutation();
  const { mutateAsync: deleteDesignPartLayoutEdge, isPending: isDeletingDesignPartLayoutEdge } =
    useDeleteDesignPartLayoutEdgeMutation();

  const getDesignPartLayoutEdges = useCallback(() => {
    const edge = getEdge(elementId || '');
    return edge && isSegmentEdge(edge) ? (edge.data.designPartLayoutEdges ?? []) : [];
  }, [elementId, getEdge]);

  const addOverwrapDesignPart = useCallback(
    (part: Part) => {
      if (!elementId) return;
      createDesignPart({
        designId,
        data: { name: generateDesignPartNameFromPart(designParts, part), partId: part.id },
      });
      closeModal();
    },
    [closeModal, elementId, designId, createDesignPart, designParts],
  );

  const handleAddOverwrap = useCallback(() => {
    openModal({
      initialPartTypeFilters: [PartType.OVERWRAP],
      filtersDisabled: true,
      renderSearchResultAction: (part: Part) => {
        return <AddOverwrapButton part={part} onPartAdded={() => addOverwrapDesignPart(part)} />;
      },
    });
  }, [openModal, addOverwrapDesignPart]);

  const handleToggleOverwrap = useCallback(
    async (overwrap: DesignPart) => {
      if (!elementId) return;

      const existingDesignPartLayoutEdge = getDesignPartLayoutEdges()?.find(
        (designPartLayoutEdge) => designPartLayoutEdge.designPartId === overwrap.id,
      );

      if (existingDesignPartLayoutEdge) {
        await deleteDesignPartLayoutEdge({
          designId,
          designPartLayoutEdgeId: existingDesignPartLayoutEdge.id,
        });
      } else {
        await createDesignPartLayoutEdge({
          designId,
          designPartId: overwrap.id,
          layoutEdgeId: elementId,
        });
      }
    },
    [designId, elementId, getDesignPartLayoutEdges, createDesignPartLayoutEdge, deleteDesignPartLayoutEdge],
  );

  const overwrapActions = useMemo(() => {
    const designPartLayoutEdges = getDesignPartLayoutEdges();

    return (
      <MenuOptionGroup
        // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
        title={
          <HStack as="span">
            <Box as="span">Overwraps</Box>
            {(isCreatingDesignPartLayoutEdge || isDeletingDesignPartLayoutEdge) && (
              <Box as="span">
                <Spinner as="span" size="xs" />
              </Box>
            )}
          </HStack>
        }
        type="checkbox"
        value={designPartLayoutEdges.map(
          (designPartLayoutEdge: DesignPartLayoutEdge) => designPartLayoutEdge.designPartId,
        )}
      >
        {overwraps.length > 0 ? (
          overwraps.map((overwrap, index) => (
            <MenuItemOption key={index} value={overwrap.id} onClick={() => handleToggleOverwrap(overwrap)}>
              <HStack spacing={4}>
                <Text flex={1} maxW={52} isTruncated>
                  {overwrap.name}
                </Text>
              </HStack>
            </MenuItemOption>
          ))
        ) : (
          <MenuItemOption isDisabled>No Overwraps</MenuItemOption>
        )}
      </MenuOptionGroup>
    );
  }, [
    getDesignPartLayoutEdges,
    handleToggleOverwrap,
    overwraps,
    isCreatingDesignPartLayoutEdge,
    isDeletingDesignPartLayoutEdge,
  ]);

  return { handleAddOverwrap, overwrapActions };
};
