import { createGroupStyledOptions, GroupedOption, Option } from '@web/common/select.ts';
import { SingleValue } from 'chakra-react-select';
import { PropsValue } from 'react-select';

import { FieldKey, RowId } from '../WiringListRow/types.ts';
import { EditableCellSelect } from './EditableCellSelect.tsx';
import { WiringListCell } from './WiringListCell.tsx';

interface Props {
  rowId: RowId;
  fieldKey: FieldKey;
  value: PropsValue<Option>;
  options: (Option | GroupedOption)[];
  onCellClick: () => void;
  onFieldChange: (fieldKey: FieldKey, value: string) => void;
  onSave: () => void;
}

export const WiringListSelectCell = ({
  rowId,
  fieldKey,
  value,
  options,
  onCellClick,
  onFieldChange,
  onSave,
}: Props) => {
  const handleChange = (newValue: SingleValue<Option>) => {
    onFieldChange(fieldKey, newValue ? newValue.value : '');
  };

  return (
    <WiringListCell rowId={rowId} fieldKey={fieldKey} onCellClick={onCellClick} onEscape={onSave}>
      {({ isInEditMode }) => (
        <EditableCellSelect
          value={value}
          options={options}
          isInEditMode={isInEditMode}
          onChange={handleChange}
          onEnterKeyPress={onSave}
          chakraStyles={createGroupStyledOptions('Used', {
            color: 'gray.400',
          })}
        />
      )}
    </WiringListCell>
  );
};
