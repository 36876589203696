import { ToastId } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { UUID } from '@web/apps/types';
import { downloadFile, getBaseOptions, getUrl } from '@web/common/api.ts';
import { useRef } from 'react';

export type Extension = 'pdf' | 'xlsx';

// React Query Hook: useExportDesignMutation
interface ExportDesignParams {
  designId: UUID;
  partNumber: string;
  partRevision: string;
  extension: Extension;
  images?: { layout: string }[];
}

const postExport = async (designId: UUID, extension: string, images?: { layout: string }[]) => {
  const url = getUrl(`/api/v1/designs/${designId}/export.${extension}`);

  const options = await getBaseOptions('POST');
  const response = await fetch(url, { ...options, body: images ? JSON.stringify({ design: { images } }) : null });

  if (!response.ok) {
    throw new Error('Failed to download export');
  }

  return response.blob();
};

export const useExportDesignMutation = () => {
  const { showLoadingToast, showErrorToast, closeToast } = useDesignToast();
  const toastIdRef = useRef<ToastId | undefined>(undefined);

  return useMutation({
    mutationKey: [`export-design`],
    mutationFn: async ({ designId, images, partNumber, partRevision, extension }: ExportDesignParams) => {
      const filename = `design-${partNumber}${partRevision ? `_${partRevision}` : ''}.${extension}`;
      const blob = await postExport(designId, extension, images);
      downloadFile(blob, filename);
    },
    onMutate: ({ extension }: ExportDesignParams) => {
      toastIdRef.current = showLoadingToast('Exporting Design', `Exporting design to .${extension}`);
    },
    onSuccess: () => {
      if (toastIdRef.current) {
        closeToast(toastIdRef.current);
      }
    },
    onError: (error) => {
      if (toastIdRef.current) {
        closeToast(toastIdRef.current);
      }
      showErrorToast('Download Failed', error.message);
    },
  });
};
