import { Edge, Node } from '@xyflow/react';
import { Fragment, MouseEvent } from 'react';

import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useRelationalBreakoutPointActions } from './useRelationalBreakoutPointActions.tsx';
import { useRelationalConnectorActions } from './useRelationalConnectorActions.tsx';
import { useRelationalLayoutPointActions } from './useRelationalLayoutPointActions.tsx';
import { useRelationalMeasurementActions } from './useRelationalMeasurementActions.tsx';
import { useRelationalNoteGroupActions } from './useRelationalNoteGroupActions.tsx';
import { useRelationalPaneActions } from './useRelationalPaneActions.tsx';
import { useRelationalSegmentActions } from './useRelationalSegmentActions.tsx';

export const useRelationalLayoutActions = () => {
  // Element Context menu actions
  const breakoutPointActions = useRelationalBreakoutPointActions();
  const layoutPointActions = useRelationalLayoutPointActions();
  const measurementEdgeActions = useRelationalMeasurementActions();
  const segmentEdgeActions = useRelationalSegmentActions();
  const connectorActions = useRelationalConnectorActions();
  const paneActions = useRelationalPaneActions();
  const noteGroupActions = useRelationalNoteGroupActions();

  const actionsArray: MenuActions[] = [
    breakoutPointActions,
    layoutPointActions,
    measurementEdgeActions,
    segmentEdgeActions,
    connectorActions,
    paneActions,
    noteGroupActions,
  ];

  // Close all context menus
  const closeAllMenus = () => {
    actionsArray.forEach((actions) => actions.closeMenu());
  };

  // Generic context menu handler
  const onGraphElementContextMenu = (event: MouseEvent, element: Node | Edge) => {
    event.preventDefault();
    closeAllMenus();

    let elementTypeActions = actionsArray.find((actions) => actions.elementType === element.type);

    if (elementTypeActions) {
      elementTypeActions.showMenu(event, element);
    }
  };

  const onPaneContextMenu = (event: MouseEvent | globalThis.MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    paneActions.showMenu(event);
  };

  // Context menus
  const contextMenus = (
    <>
      {actionsArray.map(({ menu, elementType }, index) => (
        <Fragment key={elementType || index}>{menu}</Fragment>
      ))}
    </>
  );

  return {
    closeAllMenus,
    onGraphElementContextMenu,
    onPaneContextMenu,
    contextMenus,
  };
};
