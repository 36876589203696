import { PatternProps } from '@web/apps/types';

export const Nylon = ({ id, color = 'black', lightColor = 'white' }: PatternProps) => (
  <pattern id={`${id}-pattern`} patternUnits="userSpaceOnUse" width="2" height="8">
    <rect width="4" height="8" fill={lightColor} />
    <line x1="-2" y1="0" x2="2" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="-2" y1="8" x2="2" y2="0" stroke={color} strokeWidth={0.75} />
    <line x1="0" y1="8" x2="4" y2="0" stroke={color} strokeWidth={0.75} />
    <line x1="0" y1="0" x2="4" y2="8" stroke={color} strokeWidth={0.75} />
  </pattern>
);
