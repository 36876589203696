import { UUID } from '@web/apps/types';

import { Graph } from '../../../../../types/reactFlow.ts';
import { findNodeById, updateNodeData } from '../../graph/NodeFactory.ts';
import { CustomNodeData } from '../../types.ts';
import { Operations } from '../Operations.ts';

// Operation to update node data
export type UpdateNodeDataOperation = {
  type: 'UpdateNodeData';
  params: {
    nodeId: UUID;
    data: Partial<CustomNodeData>;
  };
};

/**
 * Updates the data of a node.
 */
export class UpdateNodeData implements Operations<UpdateNodeDataOperation> {
  // Execute the operation
  execute(graph: Graph, operation: UpdateNodeDataOperation): Graph {
    const { nodes, edges } = graph;
    const { nodeId, data } = operation.params;

    // Validate parameters
    const node = findNodeById(nodes, nodeId);
    if (!node) {
      console.warn(`Node not found for id ${nodeId}.`);
      return graph;
    }

    return {
      nodes: updateNodeData(nodes, nodeId, data),
      edges,
    };
  }
}
