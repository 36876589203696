import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { useBuildNoteActions } from './useBuildNoteActions.tsx';
/**
 * Hook for pane actions.
 */
export const useRelationalPaneActions = () => {
  const { showMenu, closeMenu, renderMenu, menuPosition } = useActionsBase();
  const { buildNoteActions } = useBuildNoteActions({ closeMenu, menuPosition, closeAfterAdd: true });

  const menu = renderMenu(<>{buildNoteActions}</>);

  return {
    showMenu,
    closeMenu,
    menu,
  } as MenuActions;
};
