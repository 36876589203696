import { ChangeEvent } from 'react';

import { FieldKey, RowId } from '../WiringListRow/types.ts';
import { EditableCellText } from './EditableCellText.tsx';
import { WiringListCell } from './WiringListCell.tsx';

interface Props {
  rowId: RowId;
  fieldKey: FieldKey;
  value: string;
  onCellClick: () => void;
  onFieldChange: (fieldKey: FieldKey, value: string) => void;
  onSave: () => void;
}

export const WiringListTextCell = ({ rowId, fieldKey, value, onCellClick, onFieldChange, onSave }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onFieldChange(fieldKey, event.target.value);
  };

  return (
    <WiringListCell rowId={rowId} fieldKey={fieldKey} onCellClick={onCellClick} onEscape={onSave}>
      {({ isInEditMode }) => (
        <EditableCellText value={value} isInEditMode={isInEditMode} onChange={handleChange} onEnterKeyPress={onSave} />
      )}
    </WiringListCell>
  );
};
