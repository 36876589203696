import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Design } from '@web/apps/types';
import ErrorText from '@web/components/form/ErrorText';
import TypeAheadSelectInput from '@web/components/form/TypeAheadSelectInput';
import Loading from '@web/components/Loading';
import { useAdminTenants, useConfiguration } from '@web/queries/admin.ts';
import { useCurrentUser } from '@web/queries/users';
import { SingleValue } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaLock, FaUnlock } from 'react-icons/fa';

export type DesignFormData = Pick<
  Design,
  | 'id'
  | 'name'
  | 'description'
  | 'partNumber'
  | 'partRevision'
  | 'lengthUnit'
  | 'measurementMode'
  | 'lockedAt'
  | 'tenant'
>;

interface Props {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: DesignFormData) => void;
  submitButtonText?: string;
  initialData?: DesignFormData;
}

const DesignModal = ({ title, isOpen, onClose, onSubmit, submitButtonText, initialData }: Props) => {
  const { data: configuration } = useConfiguration();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<DesignFormData>();

  useEffect(() => {
    if (isOpen) {
      reset(initialData || {});
    }
  }, [isOpen, initialData, reset]);

  useEffect(() => {
    if (!isOpen) {
      reset(initialData || {});
    }
  }, [isOpen, initialData, reset]);

  const { data: user } = useCurrentUser();
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [value, setValue] = useState<SingleValue<{ label: string; value: string }>>(null);

  const { isLoading, data } = useAdminTenants('1', query);

  const tenantOptions = isLoading
    ? [{ value: 'loading', label: 'Loading…' }]
    : data?.data.map((tenant: { id: string; name: string }) => ({
        value: tenant.id,
        label: tenant.name,
      })) || [];

  if (!user) {
    return <Loading message="Loading user…" />;
  }

  const lengthUnitOptions =
    configuration?.designs?.lengthUnits.map((unit) => (
      <option key={unit} value={unit}>
        {unit}
      </option>
    )) || [];

  const measurementModeOptions =
    configuration?.designs?.measurementModes.map((mode) => (
      <option key={mode} value={mode}>
        {mode}
      </option>
    )) || [];

  const handleBlur = () => {
    setError(value?.label.trim().length ? '' : 'Tenant required');
  };

  const closeHandler = () => {
    setValue({ label: '', value: '' });
    onClose();
  };

  const submitHandler = (data: DesignFormData) => {
    onSubmit(data);
    closeHandler();
    reset();
  };

  const lockedAt = watch('lockedAt');

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="custom">
      <ModalOverlay />
      <ModalContent w="600px">
        <ModalHeader>
          {title || 'Design'}
          <Box borderBottom="1px solid" borderColor="gray.300" px={2} py={2} />
        </ModalHeader>
        <ModalBody w="full">
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              {user.employee && !initialData && (
                <GridItem colSpan={5}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="tenant">Tenant</FormLabel>
                    <Controller
                      name="tenant.id"
                      control={control}
                      rules={{ required: true, onBlur: handleBlur }}
                      render={({ field: { onBlur, onChange } }) => {
                        const handleChange = (selectedOption?: SingleValue<{ label: string; value: string }>) => {
                          onChange(selectedOption?.value ?? null);
                          setError('');
                          setValue(selectedOption ?? { label: '', value: '' });
                        };
                        return (
                          <TypeAheadSelectInput
                            options={tenantOptions}
                            onChange={handleChange}
                            querySetter={setQuery}
                            value={value}
                            noOptionsMessage={() => 'No matching tenants'}
                            onBlur={onBlur}
                            placeholder="Select a tenant…"
                          />
                        );
                      }}
                    />
                    <ErrorText>{error}</ErrorText>
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!errors.partNumber} isRequired>
                  <FormLabel htmlFor="partNumber">Part Number</FormLabel>
                  <Input
                    id="partNumber"
                    placeholder="Part Number"
                    autoComplete="off"
                    {...register('partNumber', { required: 'Part Number is required' })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!errors.partRevision}>
                  <FormLabel htmlFor="partRevision">Part Revision</FormLabel>
                  <Input
                    id="partRevision"
                    placeholder="Part Revision"
                    autoComplete="off"
                    {...register('partRevision')}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!errors.lengthUnit} isRequired>
                  <FormLabel htmlFor="unit">Unit</FormLabel>
                  <Select
                    id="unit"
                    placeholder="Select unit"
                    {...register('lengthUnit', { required: 'Unit is required' })}
                  >
                    {lengthUnitOptions}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={!!errors.measurementMode} isRequired>
                  <FormLabel htmlFor="unit">Measurement Mode</FormLabel>
                  <Select
                    id="measurementMode"
                    placeholder="Select measurement mode"
                    {...register('measurementMode', { required: 'Measurement mode is required' })}
                  >
                    {measurementModeOptions}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={5}>
                <FormControl isInvalid={!!errors.description}>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Textarea id="description" placeholder="Description" {...register('description')} />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isInvalid={!!errors.lockedAt}>
                  <label htmlFor="lockedAt">
                    {lockedAt ? (
                      <HStack>
                        <FaLock />
                        <Text>{lockedAt}</Text>
                      </HStack>
                    ) : (
                      <FaUnlock />
                    )}
                  </label>
                  <input id="lockedAt" type="checkbox" {...register('lockedAt')} style={{ display: 'none' }} />
                </FormControl>
              </GridItem>
            </Grid>
            <ModalFooter px={0}>
              <Button variant="ghost" mr={3} onClick={closeHandler}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit">
                {submitButtonText}
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DesignModal;
