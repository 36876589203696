import { UUID } from '@web/apps/types';

import { useDeleteControlPointMutation } from '../../../../api/layout-nodes-api';
import { useDesignId } from '../../../../hooks/useDesignId';

export const useDeleteControlPointAction = (closeMenu: () => void, controlPointId: UUID) => {
  const designId = useDesignId();
  const { mutateAsync: deleteControlPoint } = useDeleteControlPointMutation();

  const onRemove = () => {
    deleteControlPoint({ designId, controlPointId });
    closeMenu();
  };

  return onRemove;
};
