import { User } from '@web/apps/types';

import { doRequest, getUrl } from '../common/api';

export interface UpdateUserInput {
  name: string;
  email: string;
  phone: string;
}

export async function apiGetInvitedUser(userId: string) {
  return doRequest('GET', getUrl(`/api/v1/invitations/${userId}`));
}

export async function apiPutInvitedUser(userId: string, invitation: { name: string; password: string }) {
  return doRequest('PUT', getUrl(`/api/v1/invitations/${userId}`), JSON.stringify({ invitation }));
}

export async function apiUpdateUserProfile(user: UpdateUserInput) {
  const endpoint = getUrl(`/api/v1/current_user`);
  return doRequest('PUT', endpoint, JSON.stringify({ user }));
}

export async function apiGetProfile() {
  const endpoint = getUrl(`/api/v1/current_user`);
  return (await doRequest('GET', endpoint)) as User | undefined;
}
