import { PartType } from '@web/apps/types';
import { translatePartType } from '@web/apps/utils/parts';

import MultiSelectFilter from '../../../components/MultiSelectFilter';

const allPartTypes = Object.values(PartType);
const allPartTypesSet = new Set(allPartTypes);

interface Props {
  selectedOptions: PartType[];
  filtersDisabled?: boolean;
  onChangeFilter: (partTypes: PartType[]) => void;
}

export const PartSearchFilters = ({ selectedOptions, filtersDisabled, onChangeFilter }: Props) => {
  return (
    <MultiSelectFilter
      options={allPartTypes}
      selectedOptions={selectedOptions}
      isToggleable={!filtersDisabled}
      onFilter={(updatedOptions) => {
        const updatedPartTypes = updatedOptions.filter((partType) => allPartTypesSet.has(partType as PartType));
        onChangeFilter(updatedPartTypes as PartType[]);
      }}
      optionTransform={translatePartType}
    />
  );
};
