import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  HStack,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { isOverwrapDesignPart, PartType } from '@web/apps/types';
import Title from '@web/components/Title.tsx';

import { getPatternType } from '../../../../utils/patterns.ts';
import { DynamicPatternIcon } from '../../../components/DynamicPatternIcon.tsx';
import { useDesignOverview } from '../../../hooks/useDesignOverview.tsx';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import DiffIndicator from './DiffIndicator.tsx';

interface Props extends BoxProps {}

const OverwrapList = (props: Props) => {
  const { id: designId, lengthUnit } = useDesignOverview();
  const { designParts } = useDesignParts();

  const overwraps = designParts
    .filter((part) => isOverwrapDesignPart(part))
    .map((part) => {
      const colorScheme = part.partData.color;
      const patternType = getPatternType(part.partData.subtype);
      return (
        patternType && (
          <HStack key={part.id} p={2}>
            <DiffIndicator part={part} designId={designId} />
            <Tooltip label={part.partData.subtype} aria-label="Material" placement="right">
              <HStack>
                <DynamicPatternIcon width={30} height={12} colorScheme={colorScheme} patternType={patternType} />
                <Text isTruncated>{part.partData.partNumber}</Text>
                <Text>
                  ({part.quantity} {lengthUnit})
                </Text>
              </HStack>
            </Tooltip>
          </HStack>
        )
      );
    });

  return (
    <Box overflowY="auto" {...props}>
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem key={PartType.OVERWRAP} borderTop="1px solid" borderColor="gray.300" w="full" border="none">
          {({ isExpanded }) => (
            <>
              <AccordionButton h="30px" justifyContent="space-between" p={2}>
                <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} mb={1} mr={1} />
                <Box flex="1" textAlign="left">
                  <Title as="h4" title={<Text>Overwraps</Text>} size="sm" />
                </Box>
              </AccordionButton>
              <AccordionPanel px={2} py={0}>
                {overwraps}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default OverwrapList;
