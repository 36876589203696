import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { doRequest } from '@web/apps/Design/api/request-helpers.ts';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { Connection, UUID } from '@web/apps/types';
import { getUrl } from '@web/common/api.ts';

// API Calls
const createConnection = async (designId: UUID, data: { sourceId: UUID; destinationId: UUID; conductorId: UUID }) => {
  const url = getUrl(`/api/v1/designs/${designId}/connections`);
  return await doRequest<Connection>('POST', url, JSON.stringify({ connection: data }));
};

const updateConnection = async (
  designId: UUID,
  connectionId: UUID,
  data: {
    sourceId: UUID;
    destinationId: UUID;
    conductorId: UUID;
  },
) => {
  const url = getUrl(`/api/v1/designs/${designId}/connections/${connectionId}`);
  return await doRequest<Connection>('PATCH', url, JSON.stringify({ connection: data }));
};

const deleteConnection = async (designId: UUID, connectionId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/connections/${connectionId}`);
  await doRequest('DELETE', url);
};

// React Query Hook: useCreateConnectionMutation
export interface CreateConnectionParams {
  designId: UUID;
  data: {
    sourceId: UUID;
    destinationId: UUID;
    conductorId: UUID;
    signalName: string;
    sourceContactId: UUID;
    destinationContactId: UUID;
  };
}

export const useCreateConnectionMutation = (): UseMutationResult<
  Connection | undefined,
  Error,
  CreateConnectionParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-connection'],
    mutationFn: async ({ designId, data }: CreateConnectionParams) => createConnection(designId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      await invalidateQueries(designKeys.schematic(variables.designId));
    },
    onError: (error) => showErrorToast('Error Creating Connection', error.message),
  });
};

// React Query Hook: useUpdateConnectionMutation
export interface UpdateConnectionParams {
  designId: UUID;
  connectionId: UUID;
  data: {
    sourceId: UUID;
    destinationId: UUID;
    conductorId: UUID;
    signalName: string;
    sourceContactId: UUID;
    destinationContactId: UUID;
  };
}

export const useUpdateConnectionMutation = (): UseMutationResult<
  Connection | undefined,
  Error,
  UpdateConnectionParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-connection'],
    mutationFn: async ({ designId, connectionId, data }: UpdateConnectionParams) =>
      updateConnection(designId, connectionId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      await invalidateQueries(designKeys.schematic(variables.designId));
    },
    onError: (error) => showErrorToast('Error Updating Connection', error.message),
  });
};

// React Query Hook: useDeleteConnectionMutation
export interface DeleteConnectionParams {
  designId: UUID;
  connectionId: UUID;
}

export const useDeleteConnectionMutation = (): UseMutationResult<void, Error, DeleteConnectionParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-connection'],
    mutationFn: async ({ designId, connectionId }: DeleteConnectionParams) => deleteConnection(designId, connectionId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      await invalidateQueries(designKeys.schematic(variables.designId));
    },
    onError: (error) => showErrorToast('Error Deleting Connection', error.message),
  });
};
