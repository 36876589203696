import { Box, HStack } from '@chakra-ui/react';
import { useDesignOverview } from '@web/apps/Design/hooks/useDesignOverview.tsx';
import { NoteGroupBuildNote, NoteType, UUID } from '@web/apps/types';
import { Handle, Node, NodeProps, Position, useUpdateNodeInternals } from '@xyflow/react';
import { useEffect, useMemo } from 'react';

import { useDesignBuildNotes } from '../../../../../hooks/useDesignBuildNotes.tsx';
import { LayoutBomNote } from '../../../../Layout/components/nodes/NoteGroupNode/LayoutBomNote.tsx';
import { LayoutFlagNote } from '../../../../Layout/components/nodes/NoteGroupNode/LayoutFlagNote.tsx';
import { defaultLayoutConfig } from '../../../../Layout/config.ts';
import { useCalculateTargetDirection } from '../../../../Layout/hooks/notes/useCalculateTargetDirection.ts';

export type RelationalNoteGroupNodeData = {
  targetElementId?: UUID;
  noteGroupBuildNotes?: NoteGroupBuildNote[];
  designPartId?: UUID;
  designPartName?: string;
};

export const defaultRelationalNoteGroupNodeData: RelationalNoteGroupNodeData = {
  targetElementId: '',
  noteGroupBuildNotes: [],
  designPartId: '',
  designPartName: '',
};

export type RelationalNoteGroupNodeType = Node<RelationalNoteGroupNodeData>;

/**
 * Note Group point node component.
 * @param props
 * @constructor
 */
export const RelationalNoteGroupNode = ({
  id,
  dragging,
  data: { noteGroupBuildNotes, designPartName },
  selected,
  parentId,
}: NodeProps<RelationalNoteGroupNodeType>) => {
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);
  const { bom } = useDesignOverview();
  const updateNodeInternals = useUpdateNodeInternals();

  const { horizontal: horizontalPosition } = useCalculateTargetDirection(id, parentId);
  const flexDirection = horizontalPosition === Position.Right ? 'row' : 'row-reverse';

  useEffect(() => {
    updateNodeInternals(id);
  }, [updateNodeInternals, id, horizontalPosition]);

  const flagNotesForNoteGroup = useMemo(() => {
    const noteGroupBuildNoteIds = new Set(
      noteGroupBuildNotes?.map((noteGroupBuildNote) => noteGroupBuildNote.buildNoteId),
    );

    return flagNotes.filter((note) => noteGroupBuildNoteIds.has(note.id));
  }, [flagNotes, noteGroupBuildNotes]);

  const bomItems = bom.filter((item) => item.usage.includes(designPartName ?? ''));

  return (
    <Box
      key={horizontalPosition}
      position="relative"
      borderRadius="lg"
      borderWidth={selected ? 1 : 0}
      borderColor={defaultLayoutConfig.selectedNodeColor}
      padding={1}
    >
      <HStack gap={0} flexDirection={flexDirection}>
        {flagNotesForNoteGroup.map((flagNote) => (
          <LayoutFlagNote key={flagNote.id} flagNote={flagNote} disableTooltip={dragging} />
        ))}
        {bomItems.map((bomItem) => (
          <LayoutBomNote key={bomItem.id} bomItem={bomItem} disableTooltip={dragging} />
        ))}
      </HStack>
      <Box visibility="hidden">
        <Handle type="source" position={horizontalPosition} />
      </Box>
    </Box>
  );
};
