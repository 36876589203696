import { Backshell, PartType } from '@web/apps/types';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard from '../../components/PartsLibraryDashboard.tsx';

const BackshellsDashboard = () => {
  const methods = useForm<Backshell>({ defaultValues: { type: PartType.BACKSHELL } });

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.BACKSHELL} />
    </FormProvider>
  );
};

export default BackshellsDashboard;
