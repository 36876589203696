import { Box, HStack, StackProps, Text } from '@chakra-ui/react';
import { BOMItem, DesignPart } from '@web/apps/types';

import FieldValueList from '../../../components/FieldValueList';
import { usePartData } from '../../../hooks/usePartData.tsx';
import RemoveAlternateButton from '../components/RemoveAlternateButton.tsx';

interface Props extends StackProps {
  bomItem: BOMItem;
  hasFocus?: boolean;
  designParts?: DesignPart[];
}

/**
 * BOMItemDetails component displays the details of a BOM item.
 * @param bomItem
 * @param hasFocus
 * @param rest
 * @constructor
 */
const BOMItemDetails = ({ bomItem, hasFocus = false, designParts, ...rest }: Props) => {
  const { usage, alternates } = bomItem;
  const part = designParts?.find((part) => part.partData.partNumber === bomItem?.partNumber);
  const data = usePartData(part);

  // A bit of a hack, but we need to use displayType from BOMItem.displayType, and I don't want to make the more generic
  // usePartData hook more complex to handle this one-off case. If there are more cases like this, we can refactor.
  if (data[0] && data[0].value) {
    data[0].value = bomItem?.type || data[0].value;
  }

  const renderUsages = () => {
    if (usage.length === 0) return <Text>No usages</Text>;

    return usage.map((usage) => {
      const usagePart = designParts?.find((part) => part.name === usage);
      return (
        <HStack key={usage} w="full" mb={2} justify="space-between" spacing={8}>
          <Text>{usage}</Text>
          <Text>
            {parseFloat(`${usagePart?.quantity}`)} {bomItem?.unit}
          </Text>
        </HStack>
      );
    });
  };

  const renderAlternates = () => {
    if (alternates.length === 0) return <Text>No alternates</Text>;

    return alternates.map((alternate) => (
      <HStack key={alternate.id} mb={2}>
        <Text w="100px">{alternate.alternatePart.partNumber}</Text>
        <RemoveAlternateButton
          aria-label="Remove alternate part"
          partId={alternate.id}
          visibility={hasFocus ? 'visible' : 'hidden'}
          opacity={hasFocus ? 1 : 0}
          transition="opacity .3s ease-in-out, color .3s ease-in-out"
        />
      </HStack>
    ));
  };

  return (
    <HStack alignItems="flex-start" gap={4} p={4} {...rest}>
      <HStack flex={2}>
        <FieldValueList data={data} fieldProps={{ w: '120px' }} />
      </HStack>
      <HStack alignItems="flex-start" pb={2} flex={1}>
        <Box fontWeight={500}>Usage</Box>
        <Box>{renderUsages()}</Box>
      </HStack>
      <HStack alignItems="flex-start" pb={2} flex={1}>
        <Box fontWeight={500}>Alternates</Box>
        <Box>{renderAlternates()}</Box>
      </HStack>
    </HStack>
  );
};
export default BOMItemDetails;
