import { Text, Th, Thead, Tr, useToken } from '@chakra-ui/react';
import { useMemo } from 'react';

interface HeaderConfig {
  label: string;
  width: string;
  minWidth?: string;
  paddingLeft?: number;
}

interface Props {
  showHeader?: boolean;
}

/**
 * Renders the `<colgroup>` and `<thead>` elements for the wiring list table.
 */
export const WiringListHeader = ({ showHeader = true }: Props) => {
  const [spacing40, spacing60] = useToken('space', ['40', '60']);

  const headers = useMemo<HeaderConfig[]>(
    () => [
      { label: 'Cavity', width: spacing40, paddingLeft: 4 },
      { label: 'Destination', width: 'auto', minWidth: spacing40, paddingLeft: 2 },
      { label: 'Conductor', width: 'auto', minWidth: spacing40, paddingLeft: 2 },
      { label: 'Signal', width: 'auto', minWidth: spacing40, paddingLeft: 2 },
      { label: 'Contact', width: 'auto', minWidth: spacing40, paddingLeft: 2 },
      { label: 'Dest. Contact', width: 'auto', minWidth: spacing40, paddingLeft: 2 },
      { label: ' ', width: spacing60, minWidth: spacing60 },
    ],
    [spacing40, spacing60],
  );

  return (
    <>
      <colgroup>
        {headers.map(({ label, width, minWidth }) => (
          <col key={label} style={{ width, minWidth }} />
        ))}
      </colgroup>
      {showHeader && (
        <Thead color="gray.600" borderTop="1px solid" borderBottom="2px solid" borderColor="gray.300" fontWeight="500">
          <Tr h={14}>
            {headers.map(({ label, paddingLeft }) => (
              <Th key={label}>
                <Text pl={paddingLeft}>{label}</Text>
              </Th>
            ))}
          </Tr>
        </Thead>
      )}
    </>
  );
};
