import { HStack, Icon } from '@chakra-ui/react';
import { GroupedConductor } from '@web/apps/types';
import { FcCableRelease } from 'react-icons/fc';

import { useDesignParts } from '../../../../../hooks/useDesignParts';
import { BaseDetailsAccordionItem } from '../BaseDetailsAccordionItem';
import { ConductorDetails } from './ConductorDetails';
import { GroupedConductorDetailsHeader } from './GroupedConductorDetailsHeader';

interface Props {
  groupedConductor: GroupedConductor;
}

export const GroupedConductorDetails = ({ groupedConductor }: Props) => {
  const { conductors: conductorIds, sourceDesignPartId, targetDesignPartId } = groupedConductor;
  const { getDesignPartById } = useDesignParts();

  const sourceDesignPart = getDesignPartById(sourceDesignPartId);
  const targetDesignPart = getDesignPartById(targetDesignPartId);

  const conductors = conductorIds
    .map((conductorId) => getDesignPartById(conductorId))
    .filter((conductor) => !!conductor);

  return (
    <BaseDetailsAccordionItem
      header={
        <HStack spacing={4}>
          <Icon as={FcCableRelease} />
          <GroupedConductorDetailsHeader sourceDesignPart={sourceDesignPart} targetDesignPart={targetDesignPart} />
        </HStack>
      }
      borderColor="blue.200"
      bgColor="blue.50"
    >
      {conductors.map((conductor) => (
        <ConductorDetails key={conductor.id} conductor={conductor} />
      ))}
    </BaseDetailsAccordionItem>
  );
};
