import { Manufacturer } from '@web/apps/types';

import { doRequest, getUrl } from '../common/api';

interface ManufacturersResponse {
  data: Manufacturer[];
}

export const apiGetManufacturers = (query?: string): Promise<ManufacturersResponse | undefined> => {
  let endpoint = '/api/v1/manufacturers?per=10';
  if (query) {
    endpoint += `&q=${query}`;
  }
  return doRequest('GET', getUrl(endpoint));
};

export const apiPostManufacturer = (manufacturer: { name: string }): Promise<Manufacturer | undefined> => {
  return doRequest('POST', getUrl('/api/v1/manufacturers'), JSON.stringify({ manufacturer }));
};
