import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { UUID } from '@web/apps/types';
import { DesignPartLayoutEdge } from '@web/apps/types';
import { doRequest } from '@web/common/api';
import { getUrl } from '@web/common/api';

import { useDesignToast } from '../hooks/useDesignToast';
import { designKeys } from './query-keys';
import { useInvalidateQueries } from './react-query-helpers';

const createDesignPartLayoutEdge = async ({
  designId,
  designPartId,
  layoutEdgeId,
}: {
  designId: UUID;
  designPartId: UUID;
  layoutEdgeId: UUID;
}) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_part_layout_edges`);
  return await doRequest<DesignPartLayoutEdge>(
    'POST',
    url,
    JSON.stringify({
      designPartLayoutEdge: { designPartId, layoutEdgeId },
    }),
  );
};

type CreateDesignPartLayoutEdgeParams = {
  designId: UUID;
  designPartId: UUID;
  layoutEdgeId: UUID;
};

const deleteDesignPartLayoutEdge = async ({
  designId,
  designPartLayoutEdgeId,
}: {
  designId: UUID;
  designPartLayoutEdgeId: UUID;
}) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_part_layout_edges/${designPartLayoutEdgeId}`);
  return await doRequest<DesignPartLayoutEdge>('DELETE', url);
};

export const useCreateDesignPartLayoutEdgeMutation = (): UseMutationResult<
  DesignPartLayoutEdge | undefined,
  Error,
  CreateDesignPartLayoutEdgeParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-design-part-layout-edge'],
    mutationFn: createDesignPartLayoutEdge,
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error creating design part layout edge', error.message),
  });
};

export const useDeleteDesignPartLayoutEdgeMutation = (): UseMutationResult<
  DesignPartLayoutEdge | undefined,
  Error,
  { designId: UUID; designPartLayoutEdgeId: UUID }
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-design-part-layout-edge'],
    mutationFn: deleteDesignPartLayoutEdge,
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error deleting design part layout edge', error.message),
  });
};
