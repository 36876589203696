import { Badge, ChakraProps } from '@chakra-ui/react';
import { PartConfidence } from '@web/apps/types';

interface Props extends ChakraProps {
  confidence: PartConfidence;
}

const ConfidenceBadge = ({ confidence, ...rest }: Props) => {
  let colorScheme = 'green';
  if (confidence === PartConfidence.LOW) colorScheme = 'red';

  return (
    <Badge colorScheme={colorScheme} variant="solid" {...rest}>
      {confidence}
    </Badge>
  );
};

export default ConfidenceBadge;
