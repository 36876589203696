import { UUID } from '@web/apps/types';
import { Edge, Node, XYPosition } from '@xyflow/react';

import { createAndAddPartNoteEdge, createAndAddSegmentNoteEdge } from '../graph/EdgeFactory';
import { createAndAddNoteGroupNode } from '../graph/NodeFactory';
import { isNoteGroupNode } from '../types';
import { getMidpoint } from './graph';

// Gets the note group node for this element, or undefined if it doesn't exist in nodes
export const getNoteGroupNodeForElement = (nodes: Node[], targetElementId?: UUID) =>
  targetElementId ? nodes.find((node) => isNoteGroupNode(node) && node.data.targetElementId === targetElementId) : null;

// Given a position, returns an offset position for the note
export const defaultNoteNestedPosition = { x: -60, y: 80 };

export const getNoteOffsetPosition = (position: XYPosition): XYPosition => ({
  x: position.x - 60,
  y: position.y + 50,
});

// Generate new build notes and edges for layout element nodes and edges
export const generateNewBuildNotesAndEdges = (
  allNodes: Node[],
  nodesToAddNotesTo: Node[],
  edgesToAddNotesTo: Edge[],
) => {
  const newNodes: Node[] = [];
  const newEdges: Edge[] = [];

  nodesToAddNotesTo.forEach(({ id: nodeId }) => {
    const noteNode = createAndAddNoteGroupNode(
      newNodes,
      defaultNoteNestedPosition,
      { targetElementId: nodeId },
      { parentId: nodeId },
    );
    createAndAddPartNoteEdge(newEdges, noteNode.id, nodeId);
  });

  edgesToAddNotesTo.forEach((edge) => {
    const midpoint = getMidpoint(allNodes, edge);
    const noteNode = createAndAddNoteGroupNode(newNodes, getNoteOffsetPosition(midpoint), { targetElementId: edge.id });
    createAndAddSegmentNoteEdge(newEdges, noteNode.id, noteNode.id, { segmentId: edge.id });
  });

  return { nodes: newNodes, edges: newEdges };
};
