import { Box } from '@chakra-ui/react';
import { NoteGroupBuildNote, UUID } from '@web/apps/types';
import { Node, NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import { useEffect } from 'react';

import { useDesignOverview } from '../../../../../hooks/useDesignOverview.tsx';
import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle.tsx';
import { CenterLock } from '../../../../Layout/components/nodes/common/CenterLock.tsx';
import { DesignPartLabel } from '../../../../Layout/components/nodes/common/DesignPartLabel.tsx';
import { RotatingHandle } from '../../../../Layout/components/nodes/common/RotatingHandle.tsx';
import { useShapeRotation } from '../../../../Layout/components/nodes/common/useShapeRotation.tsx';
import { ConnectorShape, originalWidth } from '../../../../Layout/components/nodes/ConnectorNode/ConnectorShape.tsx';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { getNodeColor } from '../../../../Layout/utils/common.ts';

export type RelationalConnectorNodeData = {
  designPartId: UUID;
  rotateLock: boolean;
  angle: number;
  noteGroupBuildNotes: NoteGroupBuildNote[];
};

export const defaultRelationalConnectorNodeData: RelationalConnectorNodeData = {
  designPartId: '',
  rotateLock: false,
  angle: 0,
  noteGroupBuildNotes: [],
};

export type RelationalConnectorNodeType = Node<RelationalConnectorNodeData>;

const distanceFromCenter = originalWidth / 2;

/**
 * Connector node component.
 * @param props
 * @constructor
 */
export const RelationalConnectorNode = (props: NodeProps<RelationalConnectorNodeType>) => {
  const { id, data = defaultRelationalConnectorNodeData, selected } = props;
  const { designPartId, rotateLock, angle } = data;
  const designPart = useDesignParts().getDesignPartById(designPartId);
  const { measurementMode } = useDesignOverview();

  // ReactFlow hook to update the node internals, because the handle is rotated and ReactFlow needs to know about it
  const updateNodeInternals = useUpdateNodeInternals();

  // Calculate the angle to rotate the shape
  const calculatedAngle = useShapeRotation({ nodeId: id });

  // Use the calculated angle if the rotate lock is not enabled
  const resolvedAngle = rotateLock ? angle : calculatedAngle;

  useEffect(() => {
    updateNodeInternals(id);
  }, [resolvedAngle, id, updateNodeInternals]);

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative">
      <DesignPartLabel designPart={designPart} angle={resolvedAngle} />
      <ConnectorShape color={getNodeColor(selected)} angle={resolvedAngle} />
      <RotatingHandle
        id={HandleTypes.Segment}
        angle={resolvedAngle}
        offsetPosition={{ x: -distanceFromCenter, y: 0 }}
      />
      <RotatingHandle
        id={HandleTypes.Measurement}
        angle={resolvedAngle}
        offsetPosition={{ x: measurementMode === 'Face' ? distanceFromCenter : -distanceFromCenter, y: 0 }}
      />
      <CenterHandle id={HandleTypes.Note} />
      {rotateLock && <CenterLock />}
    </Box>
  );
};
