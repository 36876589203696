import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { Part, PartStatus } from '@web/apps/types';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  isUpdateMode: boolean;
  isEditing: boolean;
  isCreateMode: boolean;
  onClose?: () => void;
  toggleEdit?: () => void;
  onDeletePart?: (partId: string) => Promise<void>;
}

const PartFieldsActionBar = ({ isUpdateMode, isEditing, isCreateMode, onClose, toggleEdit, onDeletePart }: Props) => {
  const { control, getValues, reset } = useFormContext<Part>();
  const { field } = useController({ name: 'status', control });

  const handleClose = () => {
    reset();
    onClose?.();
  };

  const handleDiscard = () => {
    reset();
    toggleEdit?.();
  };

  const handleDelete = async () => {
    const part = getValues();
    if (part) {
      await onDeletePart?.(part.id);
    }
    handleClose();
  };

  return (
    <Flex width="full" justifyContent="space-between">
      <Box>
        {isUpdateMode && (
          <Button colorScheme="red" onClick={handleDelete}>
            Delete Part
          </Button>
        )}
      </Box>
      <HStack>
        {isEditing && (
          <>
            {isUpdateMode && (
              <Button colorScheme="yellow" onClick={handleDiscard}>
                Discard Changes
              </Button>
            )}
            {field.value !== PartStatus.RELEASED && (
              <Button
                type="submit"
                colorScheme="green"
                variant="outline"
                onClick={() => field.onChange(PartStatus.DRAFT)}
              >
                {field.value === PartStatus.DRAFT ? 'Save' : 'Save as Draft'}
              </Button>
            )}
            <Button type="submit" colorScheme="green" onClick={() => field.onChange(PartStatus.RELEASED)}>
              {field.value === PartStatus.RELEASED ? 'Save' : 'Save and Release'}
            </Button>
          </>
        )}
        {!isCreateMode && !isEditing && (
          <Button colorScheme="blue" onClick={toggleEdit} isDisabled={isEditing}>
            Edit
          </Button>
        )}
      </HStack>
    </Flex>
  );
};

export default PartFieldsActionBar;
