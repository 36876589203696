import { Actor, Document, DocumentAttributes, Manufacturer, Timestamps } from '@web/apps/types';

/**
 * A Tool can be any of the following types
 */
export type Tool = TorqueAdapter | CrimpTool | ToolBody | Positioner | DieSet | TurretHead | InsertionRemovalTool;

/**
 * Enumeration for tool types, sorted alphabetically
 */
export enum ToolType {
  CRIMP = 'CrimpTool',
  DIESET = 'DieSet',
  INSERTIONREMOVAL = 'InsertionRemovalTool',
  POSITIONER = 'Positioner',
  TOOLBODY = 'ToolBody',
  TORQUEADAPTER = 'TorqueAdapter',
  TURRETHEAD = 'TurretHead',
}

export enum InsertionRemovalToolSubtype {
  INSERTION = 'Insertion',
  REMOVAL = 'Removal',
  INSERTION_AND_REMOVAL = 'Insertion/Removal',
}

interface ToolSetting {
  barrelSize?: string;
  wireGauge?: string;
  colorCode?: string;
  selectorSetting?: string;
}

/**
 * BaseTool contains the shared fields for all tool types.
 */
export interface BaseTool extends Timestamps {
  id: string;
  type: ToolType;
  partNumber: string;
  notes?: string;
  creator?: Actor;
  updater?: Actor;
  manufacturer?: Manufacturer;
  documents?: Document[];
}

/**
 * A Crimp has these additional fields
 */
export interface CrimpTool extends BaseTool {}

/**
 * A DieSet has these additional fields
 */
export interface DieSet extends BaseTool {}

/**
 * An InsertionRemoval has these additional fields
 */
export interface InsertionRemovalTool extends BaseTool {
  subtype?: InsertionRemovalToolSubtype;
}

/**
 * A Positioner has these additional fields
 */
export interface Positioner extends BaseTool {
  settings?: ToolSetting[];
  name?: string;
}

/**
 * A ToolBody has these additional fields
 */
export interface ToolBody extends BaseTool {}

/**
 * A TorqueAdapter has these additional fields
 */
export interface TorqueAdapter extends BaseTool {}

/**
 * A TurretHead has these additional fields
 */
export interface TurretHead extends BaseTool {
  settings?: ToolSetting[];
  name?: string;
}

/**
 * Tool payload interface
 */
export interface ToolPayload {
  id: string;
  type: ToolType;
  part_number: string;
  manufacturer_id: string;
  notes?: string;
  subtype?: InsertionRemovalToolSubtype;
  settings?: ToolSetting[];
  documents_attributes?: DocumentAttributes[];
  name?: string;
}
