import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import theme from '../../themes.ts';
import ErrorPage from '../components/ErrorPage.tsx';
import { DesignProvider } from '../hooks/useDesign.tsx';
import { DesignIdParamsProvider } from '../hooks/useDesignId.tsx';
import { PartSearchModalProvider } from '../hooks/usePartSearchModal.tsx';
import { WindowManagerProvider } from '../hooks/useWindowManager.tsx';

/**
 * Layout for the design feature. It's wrapped by the WindowManagerProvider to manage areas of the Design that can be
 * opened in a new window.
 * @constructor
 */
const DesignLayout = () => {
  // Hack to set the color mode to light by default, we're doing this because Chakra's theme support isn't that great.
  // Chakra doesn't support setting the color mode in the theme object, so we have to set it in localStorage.
  localStorage.setItem('chakra-ui-color-mode', 'light');

  // Check if the current route is the index route. The index route is a special route that doesn't need to be wrapped
  // by the DesignProvider.
  const location = useLocation();
  const isIndex = location.pathname === RouteNames.DESIGNS.INDEX;

  // Prevent text selection on double click
  useEffect(() => {
    const handleDoubleClick = (event: MouseEvent) => {
      event.preventDefault();

      const selection = document.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    };

    document.addEventListener('dblclick', handleDoubleClick);

    return () => {
      document.removeEventListener('dblclick', handleDoubleClick);
    };
  }, []);

  const showError: FallbackRender = ({ error }) => {
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    return <ErrorPage errorMessage={errorMessage} />;
  };

  return (
    <ChakraProvider theme={theme}>
      <WindowManagerProvider>
        <Sentry.ErrorBoundary fallback={showError}>
          {isIndex ? (
            <Outlet />
          ) : (
            <DesignIdParamsProvider>
              <DesignProvider>
                <PartSearchModalProvider>
                  <Outlet />
                </PartSearchModalProvider>
              </DesignProvider>
            </DesignIdParamsProvider>
          )}
        </Sentry.ErrorBoundary>
      </WindowManagerProvider>
    </ChakraProvider>
  );
};

export default DesignLayout;
