import { ArrowRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import { InsertArrangement, PositionLabel } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ModalForm from '../../../../components/form/ModalForm.tsx';
import TextInput from '../../../../components/form/TextInput.tsx';
import { useCreateInsertArrangement, useUpdateInsertArrangement } from '../../api/queries.ts';
import PartSpecReferences from '../../components/PartSpecReferences.tsx';
import CavityGenerator from './CavityGenerator.ts';

export enum Mode {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
}

interface Props {
  mode: Mode;
  isOpen: boolean;
  onClose: () => void;
  afterSubmit?: (insertArrangement: InsertArrangement) => void;
  onDelete?: (insertArrangementId: string) => Promise<void>;
}

const InsertArrangementModal = ({ mode, isOpen, onClose, afterSubmit, onDelete }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { control, getValues, register, reset } = useFormContext<InsertArrangement>();
  const {
    fields: cavities,
    replace: replaceCavities,
    remove: removeCavities,
  } = useFieldArray({ control, name: 'cavities' });

  const createInsertArrangement = useCreateInsertArrangement();
  const updateInsertArrangement = useUpdateInsertArrangement();

  const isCreateMode = mode === Mode.CREATE;
  const isUpdateMode = mode === Mode.UPDATE;
  const isEditing = mode !== Mode.READ;

  const toggleEdit = () => {
    const mode = searchParams.get('mode') as Mode | null;
    if (!mode || mode === Mode.READ) {
      navigate(`?mode=${Mode.UPDATE}`);
    } else if (mode === Mode.UPDATE) {
      navigate(`?mode=${Mode.READ}`);
    }
  };

  const handleSubmit = async (data: InsertArrangement) => {
    const mutation = isCreateMode ? createInsertArrangement : updateInsertArrangement;
    const res = await mutation.mutateAsync({ ...data });
    if (res) {
      afterSubmit?.(res);
      return res;
    }
  };

  const handleDelete = async () => {
    const insertArrangement = getValues();
    if (insertArrangement) {
      await onDelete?.(insertArrangement.id);
    }
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleDiscard = () => {
    reset();
    toggleEdit();
  };

  const generateCavities = (label: PositionLabel) => {
    const newCavities = new CavityGenerator(cavities.length, label).generate();
    replaceCavities(newCavities);
  };

  const handleNumPositionsChange = (value: string) => {
    removeCavities();

    const num = parseInt(value);
    if (isNaN(num)) return;

    const newCavities = [];
    for (let i = 0; i < num; i++) {
      newCavities.push({ name: '' });
    }
    replaceCavities(newCavities);
  };

  const Header = () => (
    <Flex justifyContent="space-between">
      <Flex alignItems="center">
        <Text as="span" color="gray.500">
          Insert Arrangement
        </Text>
        <ArrowRightIcon mx={3} boxSize={4} color="gray.500" />
        {getValues('name') && (
          <>
            <Text as="span" color="black">
              {getValues('name')}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );

  const Footer = () => (
    <Flex width="full" justifyContent="space-between">
      <Box>
        {isUpdateMode && (
          <Button colorScheme="red" onClick={handleDelete}>
            Delete Insert Arrangement
          </Button>
        )}
      </Box>
      <HStack>
        {isEditing && (
          <>
            {isUpdateMode && (
              <Button colorScheme="yellow" onClick={handleDiscard}>
                Discard Changes
              </Button>
            )}
            <Button type="submit" colorScheme="green">
              Save
            </Button>
          </>
        )}
        {!isCreateMode && !isEditing && (
          <Button colorScheme="blue" onClick={toggleEdit} isDisabled={isEditing}>
            Edit
          </Button>
        )}
      </HStack>
    </Flex>
  );

  return (
    <ModalForm
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      header={<Header />}
      footer={<Footer />}
      closeButton={true}
      successToastMsg="Insert Arrangement saved successfully."
      minWidth="40%"
    >
      <Stack spacing={10}>
        <HStack spacing={10}>
          <Box flex="1">
            <EditableFormText label="Name" editing={isEditing} value={getValues('name')}>
              <TextInput
                label="Name"
                formControlProps={{ isDisabled: !isEditing }}
                {...register('name', { required: true })}
              />
            </EditableFormText>
          </Box>
          <Box flex="1">
            <EditableFormText
              label="Positions"
              editing={isEditing}
              value={cavities.length ? cavities.length.toString() : ''}
            >
              <TextInput
                label="Positions"
                value={cavities.length ? cavities.length.toString() : ''}
                formControlProps={{ isDisabled: !isEditing }}
                onChange={(e) => handleNumPositionsChange(e.target.value)}
              />
            </EditableFormText>
          </Box>
        </HStack>
        <Center>
          {isEditing ? (
            <HStack width="full" justifyContent="space-around">
              <Button isDisabled={!isEditing} onClick={() => generateCavities(PositionLabel.NUMERIC)}>
                Fill Numeric
              </Button>
              <Tooltip label="Skips I, O, Q">
                <Button isDisabled={!isEditing} onClick={() => generateCavities(PositionLabel.ALPHA_UPPER)}>
                  Fill Alpha Upper
                </Button>
              </Tooltip>
              <Tooltip label="Skips i, j, l, o">
                <Button isDisabled={!isEditing} onClick={() => generateCavities(PositionLabel.ALPHA_LOWER)}>
                  Fill Alpha Lower
                </Button>
              </Tooltip>
            </HStack>
          ) : (
            <></>
          )}
        </Center>
        <PartSpecReferences editing={isEditing} />
        <Stack>
          {cavities.map((cavity, index) => (
            <HStack key={cavity.id}>
              <Box flex={1}>
                <EditableFormText
                  label={index === 0 ? 'Row' : null}
                  editing={isEditing}
                  value={(index + 1)?.toString()}
                >
                  <TextInput isDisabled label={index === 0 ? 'Row' : undefined} value={index + 1} />
                </EditableFormText>
              </Box>
              <Box flex={1}>
                <EditableFormText
                  label={index === 0 ? 'Name' : null}
                  editing={isEditing}
                  value={getValues(`cavities.${index}.name`)}
                >
                  <TextInput
                    label={index === 0 ? 'Name' : undefined}
                    onFocus={(e) => e.target.select()}
                    formControlProps={{ isDisabled: !isEditing }}
                    {...register(`cavities.${index}.name` as const, { required: true })}
                  />
                </EditableFormText>
              </Box>
            </HStack>
          ))}
        </Stack>
      </Stack>
    </ModalForm>
  );
};

export default InsertArrangementModal;
