import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';

import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { RelationalLayoutPointNodeType } from '../../components/nodes/LayoutPointNode/RelationalLayoutPointNode.tsx';
import { NodeType } from '../../relational_types.ts';
import { useNoteGroupBuildNotes } from '../useNoteGroupBuildNotes.tsx';
import { useBuildNoteActions } from './useBuildNoteActions.tsx';
import { useDeleteControlPointAction } from './useDeleteControlPointAction.tsx';

/**
 * Hook for layout point actions.
 */
export const useRelationalLayoutPointActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as RelationalLayoutPointNodeType;

  const deleteLayoutNode = useDeleteControlPointAction(closeMenu, node?.id);

  const noteGroupBuildNotes = useNoteGroupBuildNotes(node?.id);
  const { buildNoteActions } = useBuildNoteActions({
    closeMenu,
    noteGroupBuildNotes,
    targetId: node?.id,
    targetType: 'LayoutNode',
  });

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={deleteLayoutNode}>Remove</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {buildNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.LayoutPoint,
  } as MenuActions;
};
