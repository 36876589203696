import { UUID } from '@web/apps/types';
import { isValidUUID } from '@web/common/lib/string-utils/validation.ts';
import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

/**
 * Hook to get the Design ID from the URL params. Validates, and ensures it's a UUID type.
 * @returns {UUID}
 */

const DesignIdContext = createContext<{ designId: UUID | undefined }>({ designId: undefined });

export const DesignIdProvider = ({ children, designId }: { children: ReactNode; designId: UUID }) => (
  <DesignIdContext.Provider value={{ designId }}>{children}</DesignIdContext.Provider>
);

export const DesignIdParamsProvider = ({ children }: { children: ReactNode }) => {
  const { designId } = useParams<{ designId: string }>();
  return <DesignIdContext.Provider value={{ designId }}>{children}</DesignIdContext.Provider>;
};

export const useDesignId = (): UUID => {
  const { designId } = useContext(DesignIdContext);

  if (!designId) {
    throw new Error('Design ID is missing');
  }

  if (!isValidUUID(designId)) {
    throw new Error('Invalid Design ID');
  }

  return designId as UUID;
};
