import { Box } from '@chakra-ui/react';
import { defaultViewport, emptyEdges, emptyNodes, NoteType } from '@web/apps/types';
import {
  ConnectionMode,
  getViewportForBounds,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { toPng } from 'html-to-image';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { defaultLayoutConfig } from '../features/Layout/config';
import { edgeTypes, nodeTypes } from '../features/Layout/types';
import { getUpdatedGraph } from '../features/Layout/utils/updateGraph';
import { useConnections } from '../hooks/useConnections';
import { useDesignBuildNotes } from '../hooks/useDesignBuildNotes';
import { useDesignParts } from '../hooks/useDesignParts';
import { useLayoutData } from '../hooks/useLayoutData';

export type ReactFlowPortalRef = {
  exportImage: () => Promise<string>;
};

const imageWidth = 1632;
const imageHeight = 1056;

export const MiniReactFlow = forwardRef((_, ref) => {
  const [nodes, setNodes] = useNodesState(emptyNodes);
  const [edges, setEdges] = useEdgesState(emptyEdges);
  const [isRendering, setIsRendering] = useState(false);

  const { getNodesBounds, setViewport, getNodes, fitView } = useReactFlow();
  const { layoutData } = useLayoutData();
  const { connections } = useConnections();
  const { designParts } = useDesignParts();
  const { data: flagNotes } = useDesignBuildNotes(NoteType.FLAG);

  useImperativeHandle(ref, () => ({
    async exportImage() {
      if (!flagNotes) return;

      setIsRendering(true);

      const { nodes = [], edges = [], viewport = {} } = layoutData;
      await setViewport({ ...defaultViewport, ...viewport });
      let updatedGraph = getUpdatedGraph({ nodes, edges, connections, designParts, flagNotes });
      setNodes(updatedGraph.nodes.map((node) => ({ ...node, selected: false })));
      setEdges(updatedGraph.edges.map((node) => ({ ...node, selected: false })));

      // wait for the graph to update
      await new Promise((resolve) => setTimeout(resolve, 100));

      await fitView();
      const nodesBounds = getNodesBounds(getNodes());
      const nodeViewport = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2, 0.1);
      const dataUrl = await toPng(document.querySelector('.react-flow__viewport') as HTMLElement, {
        backgroundColor: 'white',
        width: imageWidth,
        height: imageHeight,
        style: {
          transform: `translate(${nodeViewport.x}px, ${nodeViewport.y}px) scale(${nodeViewport.zoom})`,
        },
      });

      setIsRendering(false);
      return dataUrl.split(',')[1];
    },
  }));

  if (!isRendering) return null;
  return (
    <Box w="0px" h="0px">
      <ReactFlow
        fitView
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        connectionMode={ConnectionMode.Loose}
        defaultViewport={defaultLayoutConfig.defaultViewport}
        minZoom={defaultLayoutConfig.minZoom}
        maxZoom={defaultLayoutConfig.maxZoom}
        nodeOrigin={defaultLayoutConfig.nodeOrigin}
        snapToGrid={defaultLayoutConfig.snapToGrid}
        snapGrid={defaultLayoutConfig.snapGrid}
        style={defaultLayoutConfig.style}
      />
    </Box>
  );
});

MiniReactFlow.displayName = 'MiniReactFlow';
