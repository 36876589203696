import { Cavity, PositionLabel } from '@web/apps/types';

/**
 * CavityGenerator class generates cavities based on the number of positions and position label.
 */
class CavityGenerator {
  // References:
  // - https://d38999.federalconnectors.com/
  // - https://electronics.stackexchange.com/a/381420

  static readonly SKIPPED_CHARS = ['i', 'j', 'l', 'o', 'I', 'O', 'Q'];

  numberOfPositions: number;
  positionLabel: PositionLabel;

  constructor(numberOfPositions: number, positionLabel: PositionLabel) {
    this.numberOfPositions = numberOfPositions;
    this.positionLabel = positionLabel;
  }

  generate(): Cavity[] {
    if (this.positionLabel === PositionLabel.NUMERIC) {
      return this.generateNumericCavities();
    } else if (this.positionLabel === PositionLabel.ALPHA_UPPER) {
      return this.generateAlphaUpperCavities();
    } else if (this.positionLabel === PositionLabel.ALPHA_LOWER) {
      return this.generateAlphaLowerCavities();
    }

    return [];
  }

  generateAlphaUpperCavities(): Cavity[] {
    return this.generateAlphaCavities('A');
  }

  generateAlphaLowerCavities(): Cavity[] {
    return this.generateAlphaCavities('a');
  }

  generateNumericCavities(): Cavity[] {
    const cavities: { name: string }[] = [];

    for (let i = 0; i < this.numberOfPositions; i++) {
      cavities.push({ name: (i + 1).toString() });
    }

    return cavities;
  }

  private generateAlphaCavities(currentLabel: string): Cavity[] {
    const cavities = [{ name: currentLabel }];

    for (let i = 1; i < this.numberOfPositions; i++) {
      currentLabel = this.incrementCavityLabel(currentLabel);
      cavities.push({ name: currentLabel });
    }

    return cavities;
  }

  private incrementCavityLabel(currentLabel: string): string {
    const firstChar = currentLabel.charAt(0);
    let nextChar = String.fromCharCode(firstChar.charCodeAt(0) + 1);
    if (CavityGenerator.SKIPPED_CHARS.includes(nextChar)) {
      nextChar = this.incrementCavityLabel(nextChar);
    }
    let addExtraChar = false;

    if (firstChar === 'z') {
      nextChar = 'a';
      addExtraChar = true;
    } else if (firstChar === 'Z') {
      nextChar = 'A';
      addExtraChar = true;
    }

    const newLabel = Array(currentLabel.length).fill(nextChar);
    if (addExtraChar) {
      newLabel.push(nextChar);
    }

    return newLabel.join('');
  }
}

export default CavityGenerator;
