import { UUID } from '@web/apps/types';
import { BaseEdge, Edge, EdgeProps, getSmoothStepPath, Position, useReactFlow } from '@xyflow/react';

import { defaultLayoutConfig } from '../../../config.ts';
import { calculateMidpoint, getTargetDirection } from '../../../utils/geometry.ts';

export type SegmentNoteEdgeData = {
  segmentId: UUID;
};

export const defaultSegmentNoteEdgeData: SegmentNoteEdgeData = {
  segmentId: '',
};

export type SegmentNoteEdgeType = Edge<SegmentNoteEdgeData>;

// Define the style for the segment
const segmentStyle = {
  stroke: defaultLayoutConfig.edgeColor,
  strokeWidth: 1,
};

/**
 * Note edge component connecting the flag notes to the indicated element.
 * @constructor
 * @param props
 */
export const SegmentNoteEdge = ({
  source,
  sourceX,
  sourceY,
  data: { segmentId } = defaultSegmentNoteEdgeData,
}: EdgeProps<SegmentNoteEdgeType>) => {
  const { getEdge, getNode } = useReactFlow();
  const sourceNode = getNode(source);
  const edge = getEdge(segmentId);

  if (sourceNode && edge) {
    const edgeSourceNode = getNode(edge.source);
    const edgeTargetNode = getNode(edge.target);

    if (edgeSourceNode && edgeTargetNode) {
      const midpoint = calculateMidpoint(edgeSourceNode.position, edgeTargetNode.position);

      const { horizontal, vertical } = getTargetDirection(sourceNode.position, midpoint);

      const isTargetBelow = vertical === Position.Bottom;
      const targetPosition = isTargetBelow ? Position.Top : Position.Bottom;
      const offsetY = isTargetBelow ? -10 : 15;

      // Get the edge path for the note edge
      const [edgePath] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition: horizontal,
        targetX: midpoint.x,
        targetY: midpoint.y + offsetY,
        targetPosition,
      });

      return <BaseEdge path={edgePath} markerEnd={'url(#flag-note-arrow)'} style={segmentStyle} />;
    }
  }

  return null;
};
