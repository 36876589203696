import { useOverwrapPatterns } from '@web/apps/Design/features/Layout/hooks/useOverwrapPatterns.tsx';
import { DesignPartLayoutEdge, GroupedConductor } from '@web/apps/types';
import { BaseEdge, Edge, EdgeProps, getStraightPath } from '@xyflow/react';
import { size } from 'lodash';

import { defaultLayoutConfig } from '../../../../Layout/config.ts';

export type RelationalSegmentEdgeData = {
  designPartLayoutEdges: DesignPartLayoutEdge[];
  groupedConductors: GroupedConductor[];
};

export const defaultRelationalSegmentEdgeData: RelationalSegmentEdgeData = {
  designPartLayoutEdges: [],
  groupedConductors: [],
};

export type RelationalSegmentEdgeType = Edge<RelationalSegmentEdgeData>;

/**
 * Segment edge component representing a cable, wire, or bundle between two nodes.
 * @constructor
 * @param props
 */
export const RelationalSegmentEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  selected,
  data = defaultRelationalSegmentEdgeData,
}: EdgeProps<RelationalSegmentEdgeType>) => {
  const { getPatternsForOutermostOverwrap } = useOverwrapPatterns();
  const { pattern, selectedPattern } = getPatternsForOutermostOverwrap(
    data.designPartLayoutEdges.map((edge) => edge.designPartNumber),
  );

  const hasOverwraps = size(data.designPartLayoutEdges) > 0;

  const color = selected ? defaultLayoutConfig.selectedEdgeColor : defaultLayoutConfig.edgeColor;
  const stroke =
    hasOverwraps && pattern && selectedPattern
      ? selected
        ? `url('#${pattern.patternId}-pattern')`
        : `url('#${selectedPattern.patternId}-pattern')`
      : color;

  const conductorsStrokeWidth = size(data.groupedConductors) > 1 ? 4 : 2;
  const strokeWidth = hasOverwraps ? 8 : conductorsStrokeWidth;

  const segmentStyle = {
    stroke: stroke,
    strokeWidth: strokeWidth,
  };

  const [edgePath] = getStraightPath({
    sourceX,
    sourceY: sourceY + 3, // Manually adjust the source Y to align with the centered node handle
    targetX,
    targetY: targetY + 3, // Manually adjust the source Y to align with the centered node handle
  });

  return (
    <>
      {/* SVG pattern defs for overwraps */}
      {pattern && selectedPattern && (
        <svg>
          <defs>
            {pattern.patternComponent}
            {selectedPattern.patternComponent}
          </defs>
        </svg>
      )}
      {/* Main edge */}
      <BaseEdge path={edgePath} interactionWidth={20} style={segmentStyle} />
    </>
  );
};
