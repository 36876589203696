import { Accordion } from '@chakra-ui/react';
import { GroupedConductor } from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';
import { range } from 'lodash';

import { isSegmentEdge } from '../../../relational_types';
import { GroupedConductorDetails } from './GroupedConductorDetails';
import { OverwrapsDetails } from './OverwrapsDetails';

interface RelationalSegmentViewProps {
  node?: Node;
  edge?: Edge;
}

const getGroupedConductorKey = (groupedConductor: GroupedConductor) =>
  `${groupedConductor.sourceDesignPartId}:${groupedConductor.targetDesignPartId}`;

export const RelationalSegmentView = ({ edge }: RelationalSegmentViewProps) => {
  if (!edge || !isSegmentEdge(edge)) {
    return null;
  }

  const { designPartLayoutEdges, groupedConductors } = edge.data;

  const overwrapPartLayoutEdges = designPartLayoutEdges;
  const hasOverwraps = overwrapPartLayoutEdges.length > 0;

  const allIndices = range((hasOverwraps ? 1 : 0) + groupedConductors.length);

  return (
    <Accordion w="full" defaultIndex={allIndices} allowMultiple>
      {hasOverwraps && <OverwrapsDetails overwrapPartLayoutEdges={overwrapPartLayoutEdges} />}
      {groupedConductors.map((groupedConductor) => (
        <GroupedConductorDetails key={getGroupedConductorKey(groupedConductor)} groupedConductor={groupedConductor} />
      ))}
    </Accordion>
  );
};
