import { filter, keyBy } from 'lodash';
import { useMemo } from 'react';

import { GroupedConductor, UUID } from '../../../../../types';
import { useUnmergeMergeBreakoutPointMutation } from '../../../../api/layout-nodes-api';
import { useDesignId } from '../../../../hooks/useDesignId';
import { useDesignParts } from '../../../../hooks/useDesignParts';
import { useLayoutData } from '../../../../hooks/useLayoutData';

export const useRelationalUnmergeActions = (breakoutPointId: UUID, closeMenu: () => void) => {
  const designId = useDesignId();
  const { getDesignPartById } = useDesignParts();
  const { layoutNodes } = useLayoutData();
  const { mutate: unmergeBreakoutPoint } = useUnmergeMergeBreakoutPointMutation();

  const designPartIdToLayoutNodeMap = useMemo(() => {
    const nodesWithDesignPartId = filter(layoutNodes, 'designPartId');
    return keyBy(nodesWithDesignPartId, 'designPartId');
  }, [layoutNodes]);

  const handleUnmerge = (groupedConductor: GroupedConductor) => {
    const { sourceDesignPartId, targetDesignPartId } = groupedConductor;

    const startDesignPartNodeId = designPartIdToLayoutNodeMap[sourceDesignPartId]?.id;
    const endDesignPartNodeId = designPartIdToLayoutNodeMap[targetDesignPartId]?.id;

    unmergeBreakoutPoint({ designId, breakoutPointId, startDesignPartNodeId, endDesignPartNodeId });
    closeMenu();
  };

  const getGroupedConductorName = (groupedConductor: GroupedConductor) => {
    const { sourceDesignPartId, targetDesignPartId } = groupedConductor;

    const sourceDesignPart = getDesignPartById(sourceDesignPartId);
    const targetDesignPart = getDesignPartById(targetDesignPartId);

    if (sourceDesignPart && targetDesignPart) {
      return `${sourceDesignPart.name} to ${targetDesignPart.name}`;
    } else if (sourceDesignPart) {
      return sourceDesignPart.name;
    } else {
      // Should never reach here, but just in case, it should provide some info
      return `${sourceDesignPartId} to ${targetDesignPartId}`;
    }
  };

  return { handleUnmerge, getGroupedConductorName };
};
