import { Role, User } from '@web/apps/types';

import { IConfiguration } from '../../../types';
import { doRequest, getUrl } from '../common/api';

export async function apiGetUsers(page: string, { query }: { query: string | null }) {
  let endpoint = `/api/v1/users?page=${page}?per=25`;
  if (query) {
    endpoint += `&q=${query}`;
  }
  return doRequest('GET', getUrl(endpoint));
}

export async function apiGetEmployees({ query }: { query?: string | null }): Promise<{ data: User[] } | undefined> {
  let endpoint = `/api/v1/users?per=10&roles[]=${Role.MANUFACTURING_ENGINEER}&roles[]=${Role.ADMIN}&roles[]=${Role.TECHNICIAN}`;
  if (query) {
    endpoint += `&q=${query}`;
  }
  return doRequest('GET', getUrl(endpoint));
}

export async function apiPostUser(user: unknown) {
  return doRequest('POST', getUrl(`/api/v1/users`), JSON.stringify({ user }));
}

export async function apiGetUser(userId: string) {
  return doRequest('GET', getUrl(`/api/v1/users/${userId}`));
}

export async function apiPutUser(userId: string, user: unknown) {
  return doRequest('PUT', getUrl(`/api/v1/users/${userId}`), JSON.stringify({ user }));
}

export async function apiDeleteUser(userId: string) {
  return doRequest('DELETE', getUrl(`/api/v1/users/${userId}`));
}

export async function apiResendInvitation(userId: string) {
  return doRequest('PUT', getUrl(`/api/v1/users/${userId}/resend_invitation`));
}

export async function apiGetTenants(page: string, { query }: { query?: string | null }) {
  let endpoint = `/api/v1/tenants?page=${page}&per=25`;
  if (query) {
    endpoint += `&q=${query}`;
  }
  return doRequest('GET', getUrl(endpoint));
}

export async function apiPostTenant(tenant: unknown) {
  return doRequest('POST', getUrl(`/api/v1/tenants`), JSON.stringify({ tenant }));
}

export async function apiGetTenant(tenantId: string) {
  return doRequest('GET', getUrl(`/api/v1/tenants/${tenantId}`));
}

export async function apiPutTenant(tenantId: string, tenant: unknown) {
  return doRequest('PUT', getUrl(`/api/v1/tenants/${tenantId}`), JSON.stringify({ tenant }));
}

export async function apiDeleteTenant(tenantId: string) {
  return doRequest('DELETE', getUrl(`/api/v1/tenants/${tenantId}`));
}

export async function apiDeleteDocument(documentId: string) {
  return doRequest('DELETE', getUrl(`/api/v1/documents/${documentId}`));
}

export async function apiGetConfiguration() {
  return (await doRequest('GET', getUrl(`/api/v1/configuration`))) as unknown as Promise<IConfiguration>;
}
