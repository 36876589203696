import { Fields } from '@web/apps/Design/features/WiringList/components/WiringListRow/types.ts';
import { useEffect, useState } from 'react';

export const useSaveOnBlurWiringListRow = (
  isEditing: boolean,
  isDirty: boolean,
  onBlur: () => Promise<void>,
  state: Fields,
) => {
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const saveOnBlur = async () => {
      if (!isEditing && isDirty && !isSaving) {
        setIsSaving(true);
        await onBlur();
        setIsSaving(false);
      }
    };

    void saveOnBlur();
  }, [isEditing, isDirty, onBlur, state, isSaving]);
};
