import { Box } from '@chakra-ui/react';
import { GroupedConductor, NoteGroupBuildNote } from '@web/apps/types';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';

import { BreakoutPointShape } from '../../../../Layout/components/nodes/BreakoutPointNode/BreakoutPointShape';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle';
import { HandleTypes } from '../../../../Layout/types/handles';
import { getNodeColor } from '../../../../Layout/utils/common';
import { isBreakoutPointNode } from '../../../relational_types';

export type RelationalBreakoutPointNodeData = {
  noteGroupBuildNotes: NoteGroupBuildNote[];
  groupedConductors: GroupedConductor[];
};

export const defaultRelationalBreakoutPointNodeData: RelationalBreakoutPointNodeData = {
  noteGroupBuildNotes: [],
  groupedConductors: [],
};

export type RelationalBreakoutPointNodeType = Node<RelationalBreakoutPointNodeData>;

/**
 * Breakout point node component.
 * @param props
 * @constructor
 */
export const RelationalBreakoutPointNode = (props: NodeProps<RelationalBreakoutPointNodeType>) => {
  const { selected } = props;
  const { getIntersectingNodes } = useReactFlow();

  // Check if two breakout points are intersecting
  const intersections = getIntersectingNodes(props, true).filter(isBreakoutPointNode);

  // If they are on top of each other, color them red
  const color = intersections.length > 0 ? 'red' : getNodeColor(selected);

  return (
    <Box position="relative">
      <BreakoutPointShape color={color} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
