import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { HStack, Text } from '@chakra-ui/react';
import { Contact, PartType } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextInput from '@web/components/form/TextInput.tsx';

import CompatibleAccessoryTable from './CompatibleAccessoryTable.tsx';
import { minMaxAwgFormatter } from './MinMaxAwgFields.tsx';

interface Props {
  editing: boolean;
}

const CompatibleContactsTable = ({ editing }: Props) => {
  return (
    <FormControl>
      <HStack>
        <FormLabel id="ContactsLabel">
          <Text>Compatible Contacts</Text>
        </FormLabel>
      </HStack>
      <CompatibleAccessoryTable
        labelId="ContactsLabel"
        partType={PartType.CONTACT}
        editing={editing}
        customColumns={[
          {
            header: 'Gender',
            renderFn: (part) => (
              <EditableFormText label={null} editing={editing}>
                <TextInput value={(part as Contact).gender} formControlProps={{ isDisabled: true }} />
              </EditableFormText>
            ),
          },
          {
            header: 'Gauge',

            renderFn: (part) => (
              <EditableFormText label={null} editing={editing}>
                <TextInput formControlProps={{ isDisabled: true }} value={minMaxAwgFormatter(part as Contact)} />
              </EditableFormText>
            ),
          },
          {
            header: 'Termination',
            renderFn: (part) => (
              <EditableFormText label={null} editing={editing}>
                <TextInput formControlProps={{ isDisabled: true }} value={(part as Contact).termination} />
              </EditableFormText>
            ),
          },
        ]}
      />
    </FormControl>
  );
};

export default CompatibleContactsTable;
