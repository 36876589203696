import { Fields } from '@web/apps/Design/features/WiringList/components/WiringListRow/types.ts';
import { ConnectionPointMap, ResolvedConnectionPoint, UUID } from '@web/apps/types';
import { Option } from '@web/common/select';
import React, { useCallback } from 'react';

const excludedConnectionPointTypes = new Set(['Housing', 'Shield']);

export const useHandleConductorIdChange = (
  setStateWithDirtyFlag: (setStateAction: React.SetStateAction<Fields>) => void,
  sourceConnectionPoint: ResolvedConnectionPoint,
  connectionPointsById: ConnectionPointMap,
  sourceContactOptions: Option[],
  destinationContactOptions: Option[],
) => {
  return useCallback(
    (updatedConductorId: UUID) =>
      setStateWithDirtyFlag((prevState) => {
        const { destinationId, sourceContactId, destinationContactId } = prevState;
        const updatedState = { ...prevState, conductorId: updatedConductorId };

        if (updatedConductorId) {
          const shouldAddSourceContact = !excludedConnectionPointTypes.has(sourceConnectionPoint.name);

          const destinationConnectionPoint = connectionPointsById[destinationId];
          const shouldAddDestinationContact = !excludedConnectionPointTypes.has(destinationConnectionPoint?.name);

          if (shouldAddSourceContact && !sourceContactId && sourceContactOptions.length === 1) {
            updatedState.sourceContactId = sourceContactOptions[0].value;
          }

          if (shouldAddDestinationContact && !destinationContactId && destinationContactOptions.length == 1) {
            updatedState.destinationContactId = destinationContactOptions[0].value;
          }
        }

        return updatedState;
      }),
    [
      setStateWithDirtyFlag,
      sourceConnectionPoint.name,
      connectionPointsById,
      sourceContactOptions,
      destinationContactOptions,
    ],
  );
};
