import { Box, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { doesUserHaveRole, Role } from '@web/apps/types';
import { useAdminTenants } from '@web/queries/admin.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import { SingleValue } from 'chakra-react-select';
import { E164Number } from 'libphonenumber-js/core';
import { camelCase } from 'lodash';
import { useState } from 'react';
import { Control, Controller, FieldValue, UseFormRegister } from 'react-hook-form';

import SubmitButton from '../../../components/buttons/SubmitButton';
import CheckboxInput from '../../../components/form/CheckboxInput';
import EmailInput from '../../../components/form/EmailInput';
import ErrorText from '../../../components/form/ErrorText.tsx';
import PhoneInput from '../../../components/form/PhoneInput';
import TextInput from '../../../components/form/TextInput';
import TypeAheadSelectInput from '../../../components/form/TypeAheadSelectInput';
import WarningBox from '../../../components/WarningBox';

interface Props {
  isNew?: boolean; // are we inviting a new user or editing an existing one
  onSubmit: () => void;
  register: UseFormRegister<FieldValue<any>>;
  selectedTenant: { label: string; value: string };
  control: Control;
  isSubmitting: boolean;
  isValid: boolean;
  phone: E164Number;
  setPhone: (value: E164Number) => void;
}

const UserForm = ({
  isNew,
  selectedTenant,
  onSubmit,
  register,
  control,
  isSubmitting,
  isValid,
  phone,
  setPhone,
}: Props) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [value, setValue] = useState<SingleValue<{ label: string; value: string }>>(selectedTenant ?? null);

  const { data: currentUser } = useCurrentUser();
  const { isLoading, data: tenants } = useAdminTenants('1', query);
  const data = tenants ?? { data: [], meta: { totalCount: 0 } };

  const options = isLoading
    ? [{ value: 'loading', label: 'Loading…' }]
    : data.data.map((tenant: { id: string; name: string }) => ({
        value: tenant.id,
        label: tenant.name,
      }));

  const handleBlur = () => {
    setError(value?.label.trim().length ? '' : 'Tenant required');
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <TextInput label="Name *" {...register('name', { required: true })} />
        {!isNew && <TextInput label="Auth0 ID" hint="E.g. auth0|000000000000000000000000" {...register('auth0Id')} />}
        <EmailInput label="Email *" registerFieldName="email" register={register} />
        <PhoneInput label="Phone" name="phone" value={phone} onChange={setPhone} />
        <FormControl>
          <FormLabel>{'Tenant * '}</FormLabel>
          <Controller
            name={'tenantId'}
            control={control}
            rules={{ required: true, onBlur: handleBlur }}
            render={({ field: { onBlur, onChange, ref } }) => {
              const handleChange = (selectedOption?: SingleValue<{ label: string; value: string }>) => {
                onChange(selectedOption?.value ?? null);
                setError('');
                setValue(selectedOption ?? { label: '', value: '' });
              };
              return (
                <TypeAheadSelectInput
                  options={options}
                  onChange={handleChange}
                  querySetter={setQuery}
                  value={value}
                  noOptionsMessage={() => 'No matching tenants'}
                  onBlur={onBlur}
                  placeholder="Select a tenant…"
                  ref={ref}
                />
              );
            }}
          />
          <ErrorText>{error}</ErrorText>
        </FormControl>
        {!isNew && doesUserHaveRole(currentUser, Role.ADMIN) && (
          <WarningBox text="The following settings are DANGEROUS since they grant access to ALL customer data! DO NOT set these unless (1) the user is a Senra employee and (2) you know what you are doing.">
            <CheckboxInput
              label="Admin User (user can view all orders, manage users & tenants)"
              {...register(Role.ADMIN)}
            />
            <CheckboxInput
              label="Manufacturing Engineer (user can be assigned to orders as an owner)"
              {...register(camelCase(Role.MANUFACTURING_ENGINEER) as 'manufacturingEngineer')}
            />
            <CheckboxInput label="Technician (harness technician for the MES system)" {...register(Role.TECHNICIAN)} />
            <CheckboxInput
              label="Authorized Operator (user can verify operations in MES)"
              {...register(camelCase(Role.AUTHORIZED_OPERATOR) as 'authorizedOperator')}
            />
          </WarningBox>
        )}
        <Box>
          <SubmitButton loading={isSubmitting} disabled={!isValid} />
        </Box>
      </Stack>
    </form>
  );
};

export default UserForm;
