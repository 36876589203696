import { HTMLChakraProps, Td } from '@chakra-ui/react';
import { KeyboardEvent, ReactNode, useEffect, useRef } from 'react';

import { useDesign } from '../../../../hooks/useDesign.tsx';
import { useWiringList } from '../../hooks/useWiringList.tsx';
import { useWiringListCellKeyboardHandler } from '../../hooks/useWiringListCellKeyboardHandler.ts';
import { FieldKey, RowId } from '../WiringListRow/types.ts';

interface Props extends Omit<HTMLChakraProps<'td'>, 'children'> {
  rowId: RowId;
  fieldKey: FieldKey;
  onCellClick: () => void;
  onEscape: () => void;
  children: (props: { isInEditMode: boolean }) => ReactNode;
}

/**
 * A table data element with custom styling and behavior for the wiring list table.
 * @param rowId
 * @param fieldKey
 * @param onEdit
 * @param children
 * @param rest
 * @constructor
 */
export const WiringListCell = ({ rowId, fieldKey, onCellClick, onEscape, children, ...rest }: Props) => {
  const { isViewOnly } = useDesign();
  const { isKeyboardNav, isEditing, isFocusedRow, isFocusedField, isSelectMenuOpen, setFocusedRow, setFocusedField } =
    useWiringList();
  const keyboardHandler = useWiringListCellKeyboardHandler(rowId);
  const cellRef = useRef<HTMLTableCellElement>(null);

  const hasFocus = isFocusedRow(rowId) && isFocusedField(fieldKey);
  const isInEditMode = isEditing(rowId);
  const cursor = !isViewOnly && !isEditing(rowId) && hasFocus ? 'text' : 'auto';

  useEffect(() => {
    if (!cellRef.current) return;

    if (hasFocus) {
      if (isInEditMode) {
        cellRef.current.querySelector<HTMLElement>('input, select, textarea, button')?.focus();
      } else {
        cellRef.current.focus();
      }
    } else {
      cellRef.current.blur();
    }
  }, [hasFocus, isInEditMode]);

  const handleClick = () => {
    if (isViewOnly) return;
    if (onCellClick) {
      onCellClick();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTableCellElement>) => {
    if (isViewOnly) return;

    if (event.key === 'Escape' && !isSelectMenuOpen) {
      onEscape();
      return;
    }

    keyboardHandler(event);
  };

  const handleMouseEnter = () => {
    if (isViewOnly || isSelectMenuOpen || isKeyboardNav) return;
    if (cellRef.current) cellRef.current.focus();
  };

  const handleFocus = () => {
    if (isViewOnly) return;
    if (!isFocusedRow(rowId)) setFocusedRow(rowId);
    if (!isFocusedField(fieldKey)) setFocusedField(fieldKey);
  };

  return (
    <Td
      ref={cellRef}
      tabIndex={0}
      cursor={cursor}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      _focus={{
        outline: '1px solid',
        outlineColor: 'blue.500',
      }}
      _focusWithin={{
        outline: '1px solid',
        outlineColor: 'blue.500',
      }}
      aria-label="Interactive cell"
      {...rest}
    >
      {typeof children === 'function' ? children({ isInEditMode }) : children}
    </Td>
  );
};
