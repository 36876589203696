import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { createEmptyResponse, doRequest } from '@web/apps/Design/api/request-helpers.ts';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { Design, LayoutData, PageData, UUID } from '@web/apps/types';
import { getUrl } from '@web/common/api.ts';

// Utility Constants and Functions
interface DesignsResponse {
  data: Design[];
  meta: PageData;
}

const emptyDesignsResponse: DesignsResponse = createEmptyResponse<Design>();

const fetchDesigns = async (query?: string | null) => {
  const endpoint = getUrl(`/api/v1/designs`);
  const params = new URLSearchParams();
  if (query) params.set('q', query);

  return (await doRequest<DesignsResponse>('GET', `${endpoint}?${params.toString()}`)) || emptyDesignsResponse;
};

const fetchDesign = async (designId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}`);
  return await doRequest<Design>('GET', url);
};

const createDesign = async (data: CreateDesignData) => {
  const url = getUrl(`/api/v1/designs`);
  return await doRequest<Design>('POST', url, JSON.stringify({ design: data }));
};

const updateDesign = async (designId: UUID, data: Partial<UpdateDesignData>) => {
  const url = getUrl(`/api/v1/designs/${designId}`);
  return await doRequest<Design>('PATCH', url, JSON.stringify({ design: data }));
};

const deleteDesign = async (designId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}`);
  await doRequest('DELETE', url);
};

const cloneDesign = async (designId: UUID, data: CreateDesignData) => {
  const url = getUrl(`/api/v1/designs/${designId}/clone`);
  return await doRequest<Design>('POST', url, JSON.stringify({ design: data }));
};

// React Query Hooks: Queries
export const useDesignsQuery = <TData = DesignsResponse>(
  query?: string,
  select?: (data: DesignsResponse) => TData,
): UseQueryResult<TData> => {
  return useQuery({
    queryKey: query ? designKeys.list([query]) : designKeys.lists(),
    queryFn: () => fetchDesigns(query),
    select,
  });
};

export const useDesignQuery = <TData = Design>(
  designId: UUID,
  select?: (data: Design | undefined) => TData,
): UseQueryResult<TData> => {
  return useQuery({
    queryKey: designKeys.detail(designId),
    queryFn: () => fetchDesign(designId),
    select,
  });
};

// React Query Hooks: Mutations
interface CreateDesignData {
  name: string;
  partNumber: string;
  partRevision: string;
  description: string;
  lengthUnit: string;
  measurementMode: 'Face' | 'Rear';
  tenantId: UUID;
}

interface CreateDesignParams {
  data: CreateDesignData;
}

export const useCreateDesignMutation = (): UseMutationResult<Design | undefined, Error, CreateDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-design'],
    mutationFn: async ({ data }: CreateDesignParams) => createDesign(data),
    onSuccess: async () => {
      await invalidateQueries(designKeys.lists());
    },
    onError: (createDesignError) => showErrorToast('Error Creating Design', createDesignError.message),
  });
};

interface UpdateDesignData extends CreateDesignData {
  layoutData: LayoutData;
  locked: boolean;
}

interface UpdateDesignParams {
  designId: UUID;
  data: Partial<UpdateDesignData>;
}

export const useUpdateDesignMutation = (): UseMutationResult<Design | undefined, Error, UpdateDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-design'],
    mutationFn: async ({ designId, data }: UpdateDesignParams) => updateDesign(designId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.lists());
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (updateDesignError) => showErrorToast('Error Updating Design', updateDesignError.message),
  });
};

interface DeleteDesignParams {
  designId: UUID;
}

export const useDeleteDesignMutation = (): UseMutationResult<void, Error, DeleteDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-design'],
    mutationFn: async ({ designId }: DeleteDesignParams) => deleteDesign(designId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.lists());
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (deleteDesignError) => showErrorToast('Error Deleting Design', deleteDesignError.message),
  });
};

interface CloneDesignParams {
  designId: UUID;
  data: CreateDesignData;
}

export const useCloneDesignMutation = (): UseMutationResult<Design | undefined, Error, CloneDesignParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['clone-design'],
    mutationFn: async ({ designId, data }: CloneDesignParams) => cloneDesign(designId, data),
    onSuccess: async () => {
      await invalidateQueries(designKeys.lists());
    },
    onError: (cloneDesignError) => showErrorToast('Error Cloning Design', cloneDesignError.message),
  });
};
