import { Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Part, PartType } from '@web/apps/types';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import PartSearchModal from '../features/PartSearch/PartSearchModalContent';

// Context type for design
export type PartSearchModalContextType = {
  isOpen: boolean;
  openModal: (props: PartSearchModalProps) => void;
  closeModal: () => void;
  modalProps: PartSearchModalProps;
};

// Create a context for design
const PartSearchModalContext = createContext<PartSearchModalContextType | undefined>(undefined);

// Hook to use the design context
export const usePartSearchModal = () => {
  const context = useContext(PartSearchModalContext);
  if (context === undefined) {
    throw new Error('usePartSearchModal must be used within a PartSearchModalProvider');
  }
  return context;
};

// Provider to manage part search modal
interface PartSearchModalProviderProps {
  children: ReactNode;
}

export interface PartSearchModalProps {
  initialPartTypeFilters: PartType[];
  filtersDisabled?: boolean;
  renderSearchResultAction?: (part: Part) => ReactNode;
}

const defaultPartSearchModalProps: PartSearchModalProps = {
  initialPartTypeFilters: [],
  filtersDisabled: false,
  renderSearchResultAction: undefined,
};

export const PartSearchModalProvider = ({ children }: PartSearchModalProviderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalProps, setModalProps] = useState<PartSearchModalProps>(defaultPartSearchModalProps);

  const openModal = useCallback(
    (props: PartSearchModalProps) => {
      setModalProps(props);
      onOpen();
    },
    [onOpen],
  );

  return (
    <PartSearchModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal: onClose,
        modalProps,
      }}
    >
      {children}
      <Modal isOpen={isOpen} onClose={onClose} size="custom">
        <ModalOverlay backgroundColor="transparent" />
        <PartSearchModal {...modalProps} />
      </Modal>
    </PartSearchModalContext.Provider>
  );
};
