import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface BaseDetailsAccordionItemProps {
  header: ReactNode;
  children: ReactNode;
  borderColor?: string;
  bgColor?: string;
}

export const BaseDetailsAccordionItem = ({
  header,
  children,
  borderColor = 'gray.100',
  bgColor = 'white',
}: BaseDetailsAccordionItemProps) => (
  <AccordionItem borderColor={borderColor}>
    <AccordionButton bg={bgColor} _hover={{ bg: bgColor }} p={4}>
      <Box flex={1}>{header}</Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel pb={4}>{children}</AccordionPanel>
  </AccordionItem>
);
