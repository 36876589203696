import {
  Button,
  Center,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';
import {
  useCloneDesignMutation,
  useCreateDesignMutation,
  useDesignsQuery,
  useUpdateDesignMutation,
} from '@web/apps/Design/api/designs-api.ts';
import FilterBox from '@web/components/FilterBox.tsx';
import Loading from '@web/components/Loading.tsx';
import TopbarNav from '@web/components/navigation/TopbarNav.tsx';
import useDebouncedValue from '@web/hooks/useDebouncedValue.ts';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Title from '../../../components/Title.tsx';
import DesignModal, { DesignFormData } from './DesignModal.tsx';
import { DesignRow } from './DesignRow.tsx';

/**
 * Component that displays the list of Designs.
 * @constructor
 */
const Designs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('q') ?? '';
  const debouncedQuery = useDebouncedValue(query, 500);
  const { isLoading, data: designs, error } = useDesignsQuery(debouncedQuery);
  const [selectedDesign, setSelectedDesign] = useState<DesignFormData>();

  // State for the design modal
  const { isOpen: isCreateModalOpen, onOpen: openCreateModal, onClose: closeCreateModal } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();
  const { isOpen: isCloneModalOpen, onOpen: openCloneModal, onClose: closeCloneModal } = useDisclosure();

  // Mutations for creating, updating, cloning, and deleting designs
  const { mutate: createDesign, isPending: createDesignPending } = useCreateDesignMutation();
  const { mutate: updateDesign } = useUpdateDesignMutation();
  const { mutate: cloneDesign } = useCloneDesignMutation();

  const [spacing10, spacing40, spacing80] = useToken('space', ['10', '40', '80']);

  if (error) return;

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('q', value);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);
  };

  const handleCreateDesign = (data: DesignFormData) => {
    createDesign({
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        measurementMode: data.measurementMode,
        tenantId: data.tenant.id,
      },
    });
  };

  const handleUpdateDesign = (data: DesignFormData) => {
    updateDesign({
      designId: selectedDesign?.id || '',
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        measurementMode: data.measurementMode,
        locked: !!data.lockedAt,
      },
    });
  };

  const handleCloneDesign = (data: DesignFormData) => {
    cloneDesign({
      designId: selectedDesign?.id || '',
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        measurementMode: data.measurementMode,
        tenantId: data.tenant.id,
      },
    });
  };

  return (
    <Stack spacing={0}>
      <TopbarNav />
      <Stack padding={8} spacing={8}>
        <Title title={'Designs'} aria-label="page-title">
          <Button onClick={openCreateModal} isLoading={createDesignPending}>
            New Design
          </Button>
        </Title>
        <HStack gap={6}>
          <FilterBox placeholder="Type to filter" value={query} onChange={handleFilterQuery} />
          <Button
            onClick={() => {
              handleFilterQuery('');
            }}
          >
            Clear filters
          </Button>
        </HStack>
        {isLoading ? (
          <Center height="50vh" width="100vw">
            <Loading message="Loading designs…" />
          </Center>
        ) : (
          <TableContainer>
            <Table layout="fixed" variant="senraTable">
              <colgroup>
                <col style={{ width: spacing10 }} />
                <col style={{ width: spacing80 }} />
                <col style={{ minWidth: spacing80, width: 'auto' }} />
                <col style={{ width: spacing40 }} />
                <col style={{ width: spacing40 }} />
                <col style={{ width: spacing40 }} />
              </colgroup>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Part Number</Th>
                  <Th>Description</Th>
                  <Th>Length Unit</Th>
                  <Th>Drawn By</Th>
                  <Th>Customer</Th>
                </Tr>
              </Thead>
              <Tbody>
                {designs?.data.map((design) => (
                  <DesignRow
                    design={design}
                    key={design.id}
                    openCloneModal={openCloneModal}
                    openEditModal={openEditModal}
                    setSelectedDesign={setSelectedDesign}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <DesignModal
        title="Create Design"
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        onSubmit={handleCreateDesign}
        submitButtonText="Create"
      />
      <DesignModal
        title="Update Design"
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onSubmit={handleUpdateDesign}
        submitButtonText="Save"
        initialData={selectedDesign}
      />
      <DesignModal
        title="Clone design"
        isOpen={isCloneModalOpen}
        onClose={closeCloneModal}
        onSubmit={handleCloneDesign}
        submitButtonText="Clone"
        initialData={selectedDesign}
      />
    </Stack>
  );
};

export default Designs;
