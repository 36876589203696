import { Box } from '@chakra-ui/react';
import { NoteGroupBuildNote } from '@web/apps/types';
import { Node, NodeProps } from '@xyflow/react';

import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle.tsx';
import { LayoutPointShape } from '../../../../Layout/components/nodes/LayoutPointNode/LayoutPointShape.tsx';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { getNodeColor } from '../../../../Layout/utils/common.ts';

export type RelationalLayoutPointNodeData = {
  noteGroupBuildNotes: NoteGroupBuildNote[];
};

export const defaultRelationalLayoutPointNodeData: RelationalLayoutPointNodeData = {
  noteGroupBuildNotes: [],
};

export type RelationalLayoutPointNodeType = Node<RelationalLayoutPointNodeData>;

/**
 * Layout point node component.
 * @param props
 * @constructor
 */
export const RelationalLayoutPointNode = (props: NodeProps<RelationalLayoutPointNodeType>) => {
  const { selected } = props;

  return (
    <Box position="relative">
      <LayoutPointShape color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
