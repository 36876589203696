import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { NON_UPDATABLE_FIELDS } from '@web/api/parts-api.ts';
import { partKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { createEmptyResponse } from '@web/apps/Design/api/request-helpers.ts';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { PageData, Part, PartType, UUID } from '@web/apps/types';
import { doRequest, getUrl } from '@web/common/api.ts';

// Utility Constants and Functions
interface PartsResponse {
  data: Part[];
  meta: PageData;
}

const emptyPartsResponse: PartsResponse = createEmptyResponse<Part>();

const fetchPartsByPartNumber = async (
  partNumber: string,
  partTypes?: PartType[],
  designToolSearch: boolean = false,
) => {
  const urlParams = new URLSearchParams();
  urlParams.append('part_number', partNumber);
  if (partTypes) {
    partTypes.forEach((type) => urlParams.append('type[]', type));
  }
  urlParams.append('design_tool_search', designToolSearch.toString());
  const url = getUrl(`/api/v1/parts?${urlParams.toString()}`);
  return (await doRequest<PartsResponse>('GET', url)) || emptyPartsResponse;
};

const createPart = async (data: Part) => {
  const url = getUrl(`/api/v1/parts`);
  return await doRequest<Part>('POST', url, JSON.stringify({ part: data }));
};

const updatePart = async (partId: UUID, data: Part) => {
  const url = getUrl(`/api/v1/parts/${partId}`);
  const updatablePart = Object.fromEntries(Object.entries(data).filter(([key]) => !NON_UPDATABLE_FIELDS.includes(key)));
  return await doRequest<Part>('PUT', url, JSON.stringify({ part: updatablePart }));
};

const deletePart = async (partId: UUID) => {
  const url = getUrl(`/api/v1/parts/${partId}`);
  await doRequest('DELETE', url);
};

// React Query Hooks: Queries
export const usePartsByPartNumberQuery = <TData = PartsResponse>(
  partNumber: string,
  partTypes?: PartType[],
  designToolSearch?: boolean,
  select?: (data: PartsResponse) => TData,
): UseQueryResult<TData> => {
  const filters: string[] = [];
  if (partTypes) {
    partTypes.forEach((type) => filters.push(type));
  }
  filters.sort().push(partNumber);
  if (designToolSearch) {
    filters.sort().push('design_tool_search');
  }
  return useQuery({
    queryKey: partKeys.list(filters),
    queryFn: () => fetchPartsByPartNumber(partNumber, partTypes, designToolSearch),
    select,
    enabled: partNumber.length >= 0,
  });
};

// React Query Hooks: Mutations
interface CreatePartParams {
  data: Part & { manufacturerIds?: UUID[] };
}

export const useCreatePartMutation = ({ onError }: { onError?: () => void } = {}): UseMutationResult<
  Part | undefined,
  Error,
  CreatePartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-part'],
    mutationFn: async ({ data }: CreatePartParams) => createPart(data),
    onSuccess: async () => {
      await invalidateQueries(partKeys.lists());
    },
    onError: (error) => {
      showErrorToast('Error Creating Part', error.message);
      onError?.();
    },
  });
};

interface UpdatePartParams {
  partId: UUID;
  data: Part;
}

export const useUpdatePartMutation = ({ onError }: { onError?: () => void } = {}): UseMutationResult<
  Part | undefined,
  Error,
  UpdatePartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-part'],
    mutationFn: async ({ partId, data }: UpdatePartParams) => updatePart(partId, data),
    onSuccess: async () => {
      await invalidateQueries(partKeys.lists());
    },
    onError: (error) => {
      showErrorToast('Error Updating Part', error.message);
      onError?.();
    },
  });
};

interface DeletePartParams {
  partId: UUID;
}

export const useDeletePartMutation = (): UseMutationResult<void, Error, DeletePartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-part'],
    mutationFn: async ({ partId }: DeletePartParams) => deletePart(partId),
    onSuccess: async () => {
      await invalidateQueries(partKeys.lists());
    },
    onError: (error) => showErrorToast('Error Deleting Part', error.message),
  });
};
