import { Tooltip } from '@chakra-ui/react';
import { BOMItem } from '@web/apps/types';

import FieldValueList from '../../../../../components/FieldValueList';
import { NumberCircle } from '../../../../BuildNotes/NumberCircle';

interface Props {
  bomItem: BOMItem;
  disableTooltip: boolean;
}

export const LayoutBomNote = ({ bomItem, disableTooltip }: Props) => {
  const { id, type, partNumber, manufacturer } = bomItem;

  const bomItemData = [
    { field: 'Type', value: type },
    { field: 'Part Number', value: partNumber },
    { field: 'Manufacturer', value: manufacturer },
  ].filter((item) => item.value);

  return (
    <Tooltip
      label={<FieldValueList data={bomItemData} fieldProps={{ minW: 28 }} valueProps={{ minW: 40 }} />}
      isDisabled={disableTooltip}
    >
      <NumberCircle number={id} />
    </Tooltip>
  );
};
