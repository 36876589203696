import { Text } from '@chakra-ui/react';
import { Overwrap, Part, PartType } from '@web/apps/types';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';

const OverwrapsDashboard = () => {
  const methods = useForm<Overwrap>({ defaultValues: { type: PartType.OVERWRAP } });
  const columns: CustomColumnDef[] = [
    {
      header: 'Consumable',
      renderFn: (part: Part) => {
        const overwrap = part as Overwrap;
        return <Text>{overwrap.consumable ? 'Yes' : 'No'}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.OVERWRAP} columnDefs={columns} />
    </FormProvider>
  );
};

export default OverwrapsDashboard;
