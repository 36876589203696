import { Box, HStack, MenuItemOption, MenuOptionGroup, Spinner, Text } from '@chakra-ui/react';
import {
  useCreateNoteGroupBuildNoteMutation,
  useDeleteNoteGroupBuildNoteMutation,
} from '@web/apps/Design/api/note-group-build-notes-api';
import { useDesignBuildNotes } from '@web/apps/Design/hooks/useDesignBuildNotes';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId';
import { BuildNote, NoteGroupBuildNote, NoteType } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useMemo } from 'react';

import { MenuPosition } from '../../../Layout/hooks/actions/useActionsBase';

export const useBuildNoteActions = ({
  closeMenu,
  noteGroupBuildNotes = [],
  noteGroupId,
  targetId,
  targetType,
  menuPosition,
  closeAfterAdd = false,
}: {
  closeMenu: VoidFunction;
  noteGroupBuildNotes?: NoteGroupBuildNote[];
  noteGroupId?: string;
  targetId?: string;
  targetType?: 'LayoutNode' | 'LayoutEdge';
  menuPosition?: MenuPosition;
  closeAfterAdd?: boolean;
}) => {
  const { data: buildNotes = [] } = useDesignBuildNotes(NoteType.FLAG);
  const designId = useDesignId();
  const { screenToFlowPosition } = useReactFlow();

  const { mutateAsync: createNoteGroupBuildNoteMutation, isPending: isCreatingNoteGroupBuildNote } =
    useCreateNoteGroupBuildNoteMutation();
  const { mutateAsync: deleteNoteGroupBuildNoteMutation, isPending: isDeletingNoteGroupBuildNote } =
    useDeleteNoteGroupBuildNoteMutation();

  const handleToggleFlagNote = useCallback(
    async (note: BuildNote) => {
      const existingNoteGroupBuildNote = noteGroupBuildNotes?.find(
        (noteGroupBuildNote) => noteGroupBuildNote.buildNoteId === note.id,
      );

      if (existingNoteGroupBuildNote) {
        await deleteNoteGroupBuildNoteMutation({
          designId,
          noteGroupBuildNoteId: existingNoteGroupBuildNote.id,
        });
        closeMenu();
      } else {
        const position = menuPosition ? screenToFlowPosition(menuPosition) : undefined;

        await createNoteGroupBuildNoteMutation({
          designId,
          buildNoteId: note.id,
          noteGroupId,
          targetId,
          targetType,
          position,
        });

        if (closeAfterAdd) {
          closeMenu();
        }
      }
    },
    [
      noteGroupBuildNotes,
      deleteNoteGroupBuildNoteMutation,
      designId,
      closeMenu,
      menuPosition,
      screenToFlowPosition,
      createNoteGroupBuildNoteMutation,
      noteGroupId,
      targetId,
      targetType,
      closeAfterAdd,
    ],
  );

  const buildNoteActions = useMemo(() => {
    if (buildNotes?.length === 0) {
      return (
        <MenuOptionGroup title="Flag Notes">
          <MenuItemOption isDisabled>No Flag Notes</MenuItemOption>
        </MenuOptionGroup>
      );
    }

    return (
      <MenuOptionGroup
        // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
        title={
          <HStack as="span">
            <Box as="span">Flag Notes</Box>
            {(isCreatingNoteGroupBuildNote || isDeletingNoteGroupBuildNote) && (
              <Box as="span">
                <Spinner as="span" size="xs" />
              </Box>
            )}
          </HStack>
        }
        type="checkbox"
        value={noteGroupBuildNotes?.map((noteGroupBuildNote: NoteGroupBuildNote) => noteGroupBuildNote.buildNoteId)}
      >
        {buildNotes.map((note) => (
          <MenuItemOption key={note.id} value={note.id} onClick={() => handleToggleFlagNote(note)}>
            <Text
              width="200px"
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >{`${note.position} - ${note.body}`}</Text>
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    );
  }, [
    buildNotes,
    noteGroupBuildNotes,
    handleToggleFlagNote,
    isCreatingNoteGroupBuildNote,
    isDeletingNoteGroupBuildNote,
  ]);

  return { buildNoteActions };
};
