import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { Edge } from '@xyflow/react';

import { LayoutNodeType } from '../../../../../types/Design.ts';
import { useCreateControlPointMutation } from '../../../../api/layout-nodes-api.ts';
import { useDesignId } from '../../../../hooks/useDesignId.tsx';
import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { RelationalSegmentEdgeData } from '../../components/edges/SegmentEdge/RelationalSegmentEdge.tsx';
import { EdgeType } from '../../relational_types.ts';
import { useBuildNoteActions } from './useBuildNoteActions.tsx';
import { useRelationalOverwrapActions } from './useRelationalOverwrapActions.tsx';

/**
 * Hook for segment actions.
 */
export const useRelationalSegmentActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const edge = element as Edge<RelationalSegmentEdgeData> & { data: RelationalSegmentEdgeData };

  const designId = useDesignId();
  const { mutateAsync: createControlPoint } = useCreateControlPointMutation();

  const addControlPoint = (type: LayoutNodeType) => {
    createControlPoint({ designId, data: { type, segmentEdgeId: edge.id } });
    closeMenu();
  };

  const { buildNoteActions } = useBuildNoteActions({
    closeMenu,
    targetId: edge?.id,
    targetType: 'LayoutEdge',
  });

  const { handleAddOverwrap, overwrapActions } = useRelationalOverwrapActions(edge?.id);

  const onAddOverwrap = () => {
    handleAddOverwrap();
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onAddOverwrap}>Add overwrap</MenuItem>
        <MenuItem onClick={() => addControlPoint(LayoutNodeType.BREAKOUT_POINT)}>Add breakout point</MenuItem>
        <MenuItem onClick={() => addControlPoint(LayoutNodeType.LAYOUT_POINT)}>Add layout point</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {buildNoteActions}
      <MenuDivider />
      {overwrapActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: EdgeType.SegmentEdge,
  } as MenuActions;
};
