import { SetStateAction, useCallback, useState } from 'react';

export const useStateWithDirtyFlag = <T>(initialState: T) => {
  const [state, setState] = useState(initialState);
  const [isDirty, setIsDirty] = useState(false);

  const setStateWithDirtyFlag = useCallback((setStateAction: SetStateAction<T>) => {
    setState((prevState) => {
      // Compute the new state
      const newState =
        typeof setStateAction === 'function' ? (setStateAction as (prevState: T) => T)(prevState) : setStateAction;

      // Only update isDirty if the new state is different
      if (newState !== prevState) {
        setIsDirty(true);
      }

      return newState;
    });
  }, []);

  const resetState = useCallback(
    (newState?: T) => {
      setState(newState ?? initialState);
      setIsDirty(false);
    },
    [initialState],
  );

  return { state, isDirty, setStateWithDirtyFlag, resetState };
};
