import { useCurrentUser } from '@web/queries/users.ts';

import { RelationalLayoutReactFlow } from '../RelationalLayout/RelationalLayoutReactFlow.tsx';
import { LayoutReactFlow } from './LayoutReactFlow.tsx';

/**
 * Entry point for the Layout feature.
 * @constructor
 */
const Layout = () => {
  const { data: user } = useCurrentUser();
  return user?.featureFlags.relationalLayoutData ? <RelationalLayoutReactFlow /> : <LayoutReactFlow />;
};

export default Layout;
