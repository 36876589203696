import { useBuildWiringList, WiringListRow } from '@web/apps/Design/features/WiringList/hooks/useBuildWiringList.tsx';
import { useSelectedDesignPart } from '@web/apps/Design/hooks/useSelectedDesignPart.tsx';
import { DesignPart } from '@web/apps/types';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FieldKey, RowId } from '../components/WiringListRow/types.ts';

interface WiringListContextProps {
  selectedDesignPart: DesignPart | undefined;
  focusedRow: RowId;
  setFocusedRow: (rowId: RowId) => void;
  isFocusedRow: (rowId: RowId) => boolean;
  focusedField: FieldKey | null;
  setFocusedField: (field: FieldKey | null) => void;
  isFocusedField: (field: FieldKey) => boolean;
  activeEditRow: RowId;
  setActiveEditRow: (rowId: RowId) => void;
  draftRow: RowId;
  setDraftRow: (rowId: RowId) => void;
  isKeyboardNav: boolean;
  setIsKeyboardNav: (isKeyboardNav: boolean) => void;
  isSelectMenuOpen: boolean;
  setIsSelectMenuOpen: (isOpen: boolean) => void;
  isEditing: (rowId: RowId) => boolean;
  hasActiveEdits: boolean;
  wiringList: WiringListRow[];
}

const WiringListContext = createContext<WiringListContextProps | undefined>(undefined);

export const WiringListProvider = ({ children }: { children: ReactNode }) => {
  const selectedDesignPart = useSelectedDesignPart();
  const { wiringList } = useBuildWiringList();

  const [focusedRow, setFocusedRow] = useState<RowId>(null);
  const [focusedField, setFocusedField] = useState<FieldKey | null>(null);
  const [activeEditRow, setActiveEditRow] = useState<RowId>(null);
  const [draftRow, setDraftRow] = useState<RowId>(null);
  const [isKeyboardNav, setIsKeyboardNav] = useState(false);
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

  // Memoized selectors
  const isFocusedRow = useCallback((rowId: RowId) => focusedRow === rowId, [focusedRow]);

  const isFocusedField = useCallback((field: FieldKey) => focusedField === field, [focusedField]);

  const isEditing = useCallback((rowId: RowId) => activeEditRow === rowId || rowId === 'draft', [activeEditRow]);

  const hasActiveEdits = useMemo(() => activeEditRow !== null || draftRow !== null, [activeEditRow, draftRow]);

  useEffect(() => {
    setActiveEditRow(null);
    setDraftRow(null);
    setFocusedRow(null);
    setFocusedField(null);
  }, [selectedDesignPart]);

  const contextValue = useMemo(
    () => ({
      selectedDesignPart,
      focusedRow,
      setFocusedRow,
      isFocusedRow,
      focusedField,
      setFocusedField,
      isFocusedField,
      activeEditRow,
      setActiveEditRow,
      draftRow,
      setDraftRow,
      isKeyboardNav,
      setIsKeyboardNav,
      isSelectMenuOpen,
      setIsSelectMenuOpen,
      isEditing,
      hasActiveEdits,
      wiringList,
    }),
    [
      selectedDesignPart,
      focusedRow,
      isFocusedRow,
      focusedField,
      isFocusedField,
      activeEditRow,
      draftRow,
      isKeyboardNav,
      isSelectMenuOpen,
      isEditing,
      hasActiveEdits,
      wiringList,
    ],
  );

  return <WiringListContext.Provider value={contextValue}>{children}</WiringListContext.Provider>;
};

export const useWiringList = () => {
  const context = useContext(WiringListContext);
  if (!context) {
    throw new Error('useWiringList must be used within a WiringListProvider');
  }
  return context;
};
