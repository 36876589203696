import { useMemo } from 'react';

import { NoteGroupBuildNote, UUID } from '../../../../types';
import { useLayoutData } from '../../../hooks/useLayoutData';

export const useNoteGroupBuildNotes = (layoutNodeId: UUID) => {
  const { layoutNodes } = useLayoutData();

  const noteGroupBuildNotesMap = useMemo(() => {
    const idToNotesMap: { [id: string]: NoteGroupBuildNote[] } = {};
    layoutNodes.forEach(({ id, noteGroupBuildNotes }) => (idToNotesMap[id] = noteGroupBuildNotes || []));

    return idToNotesMap;
  }, [layoutNodes]);

  return noteGroupBuildNotesMap[layoutNodeId] || [];
};
