import { Actor, Document, DocumentAttributes, Manufacturer, Timestamps, UUID } from '@web/apps/types';

/**
 * A Part can be any of the following types
 */
export type Part = Backshell | Cable | Connector | Contact | Splice | Overwrap | Wire | Passive | GenericPart;

/**
 * Enumeration for part types, sorted alphabetically
 */
export enum PartType {
  BACKSHELL = 'Backshell',
  CABLE = 'Cable',
  CONNECTOR = 'Connector',
  CONTACT = 'Contact',
  GENERIC = 'GenericPart',
  OVERWRAP = 'Overwrap',
  PASSIVE = 'Passive',
  PIGTAIL = 'Pigtail',
  SPLICE = 'Splice',
  WIRE = 'Wire',
}

/**
 * BasePart contains the shared fields for all part types.
 */
export interface BasePart extends Timestamps {
  id: string;
  type: PartType;
  status: PartStatus;
  partNumber: string;
  manufacturers: Manufacturer[] | null;
  description: string;
  properties: object;
  confidence: PartConfidence;
  documents: Document[];
  creator?: Actor;
  updater?: Actor;
}

/**
 * A Backshell has these additional fields
 */
export interface Backshell extends BasePart {
  shielded: boolean;
}

/**
 * Cables & Wires share these fields
 */
export interface ConductorFields extends BasePart {
  innerDiameter: string | null;
  outerDiameter: string | null;
  diameterUnitOfMeasurement: string | null;
}

/**
 * A Cable has these additional fields
 */
export interface Cable extends ConductorFields {
  jacket: string;
  shielded: boolean;
  shieldType: string;
  partWires: PartWire[];
}

/**
 * A Connector has these additional fields
 */
export interface Connector extends BasePart {
  gender: PartGender;
  insertArrangementId: string | null;
  insertArrangement: InsertArrangement | null;
  shielded: boolean;
  acceptsBackshell: boolean;
  acceptsContacts: boolean;
  accessories: Accessory[];
  termination: string;
  partTools: PartTool[];
}

/**
 * A Contact has these additional fields
 */
export interface Contact extends BasePart {
  gender: PartGender;
  gaugeMinAwg: number;
  gaugeMaxAwg: number;
  termination: string;
  stripLength: number;
  partTools: PartTool[];
}

/**
 * A GenericPart has these additional fields
 */
export interface GenericPart extends BasePart {
  subtype: string;
  genericPartUnit: 'length' | 'count';
}

/**
 * A Passive has these additional fields
 */
export interface Passive extends BasePart {
  subtype: string;
  connections: PassiveConnection[];
  passiveValue?: string;
  acceptsContacts: boolean;
}

/**
 * A Pigtail has these additional fields
 */
export interface Pigtail extends BasePart {
  partWires: PartWire[];
}

/**
 * A Splice has these additional fields
 */
export interface Splice extends BasePart {
  gaugeMinAwg: number;
  gaugeMaxAwg: number;
  insulated: boolean;
  madeOnAssembly: boolean;
  subtype: string;
  acceptsContacts: boolean;
  partTools: PartTool[];
}

/**
 * A Wire has these additional fields
 */
export interface Wire extends ConductorFields {
  cableOnlyWire: boolean;
  colors: string[];
  gauge: string;
}

/**
 * An Overwrap has these additional fields
 */
export interface Overwrap extends BasePart {
  consumable: boolean;
  color: string;
  subtype: string;
}

/**
 * Interfaces for parts and related data, sorted alphabetically
 */

export interface AlternatePart {
  id: UUID;
  partNumber: string;
  manufacturerNames: string[];
  description: string;
}

export interface Cavity {
  name: string;
}

export interface Accessory {
  id?: string;
  partId?: string;
  partNumber?: string;
  type: PartType;
  _destroy?: boolean;
  included: boolean;
  torqueValue: string | null;
}

export interface InsertArrangement extends Timestamps {
  id: string;
  name: string;
  cavities: Cavity[];
  documents: Document[];
}

export interface PartWire {
  id?: string;
  position: number;
  wireId?: string;
  wire?: Wire;
  twistingGroup?: string;
  signalName?: string;
  _destroy?: boolean;
}

export interface PassiveConnection {
  label: string;
  signalName?: string;
}

export interface PartTool {
  id?: string;
  toolId: string;
  notes?: string;
  partNumber: string;
  type: string;
  _destroy?: boolean;
}

/**
 * Part interfaces for part updates
 */
export interface UpdatePartParams {
  id: string;
  type: PartType;
  status: PartStatus;
  partNumber: string;
  manufacturerIds: string[];
  description: string;
  confidence: PartConfidence;
  cableOnlyWire?: boolean;
  gauge?: string;
  gaugeMaxAwg?: number;
  gaugeMinAwg?: number;
  gender?: PartGender;
  genericPartUnit?: 'length' | 'count';
  acceptsBackshell?: boolean;
  acceptsContacts?: boolean;
  insulated?: boolean;
  insertArrangementId?: string;
  jacket?: string;
  madeOnAssembly?: boolean;
  passiveValue?: string;
  shieldType?: string;
  shielded?: boolean;
  size?: string;
  subtype?: string;
  termination?: string;
  consumable?: boolean;
  color?: string;
  torqueValue?: number;
  stripLength?: number;
  documents_attributes?: DocumentAttributes[];
  part_wires_attributes?: PartWireAttributes[];
  colors?: string[];
  connections?: PassiveConnection[];
  included_accessories_attributes?: AccessoryAttributes[];
  partTools?: PartTool[];
}

interface PartWireAttributes {
  id?: string;
  wireId?: string;
  position: number;
  twistingGroup?: string;
  signalName?: string;
  _destroy?: boolean;
}

interface AccessoryAttributes {
  id?: string;
  partId: string;
  _destroy?: boolean;
}

/**
 * Enumerations supporting parts, sorted alphabetically
 */

export enum PartConfidence {
  HIGH = 'High',
  LOW = 'Low',
}

export enum PartGender {
  FEMALE = 'Female',
  MALE = 'Male',
  COMBO = 'Genderless',
}

export enum PartStatus {
  DELETED = 'Deleted',
  DRAFT = 'Draft',
  RELEASED = 'Released',
}

export enum PassiveType {
  ANTENNA = 'Antenna',
  CAPACITOR = 'Capacitor',
  DIODE = 'Diode',
  INDUCTOR = 'Inductor',
  RESISTOR = 'Resistor',
}

export enum PositionLabel {
  ALPHA_LOWER = 'aa - zz',
  ALPHA_UPPER = 'AA - ZZ',
  NUMERIC = '1 - n',
}

export enum Shielding {
  DOUBLE_SHIELDED = 'Double Shielded',
  SHIELDED = 'Shielded',
  UNSHIELDED = 'Unshielded',
}
