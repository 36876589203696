import { Edge, Node } from '@xyflow/react';
import { Fragment, MouseEvent } from 'react';

import { MenuActions } from './actionsTypes';
import { useBreakoutPointActions } from './useBreakoutPointActions';
import { useConnectorActions } from './useConnectorActions';
import { useLayoutPointActions } from './useLayoutPointActions';
import { useMeasurementActions } from './useMeasurementActions.tsx';
import { useNoteGroupActions } from './useNoteGroupActions';
import { usePaneActions } from './usePaneActions';
import { usePassiveActions } from './usePassiveActions';
import { usePigtailActions } from './usePigtailActions.tsx';
import { useSegmentActions } from './useSegmentActions';
import { useSpliceActions } from './useSpliceActions';

export const useLayoutActions = () => {
  // Element Context menu actions
  const breakoutPointActions = useBreakoutPointActions();
  const connectorActions = useConnectorActions();
  const layoutPointActions = useLayoutPointActions();
  const measurementActions = useMeasurementActions();
  const noteGroupActions = useNoteGroupActions();
  const paneActions = usePaneActions();
  const passiveActions = usePassiveActions();
  const pigtailActions = usePigtailActions();
  const segmentActions = useSegmentActions();
  const spliceActions = useSpliceActions();

  const actionsArray: MenuActions[] = [
    breakoutPointActions,
    connectorActions,
    layoutPointActions,
    measurementActions,
    noteGroupActions,
    paneActions,
    passiveActions,
    pigtailActions,
    segmentActions,
    spliceActions,
  ];

  // Close all context menus
  const closeAllMenus = () => {
    actionsArray.forEach((actions) => actions.closeMenu());
  };

  // Generic context menu handler
  const onGraphElementContextMenu = (event: MouseEvent, element: Node | Edge) => {
    event.preventDefault();
    closeAllMenus();

    let elementTypeActions = actionsArray.find((actions) => actions.elementType === element.type);

    if (elementTypeActions) {
      elementTypeActions.showMenu(event, element);
    }
  };

  const onPaneContextMenu = (event: MouseEvent | globalThis.MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    paneActions.showMenu(event);
  };

  // Context menus
  const contextMenus = (
    <>
      {actionsArray.map(({ menu, elementType }, index) => (
        <Fragment key={elementType || index}>{menu}</Fragment>
      ))}
    </>
  );

  return {
    closeAllMenus,
    onGraphElementContextMenu,
    onPaneContextMenu,
    contextMenus,
  };
};
