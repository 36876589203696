import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { DesignPart, WireData } from '@web/apps/types';

interface Props {
  wire: DesignPart & {
    partData: WireData;
  };
}

export const WireDetails = ({ wire }: Props) => (
  <HStack w="full" p={2} spacing={4}>
    <Text minW={12}>{wire.name}</Text>
    <Text minW={24}>{wire.partData.partNumber}</Text>
    <Text minW={24}>{wire.partData.gauge} AWG</Text>
    <Spacer />
    <HStack spacing={0} borderWidth={1} borderColor="gray.300">
      {wire.partData.colors.map((color, index) => (
        <Box key={`${color}-${index}`} boxSize={4} bg={color} />
      ))}
    </HStack>
  </HStack>
);
