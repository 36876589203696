import {
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DEFAULT_PARTS_PAGE_SIZE } from '@web/api/parts-api.ts';
import { Part, PartStatus, PartType, PartWire } from '@web/apps/types';
import { partTypeToRouteName } from '@web/apps/utils/parts.ts';
import generateURlSearch from '@web/common/lib/api-utils/generateURLSearch.ts';
import { successToast } from '@web/common/toasts.ts';
import DashboardRowActions from '@web/components/DashboardRowActions.tsx';
import FilterBox from '@web/components/FilterBox.tsx';
import Loading from '@web/components/Loading.tsx';
import Paginate from '@web/components/Paginate.tsx';
import useDebouncedValue from '@web/hooks/useDebouncedValue.ts';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Title from '../../../components/Title.tsx';
import { useDeletePart, usePart, useParts, useUpdatePart } from '../api/queries.ts';
import ConfidenceBadge from './ConfidenceBadge.tsx';
import PartsLibraryModal, { Mode } from './PartsLibraryModal.tsx';
import StatusBadge from './StatusBadge.tsx';

export interface CustomColumnDef {
  header: string;
  renderFn: (part: Part) => React.ReactNode;
}

interface Props {
  partType: PartType;
  columnDefs?: CustomColumnDef[];
}

const PartsLibraryDashboard = ({ partType, columnDefs }: Props) => {
  const navigate = useNavigate();
  const { partId } = useParams<{ partId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';
  const partNumber = searchParams.get('part_number') ?? '';
  const debouncedPartNumber = useDebouncedValue(partNumber, 500);
  const { isLoading, data: parts } = useParts(partType, debouncedPartNumber, page, DEFAULT_PARTS_PAGE_SIZE);
  const { reset } = useFormContext<Part>();
  const [mode, setMode] = useState(Mode.CREATE);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updatePart = useUpdatePart(partType);
  const deletePart = useDeletePart(partType);
  const { data: part } = usePart(partId);

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('part_number', value);
    } else {
      searchParams.delete('part_number');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const handleClose = () => {
    navigate(`/parts/${partTypeToRouteName(partType)}${generateURlSearch({ page, part_number: partNumber })}`);
    reset();
    setMode(Mode.READ);
    onClose();
  };

  const handleAddPart = () => {
    reset({ partNumber: '' });
    setMode(Mode.CREATE);
    onOpen();
  };

  const handleViewPart = useCallback(
    (part: Part) => {
      navigate(
        `/parts/${partTypeToRouteName(partType)}/${part.id}${generateURlSearch({
          page: page,
          mode: Mode.READ,
          part_number: partNumber,
        })}`,
      );
    },
    [navigate, partNumber, partType, page],
  );

  const handleEditPart = useCallback(
    (part: Part) => {
      navigate(
        `/parts/${partTypeToRouteName(partType)}/${part.id}${generateURlSearch({
          page: page,
          mode: Mode.UPDATE,
          part_number: partNumber,
        })}`,
      );
    },
    [navigate, partNumber, partType, page],
  );

  useEffect(() => {
    if (part) {
      const mode = searchParams.get('mode') as Mode | null;

      reset(part);
      setMode(mode ?? Mode.READ);
      onOpen();
    }
  }, [onOpen, part, reset, searchParams]);

  const handleReleasePart = async (part: Part) => {
    const res = await updatePart.mutateAsync({ ...part, status: PartStatus.RELEASED });

    if (res) {
      successToast('Part successfully released');
      onClose();
    }
  };

  const handleDeletePart = async (partId: string) => {
    if (confirm('Are you sure you want to delete this part?')) {
      await deletePart.mutateAsync(partId);
      successToast('Part successfully deleted');
    }
  };

  const handleClonePart = (part: Part) => {
    reset({
      ...part,
      status: PartStatus.DRAFT,
      id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      creator: undefined,
      updater: undefined,
      documents: part.documents.map((doc) => ({ ...doc, id: undefined })),
      partWires: part.partWires?.map((partWire) => ({ ...partWire, id: undefined }) as PartWire),
      partTools: part.partTools?.map((partTool) => ({ ...partTool, id: undefined })),
      accessories: part.accessories?.map((accessory) => ({ ...accessory, id: undefined })),
    });
    setMode(Mode.CREATE);
    onOpen();
  };

  const getTitle = () => {
    if (partType === PartType.GENERIC) {
      return 'Generic Parts';
    }
    return `${partType}s`;
  };

  const getButtonText = () => {
    if (partType === PartType.GENERIC) {
      return 'Generic Part';
    }
    return partType;
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  return (
    <Stack direction="column" w="full" h="full" spacing={8}>
      <Spacer />
      <Flex justifyContent={'space-between'} px={8}>
        <Title title={getTitle()} aria-label="page-title" />
        <Button onClick={handleAddPart}>+ Add {getButtonText()}</Button>
        <PartsLibraryModal
          partType={partType}
          mode={mode}
          isOpen={isOpen}
          onClose={handleClose}
          onDeletePart={handleDeletePart}
        />
      </Flex>
      <HStack gap={6} px={8}>
        <FilterBox placeholder="Type to filter parts" value={partNumber} onChange={handleFilterQuery} />
        <Button
          onClick={() => {
            handleFilterQuery('');
          }}
        >
          Clear filters
        </Button>
      </HStack>
      <HStack alignItems="flex-start" spacing={0} height="full">
        <Flex width="full" height="100%" overflowY="auto">
          {isLoading ? (
            <Center height="50vh" width="80vw">
              <Loading message="Loading parts library…" />
            </Center>
          ) : (
            <VStack width="full">
              <TableContainer width="full">
                <Table variant="senraTable">
                  <Thead>
                    <Tr>
                      <Th>Part Number</Th>
                      <Th>Status</Th>
                      <Th>Manufacturer(s)</Th>
                      <Th>Description</Th>
                      {columnDefs?.map((columnDef) => <Th key={columnDef.header}>{columnDef.header}</Th>)}
                      <Th>Data Confidence</Th>
                      <Th textAlign="right">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {parts?.data.map((part) => (
                      <Tr key={part.id} role="group" onClick={() => handleViewPart(part)}>
                        <Td>
                          <Text paddingRight={4}>{part.partNumber}</Text>
                        </Td>
                        <Td>
                          <StatusBadge status={part.status} />
                        </Td>
                        <Td>{part.manufacturers?.map((m) => m.name).join(', ') || 'N/A'}</Td>
                        <Td>
                          {part.description && (
                            <Tooltip label={part.description}>
                              <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                {part.description}
                              </Text>
                            </Tooltip>
                          )}
                        </Td>
                        {columnDefs?.map((columnDef) => <Td key={columnDef.header}>{columnDef.renderFn(part)}</Td>)}
                        <Td>
                          <ConfidenceBadge confidence={part.confidence} />
                        </Td>
                        <Td textAlign="right">
                          <DashboardRowActions
                            item={part}
                            onView={handleViewPart}
                            onEdit={handleEditPart}
                            onRelease={handleReleasePart}
                            onDelete={handleDeletePart}
                            onClone={handleClonePart}
                            onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Paginate
                onPageChange={handlePageClick}
                selectedPage={parseInt(page) - 1}
                pageCount={parts?.meta.totalPages ?? 0}
              />
            </VStack>
          )}
        </Flex>
      </HStack>
    </Stack>
  );
};

export default PartsLibraryDashboard;
