import { useLayoutData } from '@web/apps/Design/hooks/useLayoutData.tsx';
import { LayoutEdge, LayoutNode, LayoutNodeType } from '@web/apps/types';
import { partition } from 'lodash';
import { useEffect, useState } from 'react';

import { setEdgesType, setNodesType } from '../../../../types/reactFlow.ts';

const toReactFlowNode = (node: LayoutNode) => ({
  id: node.id,
  position: { x: node.x, y: node.y },
  type: node.type === LayoutNodeType.DESIGN_PART_NODE ? node?.designPartType : node.type,
  data: {
    designPartId: node.designPartId,
    designPartName: node.designPartName,
    rotateLock: node.rotateLocked,
    angle: node.angle,
    noteGroupBuildNotes: node.noteGroupBuildNotes,
    groupedConductors: node.groupedConductors,
  },
  parentId: node.targetId,
});

const toReactFlowEdge = (edge: LayoutEdge) => ({
  id: edge.id,
  type: edge.type,
  source: edge.sourceId,
  target: edge.targetId,
  data: {
    distanceFromEdge: edge.distanceFromEdge,
    measurement: edge.measurement,
    flipped: edge.flipped,
    designPartLayoutEdges: edge.designPartLayoutEdges || [],
    groupedConductors: edge.groupedConductors || [],
  },
});

export const useLoadRelationalLayout = (setNodes: setNodesType, setEdges: setEdgesType) => {
  const [layoutLoaded, setLayoutLoaded] = useState(false);
  const { layoutNodes, layoutEdges, isSuccess: isLayoutDataLoaded } = useLayoutData();

  useEffect(() => {
    if (isLayoutDataLoaded && layoutNodes && layoutEdges) {
      const nodes = layoutNodes.map(toReactFlowNode);

      // React flow requires parents to be before their children in the nodes array
      const [parents, children] = partition(nodes, ({ parentId }) => !parentId);
      const parentFirstNodes = [...parents, ...children];

      setNodes(parentFirstNodes);
      setEdges(layoutEdges.map(toReactFlowEdge));
      setLayoutLoaded(true);
    }
  }, [layoutLoaded, layoutNodes, layoutEdges, setNodes, setEdges, isLayoutDataLoaded]);

  return layoutLoaded;
};
