import { ResolvedConnection, ResolvedConnectionPoint } from '@web/apps/types';
import { useCallback } from 'react';

import { useConnections } from '../../../hooks/useConnections.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { getRowState } from '../components/WiringListRow/state.ts';
import { Fields, RowId } from '../components/WiringListRow/types.ts';
import { useWiringList } from '../hooks/useWiringList.tsx';

export const useWiringListRowActions = (
  rowId: RowId,
  connectionPoint: ResolvedConnectionPoint,
  connection: ResolvedConnection | undefined,
  state: Fields,
  isDirty: boolean,
  resetState: (newState?: Fields) => void,
) => {
  const { designId, isViewOnly } = useDesign();
  const { createConnection, updateConnection, deleteConnection } = useConnections();
  const { setActiveEditRow, setDraftRow, isEditing } = useWiringList();

  const enterEditingMode = useCallback(() => {
    if (!isViewOnly && !isEditing(rowId)) {
      resetState();
      setActiveEditRow(rowId);
    }
  }, [isViewOnly, isEditing, rowId, resetState, setActiveEditRow]);

  const exitEditingMode = useCallback(() => {
    setActiveEditRow(null);
    setDraftRow(null);
  }, [setActiveEditRow, setDraftRow]);

  const createOrUpdateConnection = useCallback(
    async (updatedState: Fields) => {
      const connectionData = {
        sourceId: connectionPoint.id,
        destinationId: updatedState.destinationId,
        conductorId: updatedState.conductorId,
        signalName: updatedState.signalName,
        sourceContactId: updatedState.sourceContactId,
        destinationContactId: updatedState.destinationContactId,
      };

      if (!connection) {
        return await createConnection.mutateAsync({ designId, data: connectionData });
      }

      return await updateConnection.mutateAsync({ designId, connectionId: connection.id, data: connectionData });
    },
    [connection, connectionPoint.id, createConnection, designId, updateConnection],
  );

  const saveRow = useCallback(async () => {
    if (isDirty) {
      const newState = await createOrUpdateConnection(state);
      resetState(getRowState(newState));
    }
    exitEditingMode();
  }, [isDirty, exitEditingMode, createOrUpdateConnection, state, resetState]);

  const saveRowOnBlur = useCallback(async () => {
    if (isDirty) {
      const newState = await createOrUpdateConnection(state);
      resetState(getRowState(newState));
    }
  }, [isDirty, resetState, createOrUpdateConnection, state]);

  const clearRow = async () => {
    if (connection) {
      await deleteConnection.mutateAsync({ designId, connectionId: connection.id });
    }
  };

  return {
    enterEditingMode,
    exitEditingMode,
    saveRow,
    saveRowOnBlur,
    isSaving: createConnection.isPending || updateConnection.isPending,
    clearRow,
    isClearing: deleteConnection.isPending,
  };
};
