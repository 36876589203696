import { Edge } from '@xyflow/react';

import FieldValueList from '../../../../components/FieldValueList.tsx';
import { useDesignOverview } from '../../../../hooks/useDesignOverview.tsx';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';
import { isMeasurementEdge } from '../../relational_types.ts';

export interface RelationalMeasurementViewProps {
  edge?: Edge;
}

export const RelationalMeasurementView = ({ edge }: RelationalMeasurementViewProps) => {
  const { lengthUnit } = useDesignOverview();
  const { getDesignPartById } = useDesignParts();

  if (!edge || !isMeasurementEdge(edge)) {
    return null;
  }

  const sourceDesignPart = getDesignPartById(edge.source);
  const targetDesignPart = getDesignPartById(edge.target);

  const data = [
    { field: 'Source', value: sourceDesignPart?.name || 'Control Point' },
    { field: 'Destination', value: targetDesignPart?.name || 'Control Point' },
    { field: 'Measurement', value: `${edge.data.measurement} ${lengthUnit}` },
  ];

  return <FieldValueList data={data} p={4} />;
};
