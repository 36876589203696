import {
  Actor,
  AlternatePart,
  Backshell,
  BasePart,
  Cable,
  Connection,
  ConnectionPoint,
  Connector,
  Contact,
  GenericPart,
  Overwrap,
  PartType,
  Passive,
  Pigtail,
  Splice,
  Tenant,
  Timestamps,
  UUID,
  Wire,
} from '@web/apps/types';
import type { Edge, Node, Viewport } from '@xyflow/react';

/**
 * Interface for an item in the BOM.
 */
export interface BOMItem {
  id: number;
  type: PartType;
  quantity: number;
  unit: string;
  partNumber: string;
  description: string;
  usage: string[];
  manufacturer: string;
  alternates: AlternateDesignPart[];
}

/**
 * A design is a collection of parts that make up a harness. Designs can be used to generate a Bill of Materials (BOM)
 * for a harness.
 */
export interface Design extends Timestamps {
  id: UUID;
  name: string;
  bom: BOMItem[];
  description: string;
  partNumber: string;
  partRevision: string;
  lengthUnit: 'mm' | 'in';
  measurementMode: 'Face' | 'Rear';
  designParts: DesignPart[];
  alternateDesignParts: AlternateDesignPart[];
  connections: Connection[];
  buildNotes: BuildNote[];
  layoutData: LayoutData;
  lockedAt: string | null;
  creator?: Actor;
  tenant: Tenant;
  layoutNodes: LayoutNode[];
  layoutEdges: LayoutEdge[];
}

/**
 * A design part is a part included in a design. Parts from the part library becomes a DesignPart when it is
 * added to a design. Design parts are used to generate a Bill of Materials (BOM) for a harness. Once a part is added to
 * a design, the PartData is copied to the DesignPart, and the part data is not updated if the part data changes.
 */
export interface DesignPart {
  id: UUID;
  name: string;
  partData: BackshellData | CableData | ConnectorData | ContactData | GenericData | PassiveData | SpliceData | WireData;
  quantity: string;
  backshell?: DesignPart;
  contacts?: DesignPart[];
  connectionPoints?: ConnectionPoint[];
  includedAccessory?: boolean;
  partDataDiff: string[][];
}

/**
 * An alternate design part is a part that can be used as an alternative to a design part. Alternate design parts are
 * used to generate a Bill of Materials (BOM) for a harness.
 */
export interface AlternateDesignPart {
  id: UUID;
  partId: UUID;
  alternatePart: AlternatePart;
  applicableDesigns: Design[];
}

/**
 * A part group is a logical grouping of parts in a design. Part groups are used to organize parts in the Assembly
 * Navigator.
 */
export interface PartGroup {
  name: string;
  types: PartType[];
  parts: DesignPart[];
}

/**
 * PartData contains a snapshot of the Part details at the time the part was added to the design. The following are
 * shared fields for all part types.
 */
export type PartData = Pick<BasePart, 'id' | 'type' | 'partNumber' | 'manufacturers' | 'confidence' | 'description'>;

/**
 * BackshellData contains the details of a Backshell part.
 */
export interface BackshellData extends PartData, Pick<Backshell, 'shielded'> {}

export const isBackshellDesignPart = (part: DesignPart): part is DesignPart & { partData: BackshellData } => {
  return part.partData.type === PartType.BACKSHELL;
};

/**
 * CableData contains the details of a Cable part.
 */
export interface CableData
  extends PartData,
    Pick<
      Cable,
      | 'partWires'
      | 'jacket'
      | 'shielded'
      | 'shieldType'
      | 'innerDiameter'
      | 'outerDiameter'
      | 'diameterUnitOfMeasurement'
    > {}

export const isCableDesignPart = (part: DesignPart): part is DesignPart & { partData: CableData } => {
  return part.partData.type === PartType.CABLE;
};

/**
 * ConnectorData contains the details of a Connector part.
 */
export interface ConnectorData
  extends PartData,
    Pick<
      Connector,
      | 'gender'
      | 'insertArrangementId'
      | 'insertArrangement'
      | 'shielded'
      | 'acceptsBackshell'
      | 'acceptsContacts'
      | 'accessories'
      | 'termination'
    > {}

export const isConnectorDesignPart = (part: DesignPart): part is DesignPart & { partData: ConnectorData } => {
  return part.partData.type === PartType.CONNECTOR;
};

/**
 * ContactData contains the details of a Contact part.
 */
export interface ContactData
  extends PartData,
    Pick<Contact, 'gender' | 'gaugeMinAwg' | 'gaugeMaxAwg' | 'termination' | 'stripLength'> {}

export const isContactDesignPart = (part: DesignPart): part is DesignPart & { partData: ContactData } => {
  return part.partData.type === PartType.CONTACT;
};

/**
 * GenericData contains the details of a Generic part.
 */
export interface GenericData extends PartData, Pick<GenericPart, 'subtype' | 'genericPartUnit'> {}

export const isGenericDesignPart = (part: DesignPart): part is DesignPart & { partData: GenericData } => {
  return part.partData.type === PartType.GENERIC;
};

/**
 * OverwrapData contains the details of an Overwrap part.
 */
export interface OverwrapData extends PartData, Pick<Overwrap, 'consumable' | 'subtype' | 'color'> {}

export const isOverwrapDesignPart = (part: DesignPart): part is DesignPart & { partData: OverwrapData } => {
  return part.partData.type === PartType.OVERWRAP;
};

/**
 * PassiveData contains the details of a Passive part.
 */
export interface PassiveData
  extends PartData,
    Pick<Passive, 'subtype' | 'connections' | 'passiveValue' | 'acceptsContacts'> {}

export const isPassiveDesignPart = (part: DesignPart): part is DesignPart & { partData: PassiveData } => {
  return part.partData.type === PartType.PASSIVE;
};

/**
 * PigtailData contains the details of a Pigtail part.
 */
export interface PigtailData extends PartData, Pick<Pigtail, 'partWires'> {}

export const isPigtailDesignPart = (part: DesignPart): part is DesignPart & { partData: PigtailData } => {
  return part.partData.type === PartType.PIGTAIL;
};

/**
 * SpliceData contains the details of a Splice part.
 */
export interface SpliceData
  extends PartData,
    Pick<Splice, 'gaugeMinAwg' | 'gaugeMaxAwg' | 'insulated' | 'madeOnAssembly' | 'subtype' | 'acceptsContacts'> {}

export const isSpliceDesignPart = (part: DesignPart): part is DesignPart & { partData: SpliceData } => {
  return part.partData.type === PartType.SPLICE;
};

/**
 * WireData contains the details of a Wire part.
 */
export interface WireData
  extends PartData,
    Pick<
      Wire,
      'cableOnlyWire' | 'colors' | 'gauge' | 'innerDiameter' | 'outerDiameter' | 'diameterUnitOfMeasurement'
    > {}

export const isWireDesignPart = (part: DesignPart): part is DesignPart & { partData: WireData } => {
  return part.partData.type === PartType.WIRE;
};

/**
 * A note that is added to a design.
 */
export enum NoteType {
  GENERAL = 'GeneralNote',
  FLAG = 'FlagNote',
}

/**
 * A build note is a note added to a design. Build notes are used to communicate information about the design to the
 * manufacturing team.
 */
export interface BuildNote extends Timestamps {
  id: UUID;
  designId: UUID;
  body: string;
  position: number;
  type: NoteType;
  noteGroupNodeIds: UUID[];
}

/**
 * LayoutData contains the layout information for a design.
 */
export interface LayoutData {
  nodes: Node[];
  edges: Edge[];
  viewport: Viewport;
}

export enum LayoutNodeType {
  DESIGN_PART_NODE = 'DesignPartNode',
  BREAKOUT_POINT = 'BreakoutPoint',
  LAYOUT_POINT = 'LayoutPoint',
  NOTE_GROUP = 'NoteGroup',
}

export enum LayoutEdgeType {
  SEGMENT_EDGE = 'SegmentEdge',
  MEASUREMENT_EDGE = 'MeasurementEdge',
  NOTE_EDGE = 'NoteEdge',
}

export interface LayoutNode {
  id: UUID;
  type: LayoutNodeType;
  x: number;
  y: number;
  designPartType?: string;
  designPartId?: UUID;
  designPartName?: string;
  rotateLocked: boolean;
  angle: number;
  noteGroupBuildNotes?: NoteGroupBuildNote[];
  noteGroupId?: UUID;
  segmentEdgeId?: UUID;
  targetId?: UUID;
  groupedConductors?: GroupedConductor[];
}

export interface LayoutEdge {
  id: UUID;
  type: LayoutEdgeType;
  sourceId: UUID;
  targetId: UUID;
  distanceFromEdge?: number;
  measurement?: number;
  flipped?: boolean;
  designPartLayoutEdges: DesignPartLayoutEdge[];
  groupedConductors?: GroupedConductor[];
}

export interface NoteGroupBuildNote {
  id: UUID;
  noteGroupId: UUID;
  buildNoteId: UUID;
}

export interface DesignPartLayoutEdge {
  id: UUID;
  designPartId: UUID;
  layoutEdgeId: UUID;
  designPartNumber: string;
}

export interface GroupedConductor {
  conductors: UUID[];
  sourceDesignPartId: UUID;
  targetDesignPartId: UUID;
}
