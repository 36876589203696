import { Box, Text } from '@chakra-ui/react';
import { UUID } from '@web/apps/types';
import { Node, NodeProps } from '@xyflow/react';

import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { HandleTypes } from '../../../types/handles.ts';
import { getNodeColor } from '../../../utils/common.ts';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { SpliceShape } from './SpliceShape.tsx';

export type SpliceNodeData = {
  designPartId: UUID;
};

export const defaultSpliceNodeData: SpliceNodeData = {
  designPartId: '',
};

export type SpliceNodeType = Node<SpliceNodeData>;

/**
 * Splice node component.
 * @param props
 * @constructor
 */
export const SpliceNode = (props: NodeProps<SpliceNodeType>) => {
  const { data = defaultSpliceNodeData, selected } = props;
  const designPart = useDesignParts().getDesignPartById(data.designPartId);

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative">
      <Text color="gray.900" fontFamily="mono" position="absolute" top="-20px">
        {designPart?.name}
      </Text>
      <SpliceShape color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
