import { Part, PartType, UpdatePartParams } from '@web/apps/types';
import generateURlSearch from '@web/common/lib/api-utils/generateURLSearch.ts';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ModalForm from '../../../components/form/ModalForm.tsx';
import { useCreatePart, useUpdatePart } from '../api/queries.ts';
import PartFields from './PartFields.tsx';
import PartsLibraryModalHeader from './PartsLibraryModalHeader.tsx';

export enum Mode {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
}

interface Props {
  partType: PartType;
  mode: Mode;
  isOpen: boolean;
  onClose: () => void;
  onPartCreated?: (part?: Part) => void;
  onDeletePart?: (partId: string) => Promise<void>;
}

const PartsLibraryModal = ({ partType, mode, isOpen, onClose, onPartCreated, onDeletePart }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const createPart = useCreatePart(partType);
  const updatePart = useUpdatePart(partType);
  const { resetField } = useFormContext<Part>();

  const isUpdateMode = mode === Mode.UPDATE;
  const isCreateMode = mode === Mode.CREATE;
  const isEditing = mode !== Mode.READ;

  const handleSubmitPart = async (part: Part): Promise<Part | undefined> => {
    const mutation = isCreateMode ? createPart : updatePart;
    const { manufacturers, ...restPart } = part;

    const sanitizedPart = Object.fromEntries(
      Object.entries(restPart).map(([key, value]) => [key, value === null ? undefined : value]),
    );

    const payload: UpdatePartParams = {
      ...sanitizedPart,
      type: partType,
      manufacturerIds: manufacturers?.map((m) => m.id) || [],
      id: part.id,
      status: part.status,
      partNumber: part.partNumber,
      description: part.description,
      confidence: part.confidence,
    };

    const newPart = await mutation.mutateAsync(payload);
    if (newPart) {
      isCreateMode && onPartCreated?.(newPart);
      return newPart as Part;
    } else {
      resetField('status');
    }
  };

  const toggleEdit = () => {
    const mode = searchParams.get('mode') as Mode | null;
    const page = searchParams.get('page') || '1';
    if (!mode || mode === Mode.READ) {
      navigate(`${generateURlSearch({ page: page, mode: Mode.UPDATE })}`);
    } else if (mode === Mode.UPDATE) {
      navigate(`${generateURlSearch({ page: page, mode: Mode.READ })}`);
    }
  };

  const Header = () => <PartsLibraryModalHeader partType={partType} />;

  return (
    <ModalForm
      isOpen={isOpen}
      onSubmit={handleSubmitPart}
      onClose={onClose}
      closeButton={true}
      header={<Header />}
      successToastMsg="Part saved successfully"
      minWidth={['90%', '90%', '90%', '65%', '50%']}
    >
      <PartFields
        partType={partType}
        isEditing={isEditing}
        isUpdateMode={isUpdateMode}
        isCreateMode={isCreateMode}
        onClose={onClose}
        toggleEdit={toggleEdit}
        onDeletePart={onDeletePart}
      />
    </ModalForm>
  );
};

export default PartsLibraryModal;
