import { Text } from '@chakra-ui/react';
import { Connector, Part, PartType } from '@web/apps/types';
import { valueToLabel } from '@web/common/util.ts';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';
import { FormProvider, useForm } from 'react-hook-form';

import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';

const ConnectorsDashboard = () => {
  const methods = useForm<Connector>({ defaultValues: { type: PartType.CONNECTOR } });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  const columns: CustomColumnDef[] = [
    {
      header: 'Positions',
      renderFn: (part: Part) => {
        const connector = part as Connector;
        return <Text>{connector.insertArrangement?.cavities.length}</Text>;
      },
    },
    {
      header: 'Gender',
      renderFn: (part: Part) => {
        const connector = part as Connector;
        return <Text>{valueToLabel(connector.gender, config.parts.connector.genders)}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.CONNECTOR} columnDefs={columns} />
    </FormProvider>
  );
};

export default ConnectorsDashboard;
