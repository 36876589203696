import { Stack, Table, TableContainer, Tbody, Text } from '@chakra-ui/react';
import { isConnectionTarget } from '@web/apps/utils/design.ts';
import { Fragment, useRef } from 'react';

import { SelectedDesignPartHeader } from './components/SelectedDesignPartHeader.tsx';
import { WiringListHeader } from './components/WiringListHeader.tsx';
import { WiringListRow } from './components/WiringListRow/WiringListRow.tsx';
import { useWiringList, WiringListProvider } from './hooks/useWiringList.tsx';

const tableStyles = {
  borderCollapse: 'separate',
  borderSpacing: 0,
  p: '1px',
  th: { p: 0 },
  td: { p: 0 },
};

/**
 * WiringList component displays the selected design part and a list of connections.
 * @constructor
 */
const WiringListComponent = () => {
  const { selectedDesignPart, draftRow, hasActiveEdits, setIsKeyboardNav, wiringList } = useWiringList();
  const wiringListRef = useRef<HTMLDivElement>(null);

  const handleFocusChange = (newValue: boolean) => {
    if (wiringListRef.current && !hasActiveEdits) {
      newValue ? wiringListRef.current.focus() : wiringListRef.current.blur();
    }
  };

  const handleMouseEnter = () => handleFocusChange(true);

  const handleMouseLeave = () => handleFocusChange(false);

  const handleMouseMove = () => setIsKeyboardNav(false);

  if (!selectedDesignPart || !isConnectionTarget(selectedDesignPart)) {
    return <Text p={4}>No connection target selected. Select a valid part to view wiring list.</Text>;
  }

  return (
    <Stack
      ref={wiringListRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      h="full"
      gap={0}
    >
      {/* Fixed Header */}
      <SelectedDesignPartHeader designPart={selectedDesignPart} />

      {/* Fixed Table Header */}
      <Table layout="fixed" sx={tableStyles}>
        <WiringListHeader />
      </Table>

      {/* Scrollable Rows */}
      <TableContainer color="gray.500" h="full" overflowY="auto">
        <Table layout="fixed" sx={tableStyles}>
          <WiringListHeader showHeader={false} />
          <Tbody>
            {wiringList.map((row, index) => {
              const isDraftRow = draftRow === index;

              return (
                <Fragment key={row.keyId}>
                  {/* Regular row */}
                  <WiringListRow
                    rowId={index}
                    connectionPoint={row.connectionPoint}
                    connection={row.existingConnection}
                  />

                  {/* Draft row */}
                  {isDraftRow && <WiringListRow rowId="draft" connectionPoint={row.connectionPoint} />}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const WiringList = () => {
  return (
    <WiringListProvider>
      <WiringListComponent />
    </WiringListProvider>
  );
};

export default WiringList;
