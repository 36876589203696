import { ResolvedConnection, ResolvedConnectionPoint, UUID } from '@web/apps/types';
import { isConnectionTarget } from '@web/apps/utils/design.ts';
import { Option } from '@web/common/select.ts';
import { useMemo } from 'react';

import { useConnections } from '../../../hooks/useConnections.tsx';

export interface UseConnectionsData {
  connectionPoints: ResolvedConnectionPoint[];
  connections: ResolvedConnection[];
}

/**
 * useDestinationOptions hook returns a list of destination options for a given connection point. Valid destinations are
 * connection points that are connection targets and are different from the source connection point. The list is grouped
 * by unused, loopback, and used cavities.
 * @param connectionPoint
 * @param currentValue
 * @param injectedData
 */
export const useDestinationOptions = (
  connectionPoint: ResolvedConnectionPoint,
  currentValue?: UUID,
  injectedData?: UseConnectionsData,
) => {
  const { connectionPoints, connections } = useConnections();

  const data = useMemo(
    () => injectedData || { connections, connectionPoints },
    [connectionPoints, connections, injectedData],
  );

  const groupedOptions = useMemo(() => {
    // Create a set of used connection point IDs
    const usedConnectionPointIds = new Set();

    connections.forEach((conn) => {
      usedConnectionPointIds.add(conn.source?.id);
      usedConnectionPointIds.add(conn.destination?.id);
    });

    // Group connection points into used, loopback, and empty
    const empty: Option[] = [];
    const loopback: Option[] = [];
    const used: Option[] = [];

    data.connectionPoints
      .filter((cp) => isConnectionTarget(cp.designPart) && cp.id !== connectionPoint.id)
      .forEach((cp) => {
        const option: Option = {
          label: cp.displayName,
          value: cp.id,
        };

        const designPartMatches = cp.designPart.id === connectionPoint.designPart.id;

        if (usedConnectionPointIds.has(cp.id) && cp.id !== currentValue) {
          used.push(option);
        } else if (designPartMatches) {
          loopback.push(option);
        } else {
          empty.push(option);
        }
      });

    return [
      {
        label: 'Unused',
        options: empty,
      },
      {
        label: 'Loopback',
        options: loopback,
      },
      {
        label: 'Used',
        options: used,
      },
    ];
  }, [connections, data.connectionPoints, currentValue, connectionPoint.id, connectionPoint.designPart.id]);

  return { options: groupedOptions };
};
