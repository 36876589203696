import { Box, FormControl, FormLabel, HStack, Select } from '@chakra-ui/react';
import { GenericPart } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import { useFormContext } from 'react-hook-form';

interface Props {
  editing: boolean;
}

const ContactFields = ({ editing }: Props) => {
  const { register, getValues } = useFormContext<GenericPart>();

  return (
    <HStack spacing={10}>
      <Box flex={1}>
        <EditableFormText editing={editing} label="Generic Part Type" value={getValues('subtype') ?? ''}>
          <TextInput label="Generic Part Type" formControlProps={{ isDisabled: !editing }} {...register('subtype')} />
        </EditableFormText>
      </Box>
      <Box flex={1}>
        <EditableFormText editing={editing} label="Generic Part Unit" value={getValues('genericPartUnit') ?? ''}>
          <FormControl>
            <FormLabel>Generic Part Unit</FormLabel>
            <Select isDisabled={!editing} {...register('genericPartUnit')}>
              <option value="count">Count</option>
              <option value="length">Length</option>
            </Select>
          </FormControl>
        </EditableFormText>
      </Box>
    </HStack>
  );
};

export default ContactFields;
