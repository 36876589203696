import { Box, HStack } from '@chakra-ui/react';
import { NoteType, UUID } from '@web/apps/types';
import { Handle, Node, NodeProps, Position, useUpdateNodeInternals } from '@xyflow/react';
import { useEffect, useMemo } from 'react';

import { useDesignBuildNotes } from '../../../../../hooks/useDesignBuildNotes';
import { defaultLayoutConfig } from '../../../config.ts';
import { useCalculateTargetDirection } from '../../../hooks/notes/useCalculateTargetDirection.ts';
import { useLayoutElementBomItems } from '../../../hooks/notes/useLayoutElementBomItems.ts';
import { LayoutBomNote } from './LayoutBomNote.tsx';
import { LayoutFlagNote } from './LayoutFlagNote.tsx';

export type NoteGroupNodeData = {
  targetElementId?: UUID;
};

export const defaultNoteGroupNodeData: NoteGroupNodeData = {
  targetElementId: '',
};

export type NoteGroupNodeType = Node<NoteGroupNodeData>;

/**
 * Note Group point node component.
 * @param props
 * @constructor
 */
export const NoteGroupNode = ({ id, dragging, data: { targetElementId }, selected }: NodeProps<NoteGroupNodeType>) => {
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);
  const updateNodeInternals = useUpdateNodeInternals();

  const { horizontal: horizontalPosition } = useCalculateTargetDirection(id, targetElementId);
  const flexDirection = horizontalPosition === Position.Right ? 'row' : 'row-reverse';

  useEffect(() => {
    updateNodeInternals(id);
  }, [updateNodeInternals, id, horizontalPosition]);

  const flagNotesForNoteGroup = useMemo(
    () => flagNotes.filter((note) => note.noteGroupNodeIds.includes(id)),
    [flagNotes, id],
  );

  const bomItems = useLayoutElementBomItems(targetElementId);

  return (
    <Box
      key={horizontalPosition}
      position="relative"
      borderRadius="lg"
      borderWidth={selected ? 1 : 0}
      borderColor={defaultLayoutConfig.selectedNodeColor}
      padding={1}
    >
      <HStack gap={0} flexDirection={flexDirection}>
        {flagNotesForNoteGroup.map((flagNote) => (
          <LayoutFlagNote key={flagNote.id} flagNote={flagNote} disableTooltip={dragging} />
        ))}
        {bomItems.map((bomItem) => (
          <LayoutBomNote key={bomItem.id} bomItem={bomItem} disableTooltip={dragging} />
        ))}
      </HStack>
      <Box visibility="hidden">
        <Handle type="source" position={horizontalPosition} />
      </Box>
    </Box>
  );
};

export default NoteGroupNode;
