import { MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { useUpdateLayoutNodeMutation } from '@web/apps/Design/api/layout-nodes-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';

import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { RelationalConnectorNodeType } from '../../components/nodes/ConnectorNode/RelationalConnectorNode.tsx';
import { NodeType } from '../../relational_types.ts';
import { useNoteGroupBuildNotes } from '../useNoteGroupBuildNotes.tsx';
import { useBuildNoteActions } from './useBuildNoteActions.tsx';

/**
 * Hook for connector actions.
 */
export const useRelationalConnectorActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as RelationalConnectorNodeType;

  const designId = useDesignId();
  const noteGroupBuildNotes = useNoteGroupBuildNotes(node?.id);
  const { buildNoteActions } = useBuildNoteActions({
    closeMenu,
    noteGroupBuildNotes,
    targetId: node?.id,
    targetType: 'LayoutNode',
  });
  const { mutateAsync: updateLayoutNode } = useUpdateLayoutNodeMutation();

  const rotateLock = node?.data?.rotateLock ?? false;
  const angle = node?.data?.angle.toString() ?? '0';

  const onRotateLock = (value: boolean) => {
    updateLayoutNode({
      designId,
      layoutNodeId: node.id,
      data: {
        rotateLocked: value,
      },
    });
    closeMenu();
  };

  const onChangeDirection = (angle: number) => {
    updateLayoutNode({
      designId: designId,
      layoutNodeId: node.id,
      data: {
        angle: angle,
      },
    });
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        {!rotateLock ? (
          <MenuItem onClick={() => onRotateLock(true)}>Lock rotation</MenuItem>
        ) : (
          <MenuItem onClick={() => onRotateLock(false)}>Unlock rotation</MenuItem>
        )}
      </MenuGroup>
      {rotateLock && (
        <>
          <MenuDivider />
          <MenuOptionGroup defaultValue={angle} title="Face connector:" type="radio">
            <MenuItemOption onClick={() => onChangeDirection(180)} value="180">
              Left
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(0)} value="0">
              Right
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(270)} value="270">
              Up
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(90)} value="90">
              Down
            </MenuItemOption>
          </MenuOptionGroup>
        </>
      )}
      <MenuDivider />
      {buildNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Connector,
  } as MenuActions;
};
