import { SystemStyleObject, Text } from '@chakra-ui/react';
import { findSelectedOption, GroupedOption, Option } from '@web/common/select.ts';
import { ControlProps, Props as ChakraReactSelectProps, Select, SelectInstance } from 'chakra-react-select';
import { KeyboardEvent, useRef } from 'react';

import { useWiringList } from '../../hooks/useWiringList.tsx';

interface Props extends ChakraReactSelectProps<Option, false, GroupedOption> {
  isInEditMode?: boolean;
  onEnterKeyPress?: () => void;
}

/**
 * A custom select component that allows for editing the selected value.
 * @param value
 * @param options
 * @param isInEditMode
 * @param onEnterKeyPress
 * @param onChange
 * @param onBlur
 * @param parentChakraStyles
 * @param rest
 * @constructor
 */
export const EditableCellSelect = ({
  value,
  options = [],
  isInEditMode,
  onEnterKeyPress,
  onChange,
  onBlur,
  chakraStyles: parentChakraStyles,
  ...rest
}: Props) => {
  const selectRef = useRef<SelectInstance<Option, false, GroupedOption>>(null);
  const { isSelectMenuOpen, setIsSelectMenuOpen } = useWiringList();

  const selectedOption = findSelectedOption((value as Option | null)?.value, options);

  const mergedChakraStyles = {
    ...parentChakraStyles,
    control: (provided: SystemStyleObject, state: ControlProps<Option, false, GroupedOption>) => ({
      ...provided,
      ...(parentChakraStyles?.control ? parentChakraStyles.control(provided, state) : {}),
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      _focus: {
        boxShadow: 'none',
        border: 'none',
      },
      paddingLeft: 2,
    }),
  };

  const handleBlur = (e: any) => {
    setIsSelectMenuOpen(false);
    onBlur?.(e);
  };

  const handleMenuOpen = () => {
    setIsSelectMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsSelectMenuOpen(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnterKeyPress && !isSelectMenuOpen) {
      onEnterKeyPress();
      return;
    }

    if (!isSelectMenuOpen) {
      const controlElement = selectRef.current?.controlRef;

      if (controlElement) {
        const cell = controlElement.closest('td');
        if (event.key === 'ArrowLeft' && cell?.previousElementSibling) {
          (cell.previousElementSibling as HTMLElement)?.focus();
          event.preventDefault();
        } else if (event.key === 'ArrowRight' && cell?.nextElementSibling) {
          (cell.nextElementSibling as HTMLElement)?.focus();
          event.preventDefault();
        }
      }
    }
  };

  return isInEditMode ? (
    <Select
      ref={selectRef}
      variant="unstyled"
      value={selectedOption}
      options={options}
      onChange={onChange}
      onBlur={handleBlur}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onKeyDown={handleKeyDown}
      isClearable
      formatGroupLabel={(data) => <Text textTransform="uppercase">{data.label}</Text>}
      menuPlacement="auto"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      chakraStyles={mergedChakraStyles}
      {...rest}
    />
  ) : (
    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" px={2}>
      {selectedOption?.label || '-'}
    </Text>
  );
};
