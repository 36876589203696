import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { NodeType } from '../../../Layout/types.ts';
import { RelationalNoteGroupNodeType } from '../../components/nodes/NoteGroupNode/RelationalNoteGroupNode.tsx';
import { useNoteGroupBuildNotes } from '../useNoteGroupBuildNotes.tsx';
import { useBuildNoteActions } from './useBuildNoteActions.tsx';

/**
 * Hook for note group actions.
 */
export const useRelationalNoteGroupActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as RelationalNoteGroupNodeType;

  const noteGroupBuildNotes = useNoteGroupBuildNotes(node?.id);
  const { buildNoteActions } = useBuildNoteActions({
    closeMenu,
    noteGroupBuildNotes,
    noteGroupId: node?.id,
    targetType: 'LayoutNode',
  });

  const menu = renderMenu(buildNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.NoteGroup,
  } as MenuActions;
};
