import { useQuery } from '@tanstack/react-query';
import { Tenant, Tenants, User, Users } from '@web/apps/types';

import { apiGetConfiguration, apiGetTenant, apiGetTenants, apiGetUser, apiGetUsers } from '../api/admin-api';

export const adminUsersCacheKey = (page: string, query: string | null) =>
  ['admin', 'users', page, query].filter(Boolean);

export const adminUserCacheKey = (userId: string) => ['admin', 'users', userId];

export const adminTenantsCacheKey = (page: string, query?: string | null) =>
  ['admin', 'tenants', page, query].filter(Boolean);

export const adminTenantCacheKey = (tenantId: string) => ['admin', 'tenants', tenantId];

export const configurationCacheKey = (configuration: string) => ['admin', 'tenants', configuration];

export const useAdminUsers = (page: string, query: string | null) =>
  useQuery({
    queryKey: adminUsersCacheKey(page, query),
    queryFn: () => apiGetUsers(page, { query: query }).then((data) => data as Users),
  });
export const useAdminUser = (userId: string) =>
  useQuery({
    queryKey: adminUserCacheKey(userId),
    queryFn: () => apiGetUser(userId).then((data) => data as User),
  });

export const useAdminTenants = (page: string, query?: string | null) =>
  useQuery({
    queryKey: adminTenantsCacheKey(page, query),
    queryFn: () => apiGetTenants(page, { query: query }).then((data) => data as Tenants),
  });

export const useAdminTenant = (tenantId: string) =>
  useQuery({
    queryKey: adminTenantCacheKey(tenantId),
    queryFn: () => apiGetTenant(tenantId).then((data) => data as Tenant),
  });

export const useConfiguration = () =>
  useQuery({
    queryKey: configurationCacheKey('configuration'),
    queryFn: () => apiGetConfiguration(),
    staleTime: Infinity,
  });
