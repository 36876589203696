import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  apiDeleteInsertArrangement,
  apiDeletePart,
  apiGetInsertArrangement,
  apiGetInsertArrangements,
  apiGetPart,
  apiGetParts,
  apiPostInsertArrangement,
  apiPostPart,
  apiPutInsertArrangement,
  apiPutPart,
} from '@web/api/parts-api.ts';
import { PartType, UpdatePartParams } from '@web/apps/types';

const partCacheKey = (partId?: string) => ['part', partId].filter(Boolean);
const partsCacheKey = (partType?: PartType, page?: string, query?: string | null, pageSize?: number) =>
  ['parts', partType, page, query, pageSize].filter(Boolean);
const insertArrangementCacheKey = (insertArrangementId?: string) =>
  ['insert-arrangement', insertArrangementId].filter(Boolean);
const insertArrangementsCacheKey = (query?: string, page?: string, pageSize?: number) =>
  ['insert-arrangements', query, page, pageSize].filter(Boolean);

export const usePart = (partId?: string) =>
  useQuery({
    queryKey: partCacheKey(partId),
    queryFn: () => {
      if (partId) return apiGetPart(partId);
    },
  });

export const useParts = (partType?: PartType, query?: string | null, page?: string, pageSize?: number) =>
  useQuery({
    queryKey: partsCacheKey(partType, page, query, pageSize),
    queryFn: () => apiGetParts(partType, query, page, pageSize),
  });

export const useCreatePart = (partType?: PartType) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['post-part'],
    mutationFn: (part: UpdatePartParams) => apiPostPart(part),
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: partsCacheKey(partType) });
    },
  });
};

export const useUpdatePart = (partType?: PartType) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['put-part'],
    mutationFn: (part: UpdatePartParams) => apiPutPart(part),
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: partsCacheKey(partType) });
    },
  });
};

export const useDeletePart = (partType?: PartType) => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['delete-part'],
    mutationFn: apiDeletePart,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: partsCacheKey(partType) });
    },
  });
};

export const useInsertArrangement = (insertArrangementId?: string) =>
  useQuery({
    queryKey: insertArrangementCacheKey(insertArrangementId),
    queryFn: () => {
      if (insertArrangementId) return apiGetInsertArrangement(insertArrangementId);
    },
  });

export const useInsertArrangements = (query?: string, page?: string, pageSize?: number) =>
  useQuery({
    queryKey: insertArrangementsCacheKey(query, page, pageSize),
    queryFn: () => apiGetInsertArrangements(query, page, pageSize),
  });

export const useCreateInsertArrangement = () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['post-insert-arrangement'],
    mutationFn: apiPostInsertArrangement,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: insertArrangementsCacheKey() });
    },
  });
};

export const useUpdateInsertArrangement = () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['put-insert-arrangement'],
    mutationFn: apiPutInsertArrangement,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: insertArrangementsCacheKey() });
    },
  });
};

export const useDeleteInsertArrangement = () => {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['delete-insert-arrangement'],
    mutationFn: apiDeleteInsertArrangement,
    onSettled: async () => {
      await client.invalidateQueries({ queryKey: insertArrangementsCacheKey() });
    },
  });
};
