import { defaultLayoutConfig } from '@web/apps/Design';
import { useUpdateDesignMutation } from '@web/apps/Design/api/designs-api.ts';
import { Graph, NoteType } from '@web/apps/types';
import { Edge, Node, useReactFlow } from '@xyflow/react';

import { useConnections } from '../../../hooks/useConnections.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes.tsx';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import { GraphOperation, operationsMap } from '../graph/Operations.ts';
import { getUpdatedGraph } from '../utils/updateGraph.ts';

/**
 * Hook for building the layout, and executing operations on the layout.
 */
export const useLayoutBuilder = () => {
  const { designId, isViewOnly } = useDesign();
  const { connections } = useConnections();
  const { designParts } = useDesignParts();
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);

  // Get the ReactFlow API methods
  const { getNodes, setNodes, getEdges, setEdges, getViewport } = useReactFlow();

  // Get the update design mutation and error handler
  const { mutate: updateDesign, mutateAsync: updateDesignAsync } = useUpdateDesignMutation();

  // Save the layout to design.layoutData
  const saveLayout = (nodes: Node[], edges: Edge[]) => {
    if (isViewOnly) {
      console.debug('Skipping save layout, design is locked or view only');
      return;
    }

    const layoutData = { nodes, edges, viewport: getViewport() };
    console.debug('Saving layout to design.layoutData', layoutData);
    updateDesign({
      designId,
      data: {
        layoutData,
      },
    });
  };

  // Reset the layout to the default state
  const resetLayout = async () => {
    if (isViewOnly) {
      return;
    }

    console.debug('Resetting layout and reloading the page');
    const layoutData = { nodes: [], edges: [], viewport: defaultLayoutConfig.defaultViewport };
    await updateDesignAsync({
      designId,
      data: {
        layoutData,
      },
    });
    window.location.reload();
  };

  /**
   * Helper to update the graph with the given nodes and edges.
   * @param nodes
   * @param edges
   */
  const updateLayout = (nodes: Node[] = getNodes(), edges: Edge[] = getEdges()): Graph => {
    console.debug('Starting update', { nodes, edges });

    const updatedGraph = getUpdatedGraph({ nodes, edges, connections, designParts, flagNotes });

    // Update the ReactFlow state with the finalized graph.
    console.debug('Final graph state before saving', updatedGraph);
    setNodes(updatedGraph.nodes);
    setEdges(updatedGraph.edges);
    saveLayout(updatedGraph.nodes, updatedGraph.edges);

    return updatedGraph;
  };

  /**
   * Execute the given graph operation.
   * @param operation - The operation to execute.
   */
  const executeGraphOperation = <T extends GraphOperation>(operation: T): Graph => {
    const { type, params } = operation;

    console.debug(`Preparing to execute graph operation: ${type}`, params);

    const OperationClass = operationsMap[type];

    if (!OperationClass) {
      console.error('Unsupported operation type:', type);
      throw new Error(`Unsupported operation type: ${type}`);
    }

    const operationInstance = new OperationClass();
    const graph: Graph = {
      nodes: getNodes(),
      edges: getEdges(),
    };

    console.debug(`Executing operation: ${type}`, params, graph);

    // Execute the operation and get the updated graph
    // @ts-expect-error
    const updatedGraph = operationInstance.execute(graph, operation);

    console.debug(`Finished operation: ${type}`, updatedGraph);

    // Determine if a rebuild is required, then update the graph state
    const requiresRebuild = operationInstance.requiresRebuild;
    if (requiresRebuild) {
      console.debug('Operation requires graph rebuild, updating graph', updatedGraph);
      updateLayout(updatedGraph.nodes, updatedGraph.edges);
    } else {
      console.debug('Operation does not require rebuild, saving graph state', updatedGraph);
      setNodes(updatedGraph.nodes);
      setEdges(updatedGraph.edges);
      saveLayout(updatedGraph.nodes, updatedGraph.edges);
    }

    return updatedGraph;
  };

  return {
    saveLayout,
    resetLayout,
    updateLayout,
    executeGraphOperation,
  };
};
