import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { CableData, DesignPart } from '@web/apps/types';

interface Props {
  cable: DesignPart & {
    partData: CableData;
  };
}

export const CableDetails = ({ cable }: Props) => (
  <HStack w="full" p={2} spacing={4}>
    <Text minW={12}>{cable.name}</Text>
    <Text minW={24}>{cable.partData.partNumber}</Text>
    <Text>{cable.partData.partWires.map((wire) => (wire.wire?.gauge ? `${wire.wire.gauge} AWG` : '')).join(', ')}</Text>
    <Spacer />
    <Box boxSize={4} bg={cable.partData.jacket || 'white'} borderWidth={1} borderColor="gray.300" />
  </HStack>
);
