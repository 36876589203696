import {
  Connection,
  ConnectionPoint,
  ConnectionPointMap,
  DesignPart,
  ResolvedConnection,
  ResolvedConnectionPoint,
  UUID,
} from '@web/apps/types';

import { normalizeSourceTargetIds } from './design';

/**
 * Resolve a connection from a connection object and a map of connection points.
 * @param connection
 * @param connectionPointsById
 */
export const resolveConnection = (
  connection: Connection,
  connectionPointsById: ConnectionPointMap,
): ResolvedConnection => {
  // Helper to safely resolve a connection point or return null
  const resolvePoint = (id: UUID | null) => (id ? connectionPointsById[id] || null : null);

  const source = resolvePoint(connection.sourceId);
  const destination = resolvePoint(connection.destinationId);

  const sourceDesignPart = source?.designPart;
  const destinationDesignPart = destination?.designPart;

  const bundleId =
    sourceDesignPart && destinationDesignPart
      ? normalizeSourceTargetIds(sourceDesignPart.id, destinationDesignPart.id).key
      : sourceDesignPart?.id || destinationDesignPart?.id || '';

  return {
    id: connection.id,
    source,
    destination,
    conductor: resolvePoint(connection.conductorId),
    signalName: connection.signalName,
    sourceContact: resolvePoint(connection.sourceContactId),
    destinationContact: resolvePoint(connection.destinationContactId),
    bundleId,
    original: connection,
  };
};

/**
 * Resolve a list of connections from a list of connection objects and a map of connection points.
 * @param connections
 * @param connectionPointsById
 */
export const resolveConnections = (
  connections: Connection[],
  connectionPointsById: ConnectionPointMap,
): ResolvedConnection[] => {
  return connections.map((connection) => {
    return resolveConnection(connection, connectionPointsById);
  });
};

/**
 * Resolve a connection point from a connection point object and a design part.
 * @param connectionPoint
 * @param designPart
 */
export const resolveConnectionPoint = (
  connectionPoint: ConnectionPoint,
  designPart: DesignPart,
): ResolvedConnectionPoint => {
  return {
    id: connectionPoint.id,
    name: connectionPoint.name,
    displayName: `${designPart.name}.${connectionPoint.name}`,
    designPart,
    original: connectionPoint,
  };
};

/**
 * Resolve a list of connection points from a list of connection point objects and a list of design parts.
 * @param designParts
 */
export const resolveConnectionPoints = (designParts: DesignPart[]): ResolvedConnectionPoint[] => {
  const result: ResolvedConnectionPoint[] = [];
  designParts.forEach((part) => {
    part.connectionPoints?.forEach((cp) => {
      result.push(resolveConnectionPoint(cp, part));
    });
    part.contacts?.forEach((contact, index) => {
      result.push(resolveConnectionPoint({ id: contact.id, name: contact.name, position: index }, contact));
    });
  });
  return result;
};
