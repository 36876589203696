import { Connection } from '@web/apps/types';

import { Fields } from './types.ts';

export const defaultInitialRowState: Fields = {
  destinationId: '',
  conductorId: '',
  signalName: '',
  sourceContactId: '',
  destinationContactId: '',
};

export const getRowState = (connection: Connection | undefined) => {
  if (connection) {
    return {
      destinationId: connection.destinationId ?? '',
      conductorId: connection.conductorId ?? '',
      signalName: connection.signalName ?? '',
      sourceContactId: connection.sourceContactId ?? '',
      destinationContactId: connection.destinationContactId ?? '',
    };
  }
  return defaultInitialRowState;
};
