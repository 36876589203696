import { apiGetManufacturers } from '@web/api/manufacturers-api.ts';
import { Manufacturer } from '@web/apps/types';
import CreatableTypeAheadSelectInput from '@web/components/form/CreatableTypeAheadSelectInput.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import { useCreateManufacturer, useManufacturers } from '@web/queries/manufacturers.ts';
import { SingleValue } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  editing: boolean;
}

const toOption = (manufacturer: Manufacturer) => ({ label: manufacturer.name, value: manufacturer.id });

const ManufacturerDropdownSingleValue = ({ editing }: Props) => {
  const { control } = useFormContext();
  const { field } = useController({ name: 'manufacturer', control });
  const { data: manufacturers = [] } = useManufacturers();

  const createManufacturer = useCreateManufacturer();

  const handleSearchManufacturers = async (inputValue: string) => {
    const res = await apiGetManufacturers(inputValue);
    if (res) {
      return res.data.map(toOption);
    }
    return [];
  };

  const handleCreateManufacturer = async (manufacturerName: string) => {
    const res = await createManufacturer.mutateAsync({ name: manufacturerName });
    if (res) {
      return { label: res.name, value: res.id };
    }
  };

  const handleOnChange = (newOption: SingleValue<{ label: string; value: string } | undefined>) => {
    if (newOption) {
      field.onChange({ name: newOption.label, id: newOption.value });
    }
  };

  return (
    <EditableFormText editing={editing} label="Manufacturer" value={field.value ? field.value.name : ''}>
      <CreatableTypeAheadSelectInput
        label="Manufacturer"
        editing={editing}
        value={field.value ? toOption(field.value) : null}
        defaultOptions={manufacturers.map(toOption)}
        onChange={handleOnChange}
        onCreateOption={handleCreateManufacturer}
        loadOptions={handleSearchManufacturers}
        isMulti={false}
      />
    </EditableFormText>
  );
};

export default ManufacturerDropdownSingleValue;
