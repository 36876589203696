import { DesignPart, isCableDesignPart, isWireDesignPart } from '@web/apps/types';

import { CableDetails } from './CableDetails';
import { WireDetails } from './WireDetails';

interface Props {
  conductor: DesignPart;
}

export const ConductorDetails = ({ conductor }: Props) => {
  if (isCableDesignPart(conductor)) {
    return <CableDetails cable={conductor} />;
  } else if (isWireDesignPart(conductor)) {
    return <WireDetails wire={conductor} />;
  }
  return null;
};
