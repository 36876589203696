import { TurretHead } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import { useFormContext } from 'react-hook-form';

interface Props {
  editing: boolean;
}

const TurretHeadFields = ({ editing }: Props) => {
  const { register, getValues } = useFormContext<TurretHead>();

  return (
    <EditableFormText label="Name" value={getValues('name')} editing={editing}>
      <TextInput label="Name" formControlProps={{ isDisabled: !editing }} {...register('name')} />
    </EditableFormText>
  );
};

export default TurretHeadFields;
