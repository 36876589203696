import { MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { useUpdateLayoutEdgeMutation } from '@web/apps/Design/api/layout-edges-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';

import {
  defaultMeasurementEdgeData,
  MeasurementEdgeType,
} from '../../../Layout/components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { MenuActions } from '../../../Layout/hooks/actions/actionsTypes.ts';
import { useActionsBase } from '../../../Layout/hooks/actions/useActionsBase.tsx';
import { EdgeType } from '../../../Layout/types.ts';

/**
 * Hook for measurement actions.
 */
export const useRelationalMeasurementActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const edge = element as MeasurementEdgeType;
  const designId = useDesignId();
  const { mutate: updateLayoutEdge } = useUpdateLayoutEdgeMutation();

  // Data properties
  const flipped = edge?.data?.flipped ?? false;
  const distanceFromEdge =
    edge?.data?.distanceFromEdge.toString() ?? defaultMeasurementEdgeData.distanceFromEdge.toString();

  const onFlip = () => {
    updateLayoutEdge({ designId, layoutEdgeId: edge.id, data: { flipped: !flipped } });
    closeMenu();
  };

  const onExtend = (distance: number) => {
    updateLayoutEdge({ designId, layoutEdgeId: edge.id, data: { distanceFromEdge: distance } });
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onFlip}>Flip</MenuItem>
      </MenuGroup>
      <MenuDivider />
      <MenuOptionGroup defaultValue={distanceFromEdge} title="Step out:" type="radio">
        <MenuItemOption onClick={() => onExtend(30)} value="30">
          1x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(60)} value="60">
          2x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(90)} value="90">
          3x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(150)} value="150">
          5x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(240)} value="240">
          8x
        </MenuItemOption>
      </MenuOptionGroup>
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: EdgeType.MeasurementEdge,
  } as MenuActions;
};
