import { HStack, MenuItemOption, MenuOptionGroup, Text } from '@chakra-ui/react';
import { Part, PartType, UUID } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useMemo } from 'react';

import { usePartSearchModal } from '../../../../hooks/usePartSearchModal.tsx';
import { InnermostOverwrapIcon } from '../../../../icons/InnermostOverwrapIcon.tsx';
import { OutermostOverwrapIcon } from '../../../../icons/OutermostOverwrapIcon.tsx';
import AddOverwrapButton from '../../components/AddOverwrapButton.tsx';
import { isSegmentEdge } from '../../types.ts';
import { useLayoutBuilder } from '../useLayoutBuilder.tsx';

/**
 * Hook to add a new overwrap (by opening Part Search Modal), or toggle an existing overwrap.
 * @param elementId
 */
export const useOverwrapActions = (elementId?: UUID) => {
  const { getEdge } = useReactFlow();
  const { openModal, closeModal } = usePartSearchModal();
  const { executeGraphOperation } = useLayoutBuilder();

  // Get overwraps for the segment
  const getOverwraps = useCallback(() => {
    const edge = elementId ? getEdge(elementId) : undefined;
    return edge && isSegmentEdge(edge) ? edge.data.overwraps : [];
  }, [elementId, getEdge]);

  // Add an overwrap to the segment
  const addOverwrapToSegment = useCallback(
    (part: Part) => {
      if (!elementId) return;
      executeGraphOperation({
        type: 'UpdateEdgeData',
        params: {
          edgeId: elementId,
          data: { overwraps: [...getOverwraps(), part.partNumber] },
        },
      });
      closeModal();
    },
    [closeModal, elementId, executeGraphOperation, getOverwraps],
  );

  // Open the part search modal to add an overwrap to the segment
  const handleAddOverwrap = useCallback(() => {
    openModal({
      initialPartTypeFilters: [PartType.OVERWRAP],
      filtersDisabled: true,
      renderSearchResultAction: (part: Part) => {
        return <AddOverwrapButton part={part} onPartAdded={() => addOverwrapToSegment(part)} />;
      },
    });
  }, [openModal, addOverwrapToSegment]);

  // Toggle an overwrap on the segment
  const onToggleOverwrap = useCallback(
    (index: number) => {
      if (!elementId) return;

      // Get the current overwraps
      const overwraps = getOverwraps();

      // If the index is out of bounds, return early
      if (index < 0 || index >= overwraps.length) return;

      // Toggle: Remove only the overwrap at the specified index
      const updatedOverwraps = overwraps.filter((_, i) => i !== index);

      executeGraphOperation({
        type: 'UpdateEdgeData',
        params: {
          edgeId: elementId,
          data: {
            overwraps: updatedOverwraps,
          },
        },
      });
    },
    [elementId, executeGraphOperation, getOverwraps],
  );

  // Menu options for toggling overwraps
  const overwrapActions = useMemo(() => {
    const overwraps = getOverwraps();
    const showIcons = overwraps.length > 1;

    return (
      <MenuOptionGroup title="Overwraps" type="checkbox" value={overwraps}>
        {overwraps.length > 0 ? (
          overwraps.map((overwrap, index) => (
            <MenuItemOption key={index} value={overwrap} onClick={() => onToggleOverwrap(index)}>
              <HStack spacing={4}>
                <Text flex={1} maxW={52} isTruncated>
                  {overwrap}
                </Text>
                <Text>
                  {showIcons && index === 0 ? (
                    <HStack align="center">
                      <InnermostOverwrapIcon />
                      <Text>Innermost</Text>
                    </HStack>
                  ) : null}
                  {showIcons && index === overwraps.length - 1 ? (
                    <HStack align="center">
                      <OutermostOverwrapIcon />
                      <Text>Outermost</Text>
                    </HStack>
                  ) : null}
                </Text>
              </HStack>
            </MenuItemOption>
          ))
        ) : (
          <MenuItemOption isDisabled>No Overwraps</MenuItemOption>
        )}
      </MenuOptionGroup>
    );
  }, [getOverwraps, onToggleOverwrap]);

  return { handleAddOverwrap, overwrapActions };
};
