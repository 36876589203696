import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { AlternateDesignPart, BOMItem, UUID } from '@web/apps/types';
import { useMemo } from 'react';

/**
 * Hook to generate a Bill of Materials (BOM) from a design.
 * @param designId
 */
export const useBOM = (designId: UUID) => {
  const { data: design, isLoading, isSuccess, error } = useDesignQuery(designId);

  // Memoize the BOM generation based on changes to the design
  const bom = useMemo(() => {
    if (!design) return [] as BOMItem[];
    return design.bom.map((bom) => {
      const part = design.designParts.find((designPart) => designPart.partData.partNumber === bom.partNumber);
      const alternates = part
        ? design.alternateDesignParts.filter((alternate) => alternate.partId === part.partData.id)
        : [];
      return {
        ...bom,
        manufacturer: part?.partData.manufacturers?.[0]?.name || '',
        alternates,
      };
    });
  }, [design]);

  const designParts = useMemo(() => design?.designParts, [design]);

  // Memoize the BOM CSV generation based on changes to the BOM
  const bomCSV = useMemo(() => {
    return bom.map((item: BOMItem) => ({
      ID: item.id,
      Type: item.type,
      Qty: item.quantity,
      Unit: item.unit,
      'Part Number': item.partNumber,
      Usage: item.usage,
      Description: item.description,
      Manufacturer: item.manufacturer,
      Alts: item.alternates.map((alternate: AlternateDesignPart) => alternate.alternatePart.partNumber).join(', '),
    }));
  }, [bom]);

  return { bom, bomCSV, designParts, isLoading, isSuccess, error };
};
