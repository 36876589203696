import { Box, Flex, HStack, IconButton, Link, Tooltip, UnorderedList } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { MdLogout } from 'react-icons/md';
import { useMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

import { AuthenticatedTemplate, EmployeeTemplate } from '../auth/RenderTemplates';
import Logo from '../svg/Logo';

const LogoWrapper = () => (
  <NavLink to={RouteNames.ROOT}>
    <Box mx={4}>
      <Logo />
    </Box>
  </NavLink>
);

export default function TopbarNav() {
  const isStaging = import.meta.env.VITE_ENVIRONMENT === 'staging';
  const isDesignIndexPage = useMatch(RouteNames.DESIGNS.INDEX);
  const isDesignsPage = useMatch(RouteNames.DESIGNS.DESIGNS);
  const isDesignPartPage = useMatch(RouteNames.DESIGNS.DESIGN_PART);

  return (
    <Flex
      padding="7px"
      alignItems="center"
      width="full"
      borderBottom="1px"
      borderColor="gray.300"
      backgroundColor={isStaging ? 'red.700' : 'gray.700'}
    >
      <LogoWrapper />
      <Flex flex={1}>
        <UnorderedList listStyleType="none">
          <HStack spacing={8} mt={4} mb={4}>
            <EmployeeTemplate>
              <li>
                <NavbarLink
                  text="Designs"
                  to={RouteNames.DESIGNS.DESIGNS}
                  showActive={!!(isDesignIndexPage || isDesignsPage || isDesignPartPage)}
                />
              </li>
              <li>
                <NavbarLink text="Parts Library" to={RouteNames.PARTS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Tools Library" to={RouteNames.TOOLS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Tenants" to={RouteNames.TENANTS.INDEX} />
              </li>
              <li>
                <NavbarLink text="Users" to={RouteNames.USERS.INDEX} />
              </li>
            </EmployeeTemplate>
          </HStack>
        </UnorderedList>
      </Flex>
      <Flex>
        <AuthenticatedTemplate>
          <HStack>
            <NavLink to={RouteNames.SIGN_OUT}>
              <Tooltip label="Sign Out">
                <IconButton
                  aria-label="Sign Out"
                  icon={<MdLogout size={18} />}
                  variant="unstyled"
                  data-cy="signout-button"
                  color="white"
                />
              </Tooltip>
            </NavLink>
          </HStack>
        </AuthenticatedTemplate>
      </Flex>
    </Flex>
  );
}

const NavbarLink = ({ text, to, showActive = false }: { text: string; to: string; showActive?: boolean }) => {
  const activeColor = 'white';
  const inactiveColor = 'gray.400';

  return (
    <Link
      as={NavLink}
      to={to}
      color={showActive ? activeColor : inactiveColor}
      fontWeight={showActive ? 'bold' : 'normal'}
      _hover={{ color: activeColor }}
      _activeLink={{ color: activeColor, fontWeight: 'bold' }}
    >
      {text}
    </Link>
  );
};
