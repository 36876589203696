import { HTMLChakraProps, Td, Text, Tr } from '@chakra-ui/react';
import { ResolvedConnection, ResolvedConnectionPoint } from '@web/apps/types';
import { stringToOption } from '@web/common/select.ts';
import { useStateWithDirtyFlag } from '@web/hooks/useStateWithDirtyFlag.ts';
import { useCallback, useRef } from 'react';

import { useConnections } from '../../../../hooks/useConnections.tsx';
import { useConductorOptions } from '../../hooks/useConductorOptions.tsx';
import { useDestinationContactOptions } from '../../hooks/useDestinationContactOptions.tsx';
import { useDestinationOptions } from '../../hooks/useDestinationOptions.tsx';
import { useHandleConductorIdChange } from '../../hooks/useHandleConductorChange.ts';
import { useSaveOnBlurWiringListRow } from '../../hooks/useSaveOnBlurWiringListRow.ts';
import { useSourceContactOptions } from '../../hooks/useSourceContactOptions.tsx';
import { useWiringList } from '../../hooks/useWiringList.tsx';
import { useWiringListRowActions } from '../../hooks/useWiringListRowActions.tsx';
import { WiringListSelectCell } from '../WiringListCell/WiringListSelectCell.tsx';
import { WiringListTextCell } from '../WiringListCell/WiringListTextCell.tsx';
import { getRowState } from '../WiringListRow/state.ts';
import { FieldKey, RowId } from '../WiringListRow/types.ts';
import { WiringListRowActions } from './WiringListRowActions.tsx';

interface Props extends Omit<HTMLChakraProps<'tr'>, 'children'> {
  rowId: RowId;
  connectionPoint: ResolvedConnectionPoint;
  connection?: ResolvedConnection;
}

/**
 * A table row element for the wiring list table. This component is responsible for rendering the connection row data.
 * @param id
 * @param connectionPoint
 * @param rest
 * @constructor
 */
export const WiringListRow = ({ rowId, connectionPoint, connection, ...rest }: Props) => {
  const { connectionPointsById } = useConnections();
  const { isFocusedRow, isEditing, isKeyboardNav, setFocusedRow, setFocusedField, setDraftRow } = useWiringList();
  const rowRef = useRef<HTMLTableRowElement>(null);

  // State to manage connection data
  const initialState = getRowState(connection?.original);
  const { state, isDirty, setStateWithDirtyFlag, resetState } = useStateWithDirtyFlag(initialState);

  // Options for dropdowns
  const { options: destinationOptions } = useDestinationOptions(connectionPoint, connection?.destination?.id ?? '');
  const { options: conductorOptions } = useConductorOptions(connection?.conductor?.id ?? '');
  const { options: sourceContactOptions } = useSourceContactOptions();
  const { options: destinationContactOptions } = useDestinationContactOptions(state.destinationId);

  const { enterEditingMode, exitEditingMode, saveRow, saveRowOnBlur, isSaving, clearRow, isClearing } =
    useWiringListRowActions(rowId, connectionPoint, connection, state, isDirty, resetState);

  const handleConductorIdChange = useHandleConductorIdChange(
    setStateWithDirtyFlag,
    connectionPoint,
    connectionPointsById,
    sourceContactOptions,
    destinationContactOptions,
  );

  const handleFieldChange = useCallback(
    (fieldKey: FieldKey, value: string) => {
      if (fieldKey === 'conductorId') {
        handleConductorIdChange(value);
      } else {
        setStateWithDirtyFlag((prevState) => ({ ...prevState, [fieldKey]: value }));
      }
    },
    [handleConductorIdChange, setStateWithDirtyFlag],
  );

  const handleAddDraftRow = () => {
    setDraftRow(rowId);
    setFocusedRow('draft');
    setFocusedField('destinationId');
  };

  const handleMouseEnter = () => {
    if (isKeyboardNav) return;
    setFocusedRow(rowId);
  };

  const handleMouseLeave = () => {
    if (isKeyboardNav) return;
    setFocusedRow(null);
  };

  const sharedCellProps = {
    rowId,
    onCellClick: enterEditingMode,
    onSave: saveRow,
    onFieldChange: handleFieldChange,
  } as const;

  // Save when the row loses focus
  useSaveOnBlurWiringListRow(isEditing(rowId), isDirty, saveRowOnBlur, state);

  return (
    <Tr
      ref={rowRef}
      h={14}
      role="group"
      bg={isEditing(rowId) ? 'yellow.100' : isFocusedRow(rowId) ? 'gray.50' : 'transparent'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <Td onMouseEnter={() => setFocusedField(null)}>
        <Text pl={4}>{connectionPoint.name}</Text>
      </Td>
      <WiringListSelectCell
        fieldKey={'destinationId'}
        value={stringToOption(state.destinationId, destinationOptions)}
        options={destinationOptions}
        {...sharedCellProps}
      />
      <WiringListSelectCell
        fieldKey={'conductorId'}
        value={stringToOption(state.conductorId, conductorOptions)}
        options={conductorOptions}
        {...sharedCellProps}
      />
      <WiringListTextCell fieldKey={'signalName'} value={state.signalName} {...sharedCellProps} />
      <WiringListSelectCell
        fieldKey={'sourceContactId'}
        value={stringToOption(state.sourceContactId, sourceContactOptions)}
        options={sourceContactOptions}
        {...sharedCellProps}
      />
      <WiringListSelectCell
        fieldKey={'destinationContactId'}
        value={stringToOption(state.destinationContactId, destinationContactOptions)}
        options={destinationContactOptions}
        {...sharedCellProps}
      />
      <Td textAlign="right" onMouseEnter={() => setFocusedField(null)}>
        <WiringListRowActions
          rowId={rowId}
          state={state}
          connection={connection}
          onEdit={enterEditingMode}
          onSave={saveRow}
          onClear={clearRow}
          onCancel={exitEditingMode}
          onAddDraftRow={handleAddDraftRow}
          isSaving={isSaving}
          isClearing={isClearing}
        />
      </Td>
    </Tr>
  );
};
