import { Box, HStack, Text } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';

import ConnectorIcon from '../../../components/ConnectorIcon';

interface Props {
  designPart?: DesignPart;
}

/**
 * SelectedDesignPartHeader component displays the name and part number of the selected design part.
 */
export const SelectedDesignPartHeader = ({ designPart }: Props) => {
  const name = designPart?.name || 'Unknown Connection Target';
  const partNumber = designPart?.partData?.partNumber || 'No Part Number';

  return (
    <HStack borderBottom="1px solid" borderColor="gray.300" p={4}>
      <Text color="gray.900" fontWeight="bold" mr={2}>
        {name}
      </Text>
      <Box display="flex">
        <ConnectorIcon w={3} h={3} />
      </Box>
      <Text color="gray.700" ml={2}>
        {partNumber}
      </Text>
    </HStack>
  );
};
