import { ArrowForwardIcon } from '@chakra-ui/icons';
import { HStack, Text } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';

interface Props {
  sourceDesignPart?: DesignPart;
  targetDesignPart?: DesignPart;
}

export const GroupedConductorDetailsHeader = ({ sourceDesignPart, targetDesignPart }: Props) => {
  if (sourceDesignPart && targetDesignPart) {
    return (
      <HStack>
        <Text>{sourceDesignPart.name}</Text>
        <Text color="gray.500">{sourceDesignPart.partData.partNumber}</Text>
        <ArrowForwardIcon />
        <Text>{targetDesignPart.name}</Text>
        <Text color="gray.500">{targetDesignPart.partData.partNumber}</Text>
      </HStack>
    );
  } else if (sourceDesignPart) {
    return (
      <HStack>
        <Text>{sourceDesignPart.name}</Text>
        <Text color="gray.500">{sourceDesignPart.partData.partNumber}</Text>
      </HStack>
    );
  }

  return null;
};
