import { Edge, Node } from '@xyflow/react';

import MeasurementEdge, { MeasurementEdgeData } from '../Layout/components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { PartNoteEdge } from '../Layout/components/edges/NoteEdge/PartNoteEdge.tsx';
import {
  RelationalSegmentEdge,
  RelationalSegmentEdgeData,
} from './components/edges/SegmentEdge/RelationalSegmentEdge.tsx';
import {
  RelationalBreakoutPointNode,
  RelationalBreakoutPointNodeData,
} from './components/nodes/BreakoutPointNode/RelationalBreakoutPointNode.tsx';
import {
  RelationalConnectorNode,
  RelationalConnectorNodeData,
} from './components/nodes/ConnectorNode/RelationalConnectorNode.tsx';
import {
  RelationalLayoutPointNode,
  RelationalLayoutPointNodeData,
} from './components/nodes/LayoutPointNode/RelationalLayoutPointNode.tsx';
import {
  RelationalNoteGroupNode,
  RelationalNoteGroupNodeData,
} from './components/nodes/NoteGroupNode/RelationalNoteGroupNode.tsx';

// =======================
// Node Type Definitions
// =======================

// Define the node types
export enum NodeType {
  BreakoutPoint = 'BreakoutPoint',
  LayoutPoint = 'LayoutPoint',
  Connector = 'Connector',
  NoteGroup = 'NoteGroup',
}

// Register node types with specific component associations
export const relationalNodeTypes = {
  [NodeType.BreakoutPoint]: RelationalBreakoutPointNode,
  [NodeType.LayoutPoint]: RelationalLayoutPointNode,
  [NodeType.Connector]: RelationalConnectorNode,
  [NodeType.NoteGroup]: RelationalNoteGroupNode,
};

// Define the node type guards
export const isBreakoutPointNode = (node: Node): node is Node<RelationalBreakoutPointNodeData> => {
  return node.type === NodeType.BreakoutPoint;
};

export const isLayoutPointNode = (node: Node): node is Node<RelationalLayoutPointNodeData> => {
  return node.type === NodeType.LayoutPoint;
};

export const isConnectorNode = (node: Node): node is Node<RelationalConnectorNodeData> => {
  return node.type === NodeType.Connector;
};

export const isNoteGroupNode = (node: Node): node is Node<RelationalNoteGroupNodeData> => {
  return node.type === NodeType.NoteGroup;
};

// Type guard to determine if a node is a design part node
export const isDesignPartNode = (node: Node): node is Node<RelationalConnectorNodeData> => {
  return isConnectorNode(node);
};

// Type guard to determine if a node is a measurement node
export const isMeasurableNode = (node: Node): node is Node<RelationalConnectorNodeData> => {
  return isDesignPartNode(node);
};

// Type guard to determine if a node is a layout node
export const isLayoutNode = (node: Node): node is Node<RelationalConnectorNodeData> => {
  return isDesignPartNode(node);
};

// =======================
// Edge Type Definitions
// =======================

// Define the edge types
export enum EdgeType {
  SegmentEdge = 'SegmentEdge',
  MeasurementEdge = 'MeasurementEdge',
  NoteEdge = 'NoteEdge',
}

// Register edge types with specific component associations
export const relationalEdgeTypes = {
  [EdgeType.SegmentEdge]: RelationalSegmentEdge,
  [EdgeType.MeasurementEdge]: MeasurementEdge,
  [EdgeType.NoteEdge]: PartNoteEdge,
};

// Define the edge type guards
export const isSegmentEdge = (
  edge: Edge,
): edge is Edge<RelationalSegmentEdgeData> & { data: RelationalSegmentEdgeData } => {
  return edge.type === EdgeType.SegmentEdge && edge.data !== undefined;
};

export const isMeasurementEdge = (edge: Edge): edge is Edge<MeasurementEdgeData> & { data: MeasurementEdgeData } => {
  return edge.type === EdgeType.MeasurementEdge && edge.data !== undefined;
};

export const isNoteEdge = (edge: Edge): edge is Edge => {
  return edge.type === EdgeType.NoteEdge;
};
