import { Input, InputProps, Text } from '@chakra-ui/react';
import { KeyboardEvent, useRef } from 'react';

interface Props extends InputProps {
  isInEditMode?: boolean;
  onEnterKeyPress?: () => void;
}

/**
 * A custom text input component that allows for editing the text value.
 */
export const EditableCellText = ({ value, isInEditMode, onEnterKeyPress, ...rest }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    inputRef.current?.select();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const input = event.currentTarget;

    if (event.key === 'Enter' && onEnterKeyPress) {
      onEnterKeyPress?.();
    } else if (event.key === 'ArrowLeft' && input.selectionStart === 0) {
      // Allow moving left if at the start of input
      (input.closest('td')?.previousElementSibling as HTMLElement)?.focus();
      event.preventDefault();
    } else if (event.key === 'ArrowRight' && input.selectionStart === input.value.length) {
      // Allow moving right if at the end of input
      (input.closest('td')?.nextElementSibling as HTMLElement)?.focus();
      event.preventDefault();
    }
  };

  return isInEditMode ? (
    <Input
      ref={inputRef}
      value={value}
      variant="unstyled"
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      _focus={{
        outline: 'none',
        boxShadow: 'none',
      }}
      px={2}
      {...rest}
    />
  ) : (
    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" px={2}>
      {value || '-'}
    </Text>
  );
};
