import { RowId } from '@web/apps/Design/features/WiringList/components/WiringListRow/types.ts';
import { KeyboardEvent, useCallback } from 'react';

import { useWiringList } from './useWiringList.tsx';

const isTableRowElement = (element: Element | null): element is HTMLTableRowElement => {
  return element instanceof HTMLTableRowElement;
};

const isTableCellElement = (element: Element | null): element is HTMLTableCellElement => {
  return element instanceof HTMLTableCellElement;
};

const getAdjacentCell = (currentCell: HTMLTableCellElement, direction: string) => {
  const row = currentCell.parentElement;
  if (!isTableRowElement(row)) return null;

  const cellIndex = Array.from(row.cells).indexOf(currentCell);
  if (cellIndex === -1) return null;

  if (direction === 'ArrowUp') {
    const prevRow = row.previousElementSibling;
    return isTableRowElement(prevRow) && isTableCellElement(prevRow.cells[cellIndex]) ? prevRow.cells[cellIndex] : null;
  }

  if (direction === 'ArrowDown') {
    const nextRow = row.nextElementSibling;
    return isTableRowElement(nextRow) && isTableCellElement(nextRow.cells[cellIndex]) ? nextRow.cells[cellIndex] : null;
  }

  if (direction === 'ArrowLeft') {
    return isTableCellElement(currentCell.previousElementSibling) ? currentCell.previousElementSibling : null;
  }

  if (direction === 'ArrowRight') {
    return isTableCellElement(currentCell.nextElementSibling) ? currentCell.nextElementSibling : null;
  }

  return null;
};

const focusNextElementInRow = (currentCell: HTMLTableCellElement, shift: boolean) => {
  const rowElement = currentCell.parentElement;
  if (!rowElement) return;

  // Get all focusable elements in the row
  const focusableElements = Array.from(rowElement.querySelectorAll<HTMLElement>('input, select, textarea, button'));

  if (focusableElements.length === 0) return;

  // Get the currently focused element
  const activeElement = document.activeElement as HTMLElement;
  const currentIndex = focusableElements.indexOf(activeElement);

  if (currentIndex === -1) return;

  let nextIndex = shift ? currentIndex - 1 : currentIndex + 1;

  // Wrap around to the last element if moving backwards past the first element
  if (nextIndex < 0) {
    nextIndex = focusableElements.length - 1;
  }

  // Wrap around to the first element if moving forward past the last element
  if (nextIndex >= focusableElements.length) {
    nextIndex = 0;
  }

  focusableElements[nextIndex]?.focus();
};

export const useWiringListCellKeyboardHandler = (rowId: RowId) => {
  const { setActiveEditRow, setIsKeyboardNav, isEditing } = useWiringList();

  return useCallback(
    (e: KeyboardEvent<HTMLTableCellElement>) => {
      e.stopPropagation();
      setIsKeyboardNav(true);

      if (isEditing(rowId)) {
        if (e.key === 'Tab') {
          e.preventDefault();
          focusNextElementInRow(e.currentTarget, e.shiftKey);
          return;
        }

        // Allow inputs/selects to handle their own navigation while in edit mode
        return;
      }

      // If not in edit mode, handle navigation using arrow keys
      if (e.key === 'Enter') {
        setActiveEditRow(rowId);
      } else {
        const nextCell = getAdjacentCell(e.currentTarget, e.key);
        if (nextCell) {
          nextCell.focus();
        }
      }
    },
    [rowId, setActiveEditRow, setIsKeyboardNav, isEditing],
  );
};
