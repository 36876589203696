import { SimpleGrid, Stack } from '@chakra-ui/react';
import { Tool } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextAreaInput from '@web/components/form/TextAreaInput.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import ManufacturerDropdownSingleValue from '@web/components/ManufacturerDropdownSingleValue.tsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import ToolFieldsActionBar from './ToolFieldsActionBar.tsx';
import { Mode } from './ToolsLibraryDashboard.tsx';
import ToolSpecReferences from './ToolSpecReferences.tsx';

interface Props {
  isEditing: boolean;
  mode: Mode;
  theme?: 'light' | 'dark';
  additionalFieldsComponent?: React.JSX.ElementType;
  enableEditMode: () => void;
  onDelete?: () => void;
}

const ToolFields = ({
  isEditing,
  mode,
  additionalFieldsComponent: AdditionalFieldsComponent,
  enableEditMode,
  onDelete,
}: Props) => {
  const { register, getValues } = useFormContext<Tool>();

  return (
    <Stack spacing={6}>
      <SimpleGrid columns={2} spacing={10}>
        <EditableFormText label="Part Number" value={getValues('partNumber')} editing={isEditing}>
          <TextInput
            label="Part Number"
            formControlProps={{ isRequired: true, isDisabled: !isEditing }}
            {...register('partNumber', { required: true })}
          />
        </EditableFormText>
        <ManufacturerDropdownSingleValue editing={isEditing} />
      </SimpleGrid>
      <EditableFormText label="Notes" value={getValues('notes')} editing={isEditing}>
        <TextAreaInput label="Notes" formControlProps={{ isDisabled: !isEditing }} {...register('notes')} />
      </EditableFormText>
      {AdditionalFieldsComponent && <AdditionalFieldsComponent editing={isEditing} />}
      <ToolSpecReferences editing={isEditing} />
      <ToolFieldsActionBar mode={mode} enableEditMode={enableEditMode} onDelete={onDelete} />
    </Stack>
  );
};

export default ToolFields;
