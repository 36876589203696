import { Button, HStack, IconButton } from '@chakra-ui/react';
import { ResolvedConnection } from '@web/apps/types';
import { BiPlus } from 'react-icons/bi';

import { useDesign } from '../../../../hooks/useDesign.tsx';
import { useWiringList } from '../../hooks/useWiringList.tsx';
import { Fields, RowId } from '../WiringListRow/types.ts';

interface ConnectionActionButtonsProps {
  rowId: RowId;
  state: Fields;
  connection?: ResolvedConnection;
  onEdit: () => void;
  onSave: () => void;
  onClear: () => void;
  onCancel: () => void;
  onAddDraftRow: () => void;
  isSaving: boolean;
  isClearing: boolean;
}

/**
 * A set of action buttons for a connection row.
 * These buttons are used to edit, save, clear, and cancel changes for a connection.
 * @param rowId
 * @param state
 * @param connection
 * @param onEdit
 * @param onSave
 * @param onClear
 * @param onCancel
 * @param onAddDraftRow
 * @param isSaving
 * @param isClearing
 * @constructor
 */
export const WiringListRowActions = ({
  rowId,
  state,
  connection,
  onEdit,
  onSave,
  onClear,
  onCancel,
  onAddDraftRow,
  isSaving,
  isClearing,
}: ConnectionActionButtonsProps) => {
  const { isViewOnly } = useDesign();
  const { isFocusedRow, isEditing, hasActiveEdits } = useWiringList();

  const isRowEditing = isEditing(rowId);
  const isActionVisible = !isRowEditing && !hasActiveEdits && isFocusedRow(rowId);
  const isSaveDisabled = !state.destinationId && !state.conductorId;

  const focusStyle = { outline: 'none', boxShadow: 'none' };

  if (isViewOnly) return null;

  return (
    <HStack h={10} justifyContent="flex-end" pr={4}>
      {/* Save Button (Visible during editing) */}
      <Button
        variant="subtle"
        onClick={onSave}
        isDisabled={isSaveDisabled}
        isLoading={isSaving}
        display={isRowEditing ? 'block' : 'none'}
        _hover={{
          color: 'green.400',
        }}
        _focus={{
          color: 'green.400',
          ...focusStyle,
        }}
        pl={2}
      >
        Save
      </Button>

      {/* Cancel Button (Visible during editing) */}
      <Button
        variant="subtle"
        onClick={onCancel}
        display={isRowEditing ? 'block' : 'none'}
        _hover={{
          color: 'red.400',
        }}
        _focus={{
          color: 'red.400',
          ...focusStyle,
        }}
        pl={2}
      >
        Cancel
      </Button>

      {/* Edit Button (Visible when not editing, row is focused, and no active edits) */}
      <Button
        variant="subtle"
        onClick={onEdit}
        display={isActionVisible ? 'block' : 'none'}
        _groupHover={{ display: isActionVisible ? 'block' : 'none' }}
        _hover={{
          color: 'green.400',
        }}
        _focus={{
          color: 'green.400',
          ...focusStyle,
        }}
        pl={2}
      >
        Edit
      </Button>

      {/* Clear Button (Visible when row is focused and not editing) */}
      <Button
        variant="subtle"
        onClick={onClear}
        isDisabled={!connection}
        isLoading={isClearing}
        display={isActionVisible ? 'block' : 'none'}
        _groupHover={{ display: isActionVisible ? 'block' : 'none' }}
        _hover={{
          color: 'red.400',
        }}
        _focus={{
          color: 'red.400',
          ...focusStyle,
        }}
        pl={2}
      >
        Clear
      </Button>

      {/* Add Button (Visible when row is focused and not editing) */}
      <IconButton
        variant="subtle"
        icon={<BiPlus />}
        aria-label="Add connection row"
        minW="auto"
        onClick={onAddDraftRow}
        isDisabled={!connection}
        display={isActionVisible ? 'block' : 'none'}
        _groupHover={{ display: isActionVisible ? 'block' : 'none' }}
        _hover={{
          color: 'green.400',
        }}
        _focus={{
          color: 'green.400',
          ...focusStyle,
        }}
        pl={2}
      />
    </HStack>
  );
};
